import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FormControlComponent } from '../../../abstracts/form-control.abstract';
import { generateUniqueId } from '../../../utils/utility-functions';

@Component({
    selector: 'app-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent extends FormControlComponent implements OnInit {
    @Input() public placeholder = '';
    @Input() public inputId: string;
    @Input() public readonly: boolean;
    @Input() public maxlength: number;

    constructor(@Self() @Optional() public control: NgControl) {
        super();
        this.control.valueAccessor = this;
    }

    ngOnInit(): void {
        this.inputId = this.inputId || generateUniqueId();
    }

    public onInput(event: Event): void {
        if (this.disabled || this.readonly) {
            return;
        }

        const value: string = (event.target as HTMLTextAreaElement).value;

        this.onChange(value);
    }
}
