import { ICategoryFoodDrink } from '../interfaces/category-food-drink.interface';
import { ICategoryItem } from './../interfaces/category-food-drink.interface';

export const foodCategoriesTrads: ICategoryFoodDrink[] = [
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.BAKERY',
        id: 3,
        categories: [
            {
                id: 1011,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.BAKERY_SUB1',
            },
            {
                id: 1012,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.BAKERY_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.DAIRY',
        id: 4,
        categories: [
            {
                id: 1021,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.DAIRY_SUB1',
            },
            {
                id: 1022,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.DAIRY_SUB2',
            },
            {
                id: 1023,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.DAIRY_SUB3',
            },
            {
                id: 1024,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.DAIRY_SUB4',
            },
            {
                id: 1025,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.DAIRY_SUB5',
            },
            {
                id: 1026,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.DAIRY_SUB6',
            },
            {
                id: 1027,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.DAIRY_SUB7',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.ANTIPASTI',
        id: 5,
        categories: [
            {
                id: 1031,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.ANTIPASTI_SUB1',
            },
            {
                id: 1032,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.ANTIPASTI_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.FRUIT',
        id: 6,
        categories: [
            {
                id: 1041,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FRUIT_SUB1',
            },
            {
                id: 1042,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FRUIT_SUB2',
            },
            {
                id: 1043,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FRUIT_SUB3',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.MEAT',
        id: 7,
        categories: [
            {
                id: 1051,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.MEAT_SUB1',
            },
            {
                id: 1052,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.MEAT_SUB2',
            },
            {
                id: 1053,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.MEAT_SUB3',
            },
            {
                id: 1054,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.MEAT_SUB4',
            },
            {
                id: 1055,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.MEAT_SUB5',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.FISH',
        id: 8,
        categories: [
            {
                id: 1061,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FISH_SUB1',
            },
            {
                id: 1062,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FISH_SUB2',
            },
            {
                id: 1063,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FISH_SUB3',
            },
            {
                id: 1064,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FISH_SUB4',
            },
            {
                id: 1065,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.FISH_SUB5',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.READYMEALS',
        id: 9,
        categories: [
            {
                id: 1071,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.READYMEALS_SUB1',
            },
            {
                id: 1072,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.READYMEALS_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.SAVOURYSNACKS',
        id: 10,
        categories: [
            {
                id: 1081,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAVOURYSNACKS_SUB1',
            },
            {
                id: 1082,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAVOURYSNACKS_SUB2',
            },
            {
                id: 1083,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAVOURYSNACKS_SUB3',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.SWEETSNACKS',
        id: 11,
        categories: [
            {
                id: 1091,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SWEETSNACKS_SUB1',
            },
            {
                id: 1092,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SWEETSNACKS_SUB2',
            },
            {
                id: 1093,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SWEETSNACKS_SUB3',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.HONEY',
        id: 12,
        categories: [
            {
                id: 1101,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.HONEY_SUB1',
            },
            {
                id: 1102,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.HONEY_SUB2',
            },
            {
                id: 1103,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.HONEY_SUB3',
            },
            {
                id: 1104,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.HONEY_SUB4',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.NUTS',
        id: 13,
        categories: [
            {
                id: 1111,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.NUTS_SUB1',
            },
            {
                id: 1112,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.NUTS_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.OILS',
        id: 14,
        categories: [
            {
                id: 1121,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.OILS_SUB1',
            },
            {
                id: 1122,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.OILS_SUB2',
            },
            {
                id: 1123,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.OILS_SUB3',
            },
            {
                id: 1124,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.OILS_SUB4',
            },
            {
                id: 1125,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.OILS_SUB5',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.PRESERVED',
        id: 15,
        categories: [
            {
                id: 1131,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.PRESERVED_SUB1',
            },
            {
                id: 1132,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.PRESERVED_SUB2',
            },
            {
                id: 1133,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.PRESERVED_SUB3',
            },
            {
                id: 1134,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.PRESERVED_SUB4',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.SOUPS',
        id: 16,
        categories: [
            {
                id: 1141,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SOUPS_SUB1',
            },
            {
                id: 1142,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SOUPS_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.SAUCES',
        id: 17,
        categories: [
            {
                id: 1151,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB1',
            },
            {
                id: 1152,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB2',
            },
            {
                id: 1153,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB3',
            },
            {
                id: 1154,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB4',
            },
            {
                id: 1155,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB5',
            },
            {
                id: 1156,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB6',
            },
            {
                id: 1157,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB7',
            },
            {
                id: 1158,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.SAUCES_SUB8',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.PASTA',
        id: 18,
        categories: [
            {
                id: 1161,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.PASTA_SUB1',
            },
            {
                id: 1162,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.PASTA_SUB2',
            },
            {
                id: 1163,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.PASTA_SUB3',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.CAKE',
        id: 19,
        categories: [
            {
                id: 1171,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.CAKE_SUB1',
            },
            {
                id: 1172,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.CAKE_SUB2',
            },
            {
                id: 1173,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.CAKE_SUB3',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.ICECREAM',
        id: 20,
        categories: [
            {
                id: 1181,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.ICECREAM_SUB1',
            },
            {
                id: 1182,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.ICECREAM_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.FOOD.OTHER',
        id: 1191,
        categories: [
            {
                id: 1191,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.FOOD.OTHER_SUB1',
            },
        ],
    },
];

export const drinkCategoriesTrads: ICategoryFoodDrink[] = [
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.DRINK.WATER',
        id: 23,
        categories: [
            {
                id: 2011,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.WATER_SUB1',
            },
            {
                id: 2012,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.WATER_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.DRINK.TEA',
        id: 24,
        categories: [
            {
                id: 2021,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.TEA_SUB1',
            },
            {
                id: 2022,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.TEA_SUB2',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.DRINK.COFFEE',
        id: 25,
        categories: [
            {
                id: 2031,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.COFFEE_SUB1',
            },
            {
                id: 2032,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.COFFEE_SUB2',
            },
            {
                id: 2033,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.COFFEE_SUB3',
            },
            {
                id: 2034,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.COFFEE_SUB4',
            },
            {
                id: 2035,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.COFFEE_SUB5',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.DRINK.SOFT',
        id: 26,
        categories: [
            {
                id: 2041,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.SOFT_SUB1',
            },
            {
                id: 2042,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.SOFT_SUB2',
            },
            {
                id: 2043,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.SOFT_SUB3',
            },
            {
                id: 2044,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.SOFT_SUB4',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.DRINK.BEER',
        id: 27,
        categories: [
            {
                id: 2051,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.BEER_SUB1',
            },
            {
                id: 2052,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.BEER_SUB2',
            },
            {
                id: 2053,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.BEER_SUB3',
            },
        ],
    },
    {
        title: 'EVALUATE.SECTION2.CATEGORIES.DRINK.ALCOOL',
        id: 28,
        categories: [
            {
                id: 2061,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.ALCOOL_SUB1',
            },
            {
                id: 2062,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.ALCOOL_SUB2',
            },
            {
                id: 2063,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.ALCOOL_SUB3',
            },
            {
                id: 2064,
                title: 'EVALUATE.SECTION2.SUBCATEGORIES.DRINK.ALCOOL_SUB4',
            },
        ],
    },
];

export const isInFoodCategory = (categoryId: number): boolean => {
    let isFood: boolean = true;
    drinkCategoriesTrads.forEach((drink: ICategoryFoodDrink) => {
        drink.categories.forEach((c: ICategoryItem) => {
            if (c.id === categoryId) isFood = false;
        });
    });
    return isFood;
};
