import { IFeedbackMessage } from './../../interfaces/feedback-message.interface';

export namespace FeedbackMessages {
    export class Set {
        public static readonly type = '[Feedbac kMessage] set message';

        constructor(public message: IFeedbackMessage) {}
    }

    export class Reset {
        public static readonly type = '[Feedback Message] reset';

        constructor(public index: number | number[]) {}
    }

    export class ResetAll {
        public static readonly type = '[Feedback Message] reset all messages';
    }
}
