import { ModalNamesEnum } from '../../enums/modal-names.enum';

export class OpenModal {
    public static readonly type = '[Modal] Open Modal';
    constructor(public payload: { title?: string; name: ModalNamesEnum }) {}
}

export class CloseModal {
    public static readonly type = '[Modal] Close Modal';
}

export class CancelBtnClick {
    public static readonly type = '[Modal] Cancel';
}
