import { PaymentStatusEnum } from './../enums/payment-status.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IPaymentStatusItem = IGenericListItem<PaymentStatusEnum>;

export const paymentStatusList: IPaymentStatusItem[] = [
    {
        id: 1,
        value: PaymentStatusEnum.TO_PAY,
        label: 'PAYMENT_STATUS.TO_PAY',
    },
    {
        id: 2,
        value: PaymentStatusEnum.PAID,
        label: 'PAYMENT_STATUS.PAID',
    },
];
