import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { TastingOptionsEnum } from '../../enums/tasting-options.enum';
import { ITasting } from '../../interfaces/tasting.interface';
import { TastingService } from '../../services/tasting.service';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { Tasting } from '../actions/tasting.actions';

export interface TastingStateModel {
    tasting: ITasting;
    optionToDelete: TastingOptionsEnum;
}

@State<TastingStateModel>({
    name: 'tasting',
    defaults: {
        tasting: null,
        optionToDelete: null,
    },
})
@Injectable()
export class TastingState {
    @Selector()
    public static tasting(state: TastingStateModel): ITasting {
        return state.tasting;
    }

    @Selector()
    public static optionToRemove(state: TastingStateModel): TastingOptionsEnum {
        return state.optionToDelete;
    }

    constructor(private tastingService: TastingService) {}

    @Action(Tasting.Show)
    public show(ctx: StateContext<TastingStateModel>, action: Tasting.Show): Observable<ITasting> {
        return this.tastingService.getById(action.id).pipe(
            tap((result: ITasting) => ctx.patchState({ tasting: result })),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404) {
                    return throwError('FORMS.ERROR_FEEDBACK.TASTING_NOT_FOUND');
                } else {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ERRORS.GENERIC', type: AlertTypeEnum.ERROR }));
                    return of(null);
                }
            })
        );
    }

    @Action(Tasting.Update)
    public update(ctx: StateContext<TastingStateModel>, action: Tasting.Update): Observable<ITasting> {
        return this.tastingService
            .updateTasting(action.tasting, action.id)
            .pipe(tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.TASTING_UPDATED', type: AlertTypeEnum.SUCCESS }))));
    }

    @Action(Tasting.AssignUser)
    public assignUser(ctx: StateContext<TastingStateModel>, action: Tasting.AssignUser): Observable<void> {
        return this.tastingService
            .assignUser(action.tastingIds, action.userId)
            .pipe(tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.USER_ASSIGNED', type: AlertTypeEnum.SUCCESS }))));
    }

    @Action(Tasting.AssignStaYear)
    public assignStaYear(ctx: StateContext<TastingStateModel>, action: Tasting.AssignStaYear): Observable<void> {
        return this.tastingService.assignStaYear(action.tastingIds, action.staYear).pipe(
            tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.STA_YEAR_ASSIGNED', type: AlertTypeEnum.SUCCESS }))),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 409) {
                    return throwError('ERRORS.BACKEND.' + (err.error.error.code as string).toUpperCase());
                } else {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ERRORS.GENERIC', type: AlertTypeEnum.ERROR }));
                    return of(null);
                }
            })
        );
    }

    @Action(Tasting.ChangeProduct)
    public changeProduct(ctx: StateContext<TastingStateModel>, action: Tasting.ChangeProduct): Observable<void> {
        return this.tastingService
            .changeProduct(action.tastingIds, action.productId)
            .pipe(
                tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.TASTING_ASSIGN_PRODUCT', type: AlertTypeEnum.SUCCESS })))
            );
    }

    @Action(Tasting.SetOptionToRemove)
    public setOptionToRemove(ctx: StateContext<TastingStateModel>, action: Tasting.SetOptionToRemove): void {
        ctx.setState(
            patch<TastingStateModel>({
                optionToDelete: action.option,
            })
        );
    }

    @Action(Tasting.DeleteOption)
    public deleteOption(ctx: StateContext<TastingStateModel>, action: Tasting.DeleteOption): Observable<void> {
        return this.tastingService
            .deleteOption(action.tastingId, action.option)
            .pipe(tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.OPTION_REMOVED', type: AlertTypeEnum.SUCCESS }))));
    }

    @Action(Tasting.Delete)
    public delete(ctx: StateContext<TastingStateModel>, action: Tasting.Delete): Observable<void> {
        return this.tastingService
            .deleteTasting(action.tastingId)
            .pipe(tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.TASTING_DELETED', type: AlertTypeEnum.SUCCESS }))));
    }

    @Action(Tasting.Unplan)
    public unplan(ctx: StateContext<TastingStateModel>, action: Tasting.Unplan): Observable<ITasting> {
        return this.tastingService
            .unplanTasting(action.tastingId)
            .pipe(tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.TASTING_UNPLANNED', type: AlertTypeEnum.SUCCESS }))));
    }

    @Action(Tasting.BulkUnplan)
    public bulkUnplan(ctx: StateContext<TastingStateModel>, action: Tasting.BulkUnplan): Observable<void> {
        return this.tastingService
            .bulkUnplanTastings(action.tastingIds)
            .pipe(
                tap(() =>
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.TASTINGS_BULK_UNPLANNED', type: AlertTypeEnum.SUCCESS }))
                )
            );
    }
}
