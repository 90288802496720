import { AfterViewInit, Component, ContentChild, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';

import { BaseComponent } from '../../abstracts/base.abstract';
import { ModalNamesEnum } from '../../enums/modal-names.enum';
import { CancelBtnClick } from '../../store/actions/modal.actions';
import { ModalState } from '../../store/state/modal.state';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Select(ModalState.isModalOpened) public isModalOpened$: Observable<boolean>;
    @Select(ModalState.title) public title$: Observable<string>;
    @Select(ModalState.modalName) public name$: Observable<ModalNamesEnum>;

    @Input() public modalName: string;
    @ContentChild('titleTemplate', { static: false }) public titleTemplate: TemplateRef<any>;
    @ViewChild('cancelButton') public cancelButtonRef: ElementRef;

    public opened: boolean;
    public title: string;
    public type: string;
    public name: ModalNamesEnum;

    constructor(private store: Store) {
        super();
    }

    ngOnInit(): void {
        this.uns = combineLatest([this.isModalOpened$, this.title$, this.name$]).subscribe(
            ([isModalOpened, title, name]: [boolean, string, ModalNamesEnum]) => {
                this.opened = isModalOpened;
                this.title = title;
                this.name = name;
            }
        );
    }

    ngAfterViewInit(): void {
        if (!this.cancelButtonRef) {
            return;
        }

        const cancelBtn: HTMLButtonElement = this.cancelButtonRef.nativeElement;

        /** SetTimeout used to bypass expressionchangedafteritwaschecked.
         * Select input component blur event is triggered by the cancelBtn focus
         */
        setTimeout(() => cancelBtn.focus());
    }

    public cancel(): void {
        this.store.dispatch(new CancelBtnClick());
    }

    @HostListener('document:keyup', ['$event']) private handleEscPress(event: KeyboardEvent): void {
        if (event.key === 'Escape' && this.name === this.modalName && this.opened) {
            this.cancel();
        }
    }
}
