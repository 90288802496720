import { TastingStatusEnum } from './../enums/tasting-status.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ITastingStatusItem = IGenericListItem<TastingStatusEnum>;

export const tastingStatusList: ITastingStatusItem[] = Object.keys(TastingStatusEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: TastingStatusEnum[key as keyof typeof TastingStatusEnum],
    label: `TASTING_STATUS.${key}`,
}));
