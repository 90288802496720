import { Component, DoCheck, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FormControlComponent } from '../../../abstracts/form-control.abstract';
import { ISelectConfig } from '../../../interfaces/select-config.interface';
import { generateUniqueId } from '../../../utils/utility-functions';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent extends FormControlComponent implements OnInit, OnChanges {
    @Input() public config: ISelectConfig;

    public inputId: string;
    public idField: string;
    public labelField: string;
    public options: any[];
    public placeholder: string;
    public selectedOptionId: string;
    public selectedOption: any;
    public readonly: boolean;

    constructor(@Self() @Optional() public control: NgControl) {
        super();
        this.control.valueAccessor = this;
    }

    ngOnInit(): void {
        this.inputId = this.config.inputId || generateUniqueId();
        this.idField = this.config.idField || 'id';
        this.labelField = this.config.labelField || 'label';
        this.options = this.config.options || [];
        this.placeholder = this.config.placeholder || 'Please select an option';
        this.readonly = this.config.readonly || false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.config && changes.config.currentValue !== changes.config.previousValue) {
            this.options = changes.config.currentValue.options;
        }
    }

    public writeValue(obj: any): void {
        this.selectedOption = obj;
    }

    public onSelect(event: Event): void {
        const value: string = (event.target as HTMLSelectElement).value;
        this.selectedOption = this.options.find((opt: any) =>
            value === null ? opt[this.idField] === null : opt[this.idField].toString() === value
        );

        this.onChange(this.selectedOption);
    }

    public isSelected(idField: string | number): boolean {
        if (this.selectedOption === null || this.selectedOption === undefined) {
            return false;
        }
        const normalizedIdField: string = idField.toString();
        this.selectedOptionId = this.selectedOption[this.idField] ? this.selectedOption[this.idField].toString() : null;

        return this.selectedOptionId === normalizedIdField;
    }

    public onBlur(): void {
        this.markAsTouched();
    }
}
