export class GetAllReferentials {
    public static readonly type = '[Referentials] Get all';
}

export class GetDiscounts {
    public static readonly type = '[Referentials] Get Discounts';
}

export class GetStaYearList {
    public static readonly type = '[Referentials] Get all Sta Years';
}

export class BootstrapReferentials {
    public static readonly type = '[Referentials] Bootstrap several referential actions';
}
