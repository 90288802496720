import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { IProfile } from '../../interfaces/profile.interface';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { GetUser, RemoveLogo, UpdateUser, UploadLogo } from '../actions/profile.actions';
import { ResultsAndReports } from '../actions/results-and-reports.actions';
import { ProfileService } from './../../services/profile.service';

export interface ProfileStateModel {
    user: IProfile;
}
@State<ProfileStateModel>({
    name: 'profile',
    defaults: {
        user: null,
    },
})
@Injectable()
export class ProfileState {
    @Selector()
    public static user(state: ProfileStateModel): IProfile {
        return state.user;
    }

    constructor(private profileService: ProfileService) {}

    @Action(GetUser)
    public getUser(ctx: StateContext<ProfileStateModel>): void {
        const state: ProfileStateModel = ctx.getState();

        if (state.user) {
            return;
        }

        this.profileService.getUser().subscribe((result: IProfile) => {
            ctx.patchState({
                user: result,
            });
        });
    }

    @Action(UpdateUser)
    public updateUser(ctx: StateContext<ProfileStateModel>, action: UpdateUser): Observable<IProfile> {
        return this.profileService.updateUser(action.profile, action.userId).pipe(
            tap((result: IProfile) => {
                ctx.patchState({
                    user: result,
                });
                ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.USER_UPDATED', type: AlertTypeEnum.SUCCESS }));
            })
        );
    }

    @Action(UploadLogo)
    public uploadLogo(ctx: StateContext<ProfileStateModel>, action: UploadLogo): Observable<IProfile> {
        return this.profileService.uploadLogo(action.logo, action.userId).pipe(
            tap(() => {
                ctx.dispatch([
                    new FeedbackMessages.Set({ text: 'ALERT.MSG.LOGO_UPLOADED', type: AlertTypeEnum.SUCCESS }),
                    new ResultsAndReports.BustShowCache(),
                ]);
            })
        );
    }

    @Action(RemoveLogo)
    public removeLogo(ctx: StateContext<ProfileStateModel>, action: RemoveLogo): Observable<IProfile> {
        return this.profileService.removeLogo(action.userId).pipe(
            tap(() => {
                ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.LOGO_REMOVED', type: AlertTypeEnum.SUCCESS }));
            })
        );
    }
}
