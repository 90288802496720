import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseComponent } from '../../../shared/abstracts/base.abstract';
import { SetIsMobile } from '../../../shared/store/actions/header.actions';
import { HeaderState } from '../../../shared/store/state/header.state';
import { IProfile } from './../../../shared/interfaces/profile.interface';
import { Logout } from './../../../shared/store/actions/auth.actions';
import { AuthState } from './../../../shared/store/state/auth.state';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent extends BaseComponent implements OnInit {
    @Select(HeaderState.isMobile) public isMobile$: Observable<boolean>;
    @Select(AuthState.isAuthenticated) public isAuth$: Observable<boolean>;
    @Select(AuthState.user) public user$: Observable<IProfile>;

    public isMenuOpen = true;

    constructor(private store: Store, private router: Router, private localize: LocalizeRouterService) {
        super();
    }

    public ngOnInit(): void {
        this.store.dispatch(new SetIsMobile());
        this.uns = this.isMobile$.subscribe((isMobile: boolean) => {
            this.isMenuOpen = !isMobile;
        });
    }

    public toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    public logout(): void {
        this.store.dispatch(new Logout());
        this.router.navigate([this.localize.translateRoute('/login')]);
    }
}
