import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IFilter } from '../../interfaces/filter.interface';
import {
    IReportRedaction,
    IReportRedactionQueryOptions,
    IReportRedactionTableData,
} from '../../interfaces/report-redaction.interface';
import { ReportRedactionToolService } from '../../services/report-redaction-tool.service';
import { rowPerPageOptions } from '../../utils/table.utils';
import { ReportRedaction } from '../actions/report-redaction.actions';
import { SharedTablesSelectors } from '../selectors/shared-tables.selectors';
import { reportRedactionColumns, reportRedactionFrozenColumns } from './../../data/report-redaction-columns';
import { CriteriaTypeEnum } from './../../enums/criteria-type.enum';
import { ProductCategoriesEnum } from './../../enums/product-categories.enum';
import { ReportRedactionSortConditionEnum } from './../../enums/report-redaction-filters.enum';
import { IReportRedactionDTOIn } from './../../interfaces/report-redaction.interface';
import { TableStateModel } from './table.state';

export interface ReportRedactionStateModel extends TableStateModel<IReportRedactionTableData, IReportRedactionQueryOptions> {
    data: IReportRedactionTableData[];
    selectedRow: IReportRedactionTableData;
}

const queryOptionsDefaults: IReportRedactionQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    sortCondition: '-' + ReportRedactionSortConditionEnum.CREATED_AT,
    filters: [],
    textFilter: '',
};

@State<ReportRedactionStateModel>({
    name: 'reportRedaction',
    defaults: {
        data: [],
        tableData: [],
        scrollableColumns: [...reportRedactionColumns],
        frozenColumns: [...reportRedactionFrozenColumns],
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
        selectedRow: null,
    },
})
@Injectable()
export class ReportRedactionState extends SharedTablesSelectors {
    constructor(private reportRedactionService: ReportRedactionToolService) {
        super();
    }

    @Action(ReportRedaction.GetFilters)
    public getFilters(ctx: StateContext<ReportRedactionStateModel>): any {
        return this.reportRedactionService.getFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.patchState({
                    filtersData: result,
                });
            })
        );
    }

    @Action(ReportRedaction.GetAll)
    public getAll(ctx: StateContext<ReportRedactionStateModel>): Observable<IReportRedactionDTOIn> {
        const state: ReportRedactionStateModel = ctx.getState();

        return this.reportRedactionService.getAll(state.queryOptions).pipe(
            tap((result: IReportRedactionDTOIn) => {
                if (result) {
                    const tableData: IReportRedactionTableData[] = this.formatTableData(result.report_redactions);
                    ctx.patchState({
                        data: tableData,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    });
                }
            })
        );
    }

    @Action(ReportRedaction.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<ReportRedactionStateModel>, action: ReportRedaction.SetQueryOptions): void {
        const state: ReportRedactionStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IReportRedactionQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;
        ctx.setState(
            patch<ReportRedactionStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(ReportRedaction.UpdateColumnPreferences)
    public updateColumnPreferences(ctx: StateContext<ReportRedactionStateModel>, action: ReportRedaction.UpdateColumnPreferences): void {
        ctx.setState(
            patch<ReportRedactionStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(ReportRedaction.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<ReportRedactionStateModel>): void {
        ctx.setState(
            patch<ReportRedactionStateModel>({
                scrollableColumns: reportRedactionColumns,
            })
        );
    }

    @Action(ReportRedaction.ToggleFilters)
    public setFiltersDisplay(ctx: StateContext<ReportRedactionStateModel>, action: ReportRedaction.ToggleFilters): void {
        ctx.setState(
            patch<ReportRedactionStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    private formatTableData(data: IReportRedaction[]): IReportRedactionTableData[] {
        const tableData: IReportRedactionTableData[] = [];

        data.forEach((item: IReportRedaction) => {
            const reportRedactionTableItem: IReportRedactionTableData = {
                id: item.id,
                tasting_id: item.tasting_id,
                user_id: item.user_id,
                product_id: item.product_id,
                sta_year: item.sta_year,
                category_type:
                    item.category.criteria_type === CriteriaTypeEnum.DRINK ? ProductCategoriesEnum.DRINK : ProductCategoriesEnum.FOOD,
                category_name: item.category.name,
                hasCommentsAndSuggestions: item.comments_suggestions,
                hasQuestions: item.question,
                hasFoodPairing: item.food_pairing,
                feedback_only: item.feedback_only,
                stars: item.star,
                score: item.score,
                redaction_status: item.redaction_status,
                announcement_desc: item.announcement_description,
            };

            tableData.push(reportRedactionTableItem);
        });

        return tableData;
    }
}
