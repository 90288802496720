import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { IFeaturePermissions } from '../../interfaces/feature-permissions.interface';
import { AuthState } from '../../store/state/auth.state';
import { RoleEnum } from './../../enums/user-right.enums';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    public permissionsHaveChanged$: Observable<void>;
    private permissionsHaveChangedSubject$: Subject<void>;

    constructor(private store: Store) {
        this.permissionsHaveChangedSubject$ = new Subject();
        this.permissionsHaveChanged$ = this.permissionsHaveChangedSubject$.asObservable();
    }

    public checkPermission(role: RoleEnum, feature: IFeaturePermissions): boolean {
        return feature.authorisation.includes(role);
    }

    public notifyPermissionsChanged(): void {
        this.permissionsHaveChangedSubject$.next();
    }

    public getHomeURL(): string {
        const userRole: RoleEnum = this.store.selectSnapshot(AuthState.userRole);

        return userRole === RoleEnum.FREELANCE_BASIC ? '/report-management-tool' : '/my-workspace';
    }
}
