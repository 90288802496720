import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { mockResultValidationDetails } from './mock-data';

export const mockResultsValidationDetailsRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: any = mockResultValidationDetails;

    if (req.method === 'GET') {
        const res: any = {
            status: 200,
            body: data,
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
