import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { mockPretestTranslationsRes } from '../mocks/mock-pretest-translations';
import { mockProductsRes } from '../mocks/mock-products-res';
import { mockProfileRes } from '../mocks/mock-profile-res';
import { mockReportDetailsRes } from '../mocks/mock-report-details.res';
import { mockResultsValidationDetailsRes } from '../mocks/mock-resuts-validation-res';
import { IMockEnv } from './../interfaces/environment.interface';
import { mockPretestRes } from './../mocks/mock-pretest-res';
import { mockReporttRedactionToolRes } from './../mocks/mock-report-redaction-tool-res';
import { mockResultsAndReportsFiltersRes, mockResultsAndReportsRes } from './../mocks/mock-results-and-reports-data';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const mockEnv: IMockEnv = environment.mock;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(
                mergeMap(() => {
                    /* PROFILE */
                    if ((mockEnv.all || (mockEnv.services && mockEnv.services.profile)) && request.url.includes(SubUrlsEnum.USERS)) {
                        return mockProfileRes(request);
                    }

                    /* PRODUCTS */
                    if ((mockEnv.all || (mockEnv.services && mockEnv.services.products)) && request.url.includes(SubUrlsEnum.PRODUCTS)) {
                        return mockProductsRes(request);
                    }

                    /* PRETEST */
                    if ((mockEnv.all || (mockEnv.services && mockEnv.services.pretest)) && request.url.endsWith(SubUrlsEnum.PRETEST)) {
                        return mockPretestRes(request);
                    }

                    /* PRETEST TRANSLATIONS */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.pretestTranslations)) &&
                        request.url.includes(SubUrlsEnum.PRETEST_TRANSLATIONS)
                    ) {
                        return mockPretestTranslationsRes(request);
                    }

                    /* RESULTS AND REPORTS */
                    if (
                        mockEnv.all ||
                        (mockEnv.services && mockEnv.services.resultsAndReports && request.url.endsWith(SubUrlsEnum.RESULTS_AND_REPORTS))
                    ) {
                        return mockResultsAndReportsRes(request);
                    }

                    if (
                        mockEnv.all ||
                        (mockEnv.services &&
                            mockEnv.services.resultsAndReports &&
                            request.url.endsWith(SubUrlsEnum.RESULTS_AND_REPORTS_FILTERS))
                    ) {
                        return mockResultsAndReportsFiltersRes(request);
                    }

                    /* REPORT REDACTION */
                    if (
                        mockEnv.all ||
                        (mockEnv.services &&
                            mockEnv.services.reportRedactionTool &&
                            (request.url.endsWith(SubUrlsEnum.REPORT_REDACTION) ||
                                request.url.endsWith(SubUrlsEnum.REPORT_REDACTION_FILTERS)))
                    ) {
                        return mockReporttRedactionToolRes(request);
                    }

                    /* REPORT DETAILS */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.reportDetails)) &&
                        request.url.includes(SubUrlsEnum.REPORT_DETAILS)
                    ) {
                        return mockReportDetailsRes(request);
                    }

                    /* RESULT DETAILS */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.resultValidationDetails)) &&
                        request.url.includes(SubUrlsEnum.RESULTS) &&
                        request.url.match(/(.)*(\/)([0-9])*$/)
                    ) {
                        return mockResultsValidationDetailsRes(request);
                    }

                    // pass through any requests not handled above
                    return next.handle(request);
                })
            )
            .pipe(
                // call materialize and dematerialize to ensure delay even if an error is thrown
                // (cfr: https://github.com/Reactive-Extensions/RxJS/issues/648)
                materialize(),
                delay(200),
                dematerialize()
            );
    }
}

export const mockInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: MockInterceptor,
    multi: true,
};
