import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { ValidationErrorNameEnum } from './../../../enums/validation-error-name.enum';

@Component({
    selector: 'app-validation-feedback',
    templateUrl: './validation-feedback.component.html',
    styleUrls: ['./validation-feedback.component.scss'],
})
export class ValidationFeedbackComponent {
    @Input() public control: AbstractControl;

    /* ENUMS */
    public validationErrorNameEnum = ValidationErrorNameEnum;
}
