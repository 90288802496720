export enum ReportAssignationFiltersTypeEnum {
    STA_YEAR = 'tasting.sta_year',
    CONTACT_OWNER = 'user.contact_owner',
    CATEGORY = 'product.category',
    OPTIONS = 'tasting.options',
    FEEDBACK_ONLY = 'tasting.feedback_only',
    PAYMENT_STATUS = 'user.payment_status',
    COMPANY_SIZE = 'user.company_size',
    COUNTRY = 'user.country',
    CLAIMS = 'product.claims',
    REDACTION_STATUS = 'report_management.redaction_status',
    ASSIGNED_REDACTOR = 'report_management.assigned_redactor',
    PRESTIGE_AWARD = 'tasting.prestige_award',
    FINAL_STARS = 'final_score.final_stars',
}

export enum ReportAssignationFiltersLabelEnum {
    CATEGORY = 'PRODUCT.CATEGORY',
    FOOD_DRINK = 'REPORT_MANAGEMENT.FOOD_DRINK',
    MOTHER_CAT = 'REPORT_MANAGEMENT.MOTHER_CAT',
    COUNTRY = 'USER.COUNTRY',
}

export enum ReportAssignationSortConditionEnum {
    REPORT_ID = 'report_management.id',
    TASTING_ID = 'tasting.id',
    COMPANY_NAME = 'user.company_name',
    PRODUCT_ID = 'product.id',
    FINAL_STARS = 'final_score.final_stars',
    REDACTION_STATUS = 'report_management.redaction_status',
    REPORT_READY = 'report_management.report_ready',
    REDACTOR_EMAIL = 'report_management.redactor_email',
    PAYMENT_STATUS = 'user.payment_status',
    QUESTION = 'new_option.question',
    FOOD_PAIRING = 'new_option.food_pairing',
    COMMENT = 'new_option.comment',
    CREATED_AT = 'tasting.created_at',
}
