import { IProduct } from '../../interfaces/product.interface';
import { IProfile } from '../../interfaces/profile.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { ITasting } from '../../interfaces/tasting.interface';
import { IPretestQueryOptions } from './../../interfaces/pretest.interface';

export namespace Pretest {
    export class GetAll {
        public static readonly type = '[Pretest] get data';
    }
    export class SetSelectedRow {
        public static readonly type = '[Pretest] set selected row';

        constructor(public tastingId: number) {}
    }

    export class UpdateSelectedUser {
        public static readonly type = '[Pretest] Update selected user';

        constructor(public profile: Partial<IProfile>, public userId: number) {}
    }

    export class UpdateSelectedProduct {
        public static readonly type = '[Pretest] Update selected product';

        constructor(public product: Partial<IProduct>, public productId: number) {}
    }

    export class SetQueryOptions {
        public static readonly type = '[Pretest] Set query options';

        constructor(
            public options: Partial<IPretestQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class GetFilters {
        public static readonly type = '[Pretest] get filters';
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[Pretest] set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[Pretest] reset column prefs';
    }

    export class UpdateTasting {
        public static readonly type = '[Pretest] update tasting';

        constructor(public tasting: Partial<ITasting>, public tastingId: number) {}
    }

    export class SetMultipleSelectedRows {
        public static readonly type = '[Pretest] set multiple rows as selected';

        constructor(public tastingIds: number[]) {}
    }

    export class ToggleFilters {
        public static readonly type = '[Pretest] Set pretest filters display status';

        constructor(public showFilters: boolean) {}
    }

    export class GetCNCodes {
        public static readonly type = '[Pretest] Get CN Codes';
    }
}
