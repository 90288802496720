import { Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { FormValidatorComponent } from './form-validator.abstract';

@Directive()
export abstract class FormControlComponent extends FormValidatorComponent implements ControlValueAccessor {
    public value: any;

    public touched = false;

    public disabled = false;

    public onTouched: () => void;
    public onChange: (value: any) => void;

    public writeValue(obj: any): void {
        this.value = obj;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public markAsTouched(): void {
        this.onTouched();
        this.touched = true;
    }
}
