import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ProductCategoriesEnum } from '../../enums/product-categories.enum';
import { ICategory } from '../../interfaces/category.interface';
import { CategoryService } from '../../services/categories.service';
import { GetAllCategories } from '../actions/category.actions';

export interface CategoryStateModel {
    categories: ICategory[];
    motherCategories: ICategory[];
    childCategories: ICategory[];
}

@State<CategoryStateModel>({
    name: 'categories',
    defaults: {
        categories: [],
        motherCategories: [],
        childCategories: [],
    },
})
@Injectable()
export class CategoryState implements NgxsOnInit {
    @Selector()
    public static categories(state: CategoryStateModel): ICategory[] {
        return state.categories;
    }

    @Selector()
    public static foodCategories(state: CategoryStateModel): ICategory[] {
        if (!state.categories.length) {
            return [];
        }

        return state.categories.filter((c: ICategory) => c.name === ProductCategoriesEnum.FOOD)[0].childreen;
    }

    @Selector()
    public static drinkCategories(state: CategoryStateModel): ICategory[] {
        if (!state.categories.length) {
            return [];
        }

        return state.categories.filter((c: ICategory) => c.name === ProductCategoriesEnum.DRINK)[0].childreen;
    }

    @Selector()
    public static motherCategories(state: CategoryStateModel): ICategory[] {
        return state.motherCategories;
    }

    @Selector()
    public static childCategories(state: CategoryStateModel): ICategory[] {
        return state.childCategories;
    }

    constructor(private categoryService: CategoryService) {}

    public ngxsOnInit(ctx: StateContext<CategoryStateModel>): void {
        ctx.dispatch(new GetAllCategories());
    }

    @Action(GetAllCategories)
    public getAllCategories(ctx: StateContext<CategoryStateModel>): Observable<ICategory[]> {
        const state: CategoryStateModel = ctx.getState();

        if (state.categories.length > 0) {
            return null;
        }

        return this.categoryService.getCategories().pipe(
            tap((result: ICategory[]) => {
                const allCatsInFoodDrink: ICategory[] = [...result[0].childreen, ...result[1].childreen];

                const motherCats: ICategory[] = [];
                const childCats: ICategory[] = [];

                allCatsInFoodDrink.forEach((cat: ICategory) => {
                    if (cat.childreen) {
                        motherCats.push(cat);

                        cat.childreen.forEach((subCat: ICategory) => childCats.push(subCat));
                    } else {
                        // case of "Other food product, should be in childCategories cause it has no children"
                        childCats.push(cat);
                    }
                });

                ctx.patchState({
                    categories: result,
                    motherCategories: motherCats,
                    childCategories: childCats,
                });
            })
        );
    }
}
