export enum DiscountTypeEnum {
    CURRENCY = 'currency',
    PERCENTAGE = 'percentage',
}

export enum DiscountStatusEnum {
    USABLE = 'usable',
    DISABLE = 'unusable',
}

export enum DiscountSortConditionEnum {
    ID = 'id',
    CREATION_DATE = 'created_at',
    AMOUNT = 'amount',
    DISCOUNT_TYPE = 'discount_type',
    STATUS = 'status',
}
