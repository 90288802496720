import { CompanySizes, CompanySizesFromDTOEnum } from '../enums/company-size.enum';

export interface ICompanySizeItem {
    id: number;
    name: CompanySizes;
    size: string;
}

export const companySizeList: ICompanySizeItem[] = [
    {
        id: 1,
        name: CompanySizes.SMALL,
        size: CompanySizesFromDTOEnum.SMALL,
    },
    {
        id: 2,
        name: CompanySizes.MEDIUM,
        size: CompanySizesFromDTOEnum.MEDIUM,
    },
    {
        id: 3,
        name: CompanySizes.LARGE,
        size: CompanySizesFromDTOEnum.LARGE,
    },
];
