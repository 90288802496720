import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { TastingOptionsEnum } from '../enums/tasting-options.enum';
import { TastingStatusEnum } from '../enums/tasting-status.enum';
import { ITasting, ITastingDTOIn, ITastingDTOOut } from '../interfaces/tasting.interface';
import { HeaderErrorEnum } from './../enums/header-errors.enum';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { IOptionDeletionPayload } from './../interfaces/option.interface';
import { ITastingUnplanPayload } from './../interfaces/tasting.interface';

@Injectable({
    providedIn: 'root',
})
export class TastingService {
    constructor(private http: HttpClient) {}

    public getById(id: number): Observable<ITasting> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .get<ITastingDTOIn>(environment.itqiApi + SubUrlsEnum.TASTINGS + `/${id.toString()}`, { headers })
            .pipe(map((result: ITastingDTOIn) => result.tasting));
    }

    public updateTasting(tasting: Partial<ITasting>, id: number): Observable<ITasting> {
        const tastingDTOOut: ITastingDTOOut = {
            tasting,
        };

        return this.http
            .patch<ITastingDTOIn>(environment.itqiApi + SubUrlsEnum.TASTINGS + `/${id.toString()}`, tastingDTOOut)
            .pipe(map((result: ITastingDTOIn) => result.tasting));
    }

    public assignUser(tastingIds: number[], userId: number): Observable<void> {
        return this.http.put<void>(environment.itqiApi + SubUrlsEnum.TASTING_ASSIGN_USER, { tasting_ids: tastingIds, user_id: userId });
    }

    public assignStaYear(tastingIds: number[], staYear: number): Observable<void> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http.put<void>(
            environment.itqiApi + SubUrlsEnum.TASTING_CHANGE_STA_YEAR,
            { tasting_ids: tastingIds, sta_year: staYear },
            { headers }
        );
    }

    public changeProduct(tastingIds: number[], productId: number): Observable<void> {
        return this.http.put<void>(environment.itqiApi + SubUrlsEnum.TASTING_CHANGE_PRODUCT, {
            tasting_ids: tastingIds,
            product_id: productId,
        });
    }

    public deleteOption(id: number, option: TastingOptionsEnum): Observable<void> {
        const payload: IOptionDeletionPayload = {
            options: {
                [option]: false,
            },
        };
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.TASTING_DELETE_OPTION.replace('{{id}}', id.toString()), payload);
    }

    public deleteTasting(id: number): Observable<void> {
        return this.http.delete<void>(environment.itqiApi + SubUrlsEnum.TASTINGS + `/${id.toString()}`);
    }

    public unplanTasting(id: number): Observable<ITasting> {
        const unplanPayload: ITastingUnplanPayload = {
            tasting: {
                tasting_status: TastingStatusEnum.TO_PLAN,
            },
        };

        return this.http
            .patch<ITastingDTOIn>(environment.itqiApi + SubUrlsEnum.TASTINGS + `/${id.toString()}`, unplanPayload)
            .pipe(map((result: ITastingDTOIn) => result.tasting));
    }

    public bulkUnplanTastings(ids: number[]): Observable<void> {
        /* Using http.request with delete as first param cause angular 11 cannot send body with a delete request if doing http.delete */
        return this.http.request<void>('delete', environment.itqiApi + SubUrlsEnum.PLANNING_UNPLAN, { body: { ids } });
    }
}
