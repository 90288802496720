import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { frozenColumns, invoicesColumns } from '../../data/invoices-columns';
import { InvoiceSortConditionEnum } from '../../enums/invoice-filters.enum';
import { IFilter } from '../../interfaces/filter.interface';
import { IInvoiceData, IInvoicesDTOIn, IInvoiceTableData } from '../../interfaces/invoices.interface';
import { InvoicesService } from '../../services/invoices.service';
import { rowPerPageOptions } from '../../utils/table.utils';
import { Invoice } from '../actions/invoices.actions';
import { SharedTablesSelectors } from '../selectors/shared-tables.selectors';
import { DocumentTypeEnum } from './../../enums/invoices.enum';
import { IInvoiceQueryOptions, IInvoiceStatistics } from './../../interfaces/invoices.interface';
import { TableStateModel } from './table.state';

export interface InvoicesStateModel extends TableStateModel<IInvoiceTableData, IInvoiceQueryOptions> {
    data: IInvoiceData[];
    stats: IInvoiceStatistics;
}

const queryOptionsDefaults: IInvoiceQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    sortCondition: '-' + InvoiceSortConditionEnum.DATE,
    filters: [],
    textFilter: '',
};
@State<InvoicesStateModel>({
    name: 'invoice',
    defaults: {
        data: [],
        stats: null,
        tableData: [],
        scrollableColumns: [...invoicesColumns],
        frozenColumns,
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
    },
})
@Injectable()
export class InvoicesState extends SharedTablesSelectors {
    constructor(private invoiceService: InvoicesService) {
        super();
    }

    @Action(Invoice.GetInvoicesFilters)
    public getInvoicesFilters(ctx: StateContext<InvoicesStateModel>): any {
        return this.invoiceService.getFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.patchState({
                    filtersData: result,
                });
            })
        );
    }

    @Action(Invoice.GetAllInvoices)
    public getAllInvoices(ctx: StateContext<InvoicesStateModel>): Observable<IInvoicesDTOIn> {
        const state: InvoicesStateModel = ctx.getState();
        return this.invoiceService.getAllInvoices(state.queryOptions).pipe(
            tap((result: IInvoicesDTOIn) => {
                if (result) {
                    const tableData: IInvoiceTableData[] = this.formatTableData(result.invoices);
                    ctx.patchState({
                        data: result.invoices,
                        stats: result.stats,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    });
                }
            })
        );
    }

    @Action(Invoice.CreateInvoice)
    public createInvoice(ctx: StateContext<InvoicesStateModel>, action: Invoice.CreateInvoice): Observable<void> {
        return this.invoiceService.createInvoice(action.invoice);
    }

    @Action(Invoice.CreateCreditNote)
    public createCreditNote(ctx: StateContext<InvoicesStateModel>, action: Invoice.CreateCreditNote): Observable<void> {
        return this.invoiceService.createCreditNote(action.creditNote);
    }

    @Action(Invoice.CreatePayment)
    public createPayment(ctx: StateContext<InvoicesStateModel>, action: Invoice.CreatePayment): Observable<void> {
        return this.invoiceService.createPayment(action.payment);
    }

    @Action(Invoice.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<InvoicesStateModel>, action: Invoice.SetQueryOptions): void {
        const state: InvoicesStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IInvoiceQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<InvoicesStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(Invoice.UpdateColumnPreferences)
    public updateColumnPreferences(ctx: StateContext<InvoicesStateModel>, action: Invoice.UpdateColumnPreferences): void {
        ctx.setState(
            patch<InvoicesStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(Invoice.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<InvoicesStateModel>): void {
        ctx.setState(
            patch<InvoicesStateModel>({
                scrollableColumns: invoicesColumns,
            })
        );
    }

    @Action(Invoice.ToggleFilters)
    public setFiltersDisplay(ctx: StateContext<InvoicesStateModel>, action: Invoice.ToggleFilters): void {
        ctx.setState(
            patch<InvoicesStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    private formatTableData(data: IInvoiceData[]): IInvoiceTableData[] {
        const tableData: IInvoiceTableData[] = [];

        data.forEach((item: IInvoiceData) => {
            const invoice: IInvoiceTableData = {
                ...item,
                amount: item.invoice_type !== DocumentTypeEnum.INVOICE ? -item.amount : item.amount,
                htva_amount: item.invoice_type !== DocumentTypeEnum.INVOICE ? -item.htva_amount : item.htva_amount,
                discount_amount: item?.discount_amount ?? null,
                discount_description: item.discount?.description,
            };

            tableData.push(invoice);
        });

        return tableData;
    }
}
