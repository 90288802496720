import { DefrostingTimesEnum } from './../enums/defrosting-times.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IDefrostingTimesItem = IGenericListItem<DefrostingTimesEnum>;

export const defrostingTimesList: IDefrostingTimesItem[] = Object.keys(DefrostingTimesEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: DefrostingTimesEnum[key as keyof typeof DefrostingTimesEnum],
    label: `DEFROSTING_TIME.${key}`,
}));
