import { ITableColumn } from '../interfaces/table-column.interface';

export const scrollableAnnouncementTranslationsColumns: ITableColumn[] = [
    {
        field: 'categoryName',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.CAT_NAME',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'announcementDescription',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.DESCRIPTION',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'announcementCreator',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.ANN_CREATOR',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'frenchTranslationStatus',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.FR_TRANSLATION',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'italianTranslationStatus',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.IT_TRANSLATION',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'spanishTranslationStatus',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.ES_TRANSLATION',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'globalTranslationStatus',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.GLOBAL_TRANSLATION_STATUS',
        sortable: false,
        editable: false,
        checked: true,
    },
];

export const frozenAnnouncementTranslationsColumns: ITableColumn[] = [
    {
        field: 'announcementId',
        header: 'TRANSLATIONS_MODULE.ANNOUNCEMENT.TABLE.COL_HEADER.ANN_ID',
        sortable: true,
        editable: false,
        checked: true,
    },
];
