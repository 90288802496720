import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IReportDetails } from '../../interfaces/report-details.interface';
import { ReportManagementService } from '../../services/report-management.service';
import { ReportManagement } from '../actions/report-management.actions';
import { ReportAssignationState } from './report-assignation.state';
import { ReportRedactionState } from './report-redaction.state';

export interface ReportManagementStateModel {
    reportDetails: IReportDetails;
}

@State<ReportManagementStateModel>({
    name: 'reportManagement',
    defaults: {
        reportDetails: null,
    },
    children: [ReportAssignationState, ReportRedactionState],
})
@Injectable()
export class ReportManagementState {
    @Selector()
    public static reportDetails(state: ReportManagementStateModel): IReportDetails {
        return state.reportDetails;
    }

    constructor(private reportManagementService: ReportManagementService) {}

    @Action(ReportManagement.GetDetails)
    public getDetails(ctx: StateContext<ReportManagementStateModel>, action: ReportManagement.GetDetails): Observable<IReportDetails> {
        return this.reportManagementService
            .show(action.id)
            .pipe(tap((result: IReportDetails) => ctx.patchState({ reportDetails: result })));
    }

    @Action(ReportManagement.Update)
    public update(ctx: StateContext<ReportManagementStateModel>, action: ReportManagement.Update): Observable<IReportDetails> {
        return this.reportManagementService.update(action.details, action.id).pipe(
            tap((result: IReportDetails) => {
                ctx.patchState({ reportDetails: result });
            })
        );
    }
}
