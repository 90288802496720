import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {
    ReportAssignationFiltersLabelEnum,
    ReportAssignationFiltersTypeEnum,
} from '../../views/my-workspace/enums/report-assignation-filters.enum';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IFilter, IFilterFromDto } from '../interfaces/filter.interface';
import {
    IReportAssignation,
    IReportAssignationDTO,
    IReportAssignationQueryOptions,
} from '../interfaces/report-assignation.interface';
import { IResultValidationsQueryOptionsDTO } from '../interfaces/results-validation.interface';
import { categoryTypeList, ICategoryTypeItem } from '../utils/category-type.utils';
import { companySizeList, ICompanySizeItem } from '../utils/company-size.utils';
import { mapFiltersToDTO } from '../utils/utility-functions';

@Injectable({
    providedIn: 'root',
})
export class ReportAssignationToolService {
    constructor(private http: HttpClient) {}

    public getAllReportAssignations(queryOptions: IReportAssignationQueryOptions): Observable<IReportAssignationDTO> {
        const body: IResultValidationsQueryOptionsDTO = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IReportAssignationDTO>(environment.itqiApi + SubUrlsEnum.REPORTS_ASSIGNATION, body, {
            params,
        });
    }

    public getReportAssignationFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.REPORTS_ASSIGNATION_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.parseFilter(result)));
    }

    public getReportAssignation(reportId: number): Observable<IReportAssignation> {
        return this.http.get<IReportAssignation>(environment.itqiApi + SubUrlsEnum.REPORTS_ASSIGNATION + '/' + reportId);
    }

    public assignRedactorToReport(tastingIds: number[], redactorId: number): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.REPORT_ASSIGN, {
            tastings: tastingIds,
            redactor_id: redactorId,
        });
    }

    public unassignRedactorFromReport(tastingIds: number[]): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.REPORT_UNASSIGN, {
            tastings: tastingIds,
        });
    }

    public downloadReport(url: string, id: number): Observable<Blob> {
        return this.http
            .get(environment.itqiFiles + url, { responseType: 'blob' })
            .pipe(tap((pdf: Blob) => saveAs(pdf, `tasting-${id}-report`)));
    }

    private parseFilter(filters: IFilterFromDto): IFilter[] {
        const tradPath: string = 'MY_WORKSPACE.FILTERS.LABELS.REPORT_MANAGEMENT.';
        const parsedFilters: IFilter[] = [];

        for (const [key, value] of Object.entries(filters)) {
            switch (key) {
                case ReportAssignationFiltersTypeEnum.STA_YEAR:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.sort().map((v: any) => ({
                            value: v,
                            label: v ? v : '',
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.FEEDBACK_ONLY:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: [
                            { label: tradPath + 'FEEDBACK_ONLY_TRUE', value: true },
                            { label: tradPath + 'FEEDBACK_ONLY_FALSE', value: false },
                        ],
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.COMPANY_SIZE:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: companySizeList.map((item: ICompanySizeItem) => ({
                            value: item.name,
                            label: tradPath + item.size.toString().toUpperCase(),
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.COUNTRY:
                    parsedFilters.push({
                        type: key,
                        label: ReportAssignationFiltersLabelEnum.COUNTRY,
                        options: [],
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.CONTACT_OWNER:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v.id,
                            label: v ? v.name : '',
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.PRESTIGE_AWARD:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v ? tradPath + v.toString().toUpperCase() : '',
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.FINAL_STARS:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v.toString(),
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.ASSIGNED_REDACTOR:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v.id,
                            label: v ? v.email : '',
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.REDACTION_STATUS:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v ? tradPath + v.toString().toUpperCase() : '',
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.OPTIONS:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v ? tradPath + v.toString().toUpperCase() : '',
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.PAYMENT_STATUS:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v ? tradPath + v.toString().toUpperCase() : '',
                        })),
                    });
                    break;
                case ReportAssignationFiltersTypeEnum.CLAIMS:
                    parsedFilters.push({
                        type: key,
                        label: key.toString().toUpperCase(),
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v ? 'CLAIMS.' + v.toString().toUpperCase() : '',
                        })),
                    });
                    break;
                /* Need a special section because categories options must be distinct  */
                case ReportAssignationFiltersTypeEnum.CATEGORY:
                    parsedFilters.push(
                        ...[
                            {
                                type: key + '##' + ReportAssignationFiltersLabelEnum.FOOD_DRINK,
                                label: ReportAssignationFiltersLabelEnum.FOOD_DRINK,
                                options: categoryTypeList.map((item: ICategoryTypeItem) => ({ value: item.value, label: item.label })),
                            },
                            {
                                type: key + '##' + ReportAssignationFiltersLabelEnum.MOTHER_CAT,
                                label: ReportAssignationFiltersLabelEnum.MOTHER_CAT,
                                options: null,
                            },
                            {
                                type: key,
                                label: ReportAssignationFiltersLabelEnum.CATEGORY,
                                options: null,
                            },
                        ]
                    );
                    break;
            }
        }
        return parsedFilters;
    }
}
