import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { frozenColumns, scrollableColumns } from '../../data/results-and-reports-columns';
import { AlertTypeEnum } from '../../enums/alert.enum';
import { ICategory } from '../../interfaces/category.interface';
import { IFilter } from '../../interfaces/filter.interface';
import { ICountryResource } from '../../interfaces/referentials.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { ResultsAndReports } from '../actions/results-and-reports.actions';
import { Tasting } from '../actions/tasting.actions';
import { ResultsAndReportsFiltersLabelEnum, ResultsAndReportsSortConditionsEnum } from './../../enums/results-and-reports-filters.enum';
import {
    IResultsAndReportsData,
    IResultsAndReportsDataDTOIn,
    IResultsAndReportsDetails,
    IResultsAndReportsQueryOptions,
    IResultsAndReportsTableData,
} from './../../interfaces/results-and-reports.interface';
import { ResultsAndReportsService } from './../../services/results-and-reports.service';
import { rowPerPageOptions } from './../../utils/table.utils';
import { capitalize } from './../../utils/utility-functions';
import { SharedTablesSelectors } from './../selectors/shared-tables.selectors';
import { CategoryState, CategoryStateModel } from './category.state';
import { ReferentialsState } from './referentials.state';
import { TableStateModel } from './table.state';

export interface ResultsAndReportsStateModel extends TableStateModel<IResultsAndReportsTableData, IResultsAndReportsQueryOptions> {
    data: IResultsAndReportsData[];
    selectedRow: IResultsAndReportsData;
    multipleSelectedRows: IResultsAndReportsData[];
    details: IResultsAndReportsDetails;
    availableStaYearsForVideoGeneration: number[];
}

const queryOptionsDefaults: IResultsAndReportsQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    sortCondition: '-' + ResultsAndReportsSortConditionsEnum.CREATED_AT,
    filters: [],
    textFilter: '',
};
@State<ResultsAndReportsStateModel>({
    name: 'resultsAndReports',
    defaults: {
        data: [],
        tableData: [],
        scrollableColumns,
        frozenColumns,
        selectedRow: null,
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
        multipleSelectedRows: [],
        details: null,
        availableStaYearsForVideoGeneration: [],
    },
})
@Injectable()
export class ResultsAndReportsState extends SharedTablesSelectors {
    @Selector()
    public static selectedRow(state: ResultsAndReportsStateModel): IResultsAndReportsData {
        return state.selectedRow;
    }

    @Selector()
    public static multipleSelectedRows(state: ResultsAndReportsStateModel): IResultsAndReportsData[] {
        return state.multipleSelectedRows;
    }

    @Selector()
    public static activeColumns(state: ResultsAndReportsStateModel): ITableColumn[] {
        return state.frozenColumns.concat(state.scrollableColumns).filter((column: ITableColumn) => column.checked);
    }

    @Selector([CategoryState, ReferentialsState.countries])
    public static filtersData(state: ResultsAndReportsStateModel, catState: CategoryStateModel, countries: ICountryResource[]): IFilter[] {
        const bareFilters: IFilter[] = state.filtersData;
        if (!catState.categories?.length || !countries.length) {
            return [];
        }

        const motherCats: ICategory[] = catState.motherCategories;
        const childCats: ICategory[] = catState.childCategories;

        const filtersWithCategoryValues: IFilter[] = bareFilters.map((filter: IFilter) => {
            if (filter.label === ResultsAndReportsFiltersLabelEnum.MOTHER_CAT) {
                return {
                    ...filter,
                    options: motherCats.map((c: ICategory) => ({ value: c.id, label: c.name })),
                };
            }

            if (filter.label === ResultsAndReportsFiltersLabelEnum.CAT) {
                return {
                    ...filter,
                    options: childCats.map((c: ICategory) => ({ value: c.id, label: c.name })),
                };
            }

            if (filter.label === ResultsAndReportsFiltersLabelEnum.COUNTRY) {
                return {
                    ...filter,
                    options: countries.map((country: ICountryResource) => ({ value: country.name, label: capitalize(country.name) })),
                };
            }

            return filter;
        });

        return filtersWithCategoryValues;
    }

    @Selector()
    public static resultAndReportDetails(state: ResultsAndReportsStateModel): IResultsAndReportsDetails {
        return state?.details || null;
    }

    @Selector()
    public static isVideoGenerationAvailable(state: ResultsAndReportsStateModel): boolean {
        if (!state.availableStaYearsForVideoGeneration?.length || !state.multipleSelectedRows.length) {
            return false;
        }

        const selectedTastings: IResultsAndReportsData[] = state.multipleSelectedRows;

        /**
         * If there are multiple rows and they don't all share the same generation context
         * (first generation for every tasting or regeneration for every tasting), return false
         *  */
        const initialGenerationContext: boolean = selectedTastings[0].should_regenerate_video;

        if (selectedTastings.some((tasting: IResultsAndReportsData) => tasting.should_regenerate_video !== initialGenerationContext)) {
            return false;
        }

        /**
         * Video generation only enabled if : All tastings have the correct sta year, tastings have no movie url or have the should regen video flag to true, stars count > 0
         */
        return selectedTastings.every(
            (tasting: IResultsAndReportsData) =>
                state.availableStaYearsForVideoGeneration.includes(tasting.sta_year) &&
                (!tasting.movie_url || tasting.should_regenerate_video) &&
                (parseInt(tasting.scores.stars as unknown as string, 10) ?? 0 > 0)
        );
    }

    constructor(private resultsAndReportsService: ResultsAndReportsService) {
        super();
    }

    @Action(ResultsAndReports.GetAll)
    public getAll(ctx: StateContext<ResultsAndReportsStateModel>): Observable<IResultsAndReportsDataDTOIn> {
        const state: ResultsAndReportsStateModel = ctx.getState();

        return this.resultsAndReportsService.getResultsAndReportsData(state.queryOptions).pipe(
            tap((result: IResultsAndReportsDataDTOIn) => {
                const tableData: IResultsAndReportsTableData[] = this.formatTableData(result.results_and_reports);

                ctx.setState(
                    patch<ResultsAndReportsStateModel>({
                        data: result.results_and_reports,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    })
                );
            })
        );
    }

    @Action(ResultsAndReports.Show)
    public show(ctx: StateContext<ResultsAndReportsStateModel>, action: ResultsAndReports.Show): Observable<IResultsAndReportsDetails> {
        const state: ResultsAndReportsStateModel = ctx.getState();

        if (state?.details?.id === action.id) {
            return null;
        }

        return this.resultsAndReportsService.showResultsAndReportsDetails(action.id).pipe(
            tap((result: IResultsAndReportsDetails) => {
                ctx.patchState({
                    details: result,
                });
            })
        );
    }

    @Action(ResultsAndReports.GetFilters)
    public getResultsAndReportsFilters(ctx: StateContext<ResultsAndReportsStateModel>): Observable<IFilter[]> {
        return this.resultsAndReportsService.getResultsAndReportsFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.setState(
                    patch<ResultsAndReportsStateModel>({
                        filtersData: result,
                    })
                );
            })
        );
    }

    @Action(ResultsAndReports.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<ResultsAndReportsStateModel>, action: ResultsAndReports.SetQueryOptions): void {
        const state: ResultsAndReportsStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IResultsAndReportsQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<ResultsAndReportsStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(ResultsAndReports.UpdateColumnPreferences)
    public updateColumnPreferences(
        ctx: StateContext<ResultsAndReportsStateModel>,
        action: ResultsAndReports.UpdateColumnPreferences
    ): void {
        ctx.setState(
            patch<ResultsAndReportsStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(ResultsAndReports.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<ResultsAndReportsStateModel>): void {
        ctx.setState(
            patch<ResultsAndReportsStateModel>({
                scrollableColumns,
            })
        );
    }

    @Action(ResultsAndReports.ToggleFilters)
    public setFiltersDisplay(ctx: StateContext<ResultsAndReportsStateModel>, action: ResultsAndReports.ToggleFilters): void {
        ctx.setState(
            patch<ResultsAndReportsStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    @Action(ResultsAndReports.SetMultipleSelectedRows)
    public setMultipleSelectedRows(
        ctx: StateContext<ResultsAndReportsStateModel>,
        action: ResultsAndReports.SetMultipleSelectedRows
    ): void {
        const state: ResultsAndReportsStateModel = ctx.getState();

        const multipleSelectedRows: IResultsAndReportsData[] = state.data.filter((resultsAndReportsData: IResultsAndReportsData) =>
            action.tastingIds.includes(resultsAndReportsData.id)
        );

        ctx.setState(
            patch<ResultsAndReportsStateModel>({
                multipleSelectedRows,
            })
        );
    }

    @Action(ResultsAndReports.SetSelectedRow)
    public setSelectedRow(ctx: StateContext<ResultsAndReportsStateModel>, action: ResultsAndReports.SetSelectedRow): void {
        const state: ResultsAndReportsStateModel = ctx.getState();

        const selectedRow: IResultsAndReportsData =
            state.data.find((dataItem: IResultsAndReportsData) => dataItem.id === action.tastingId) ?? null;

        ctx.patchState({ selectedRow });
    }

    @Action(ResultsAndReports.UpdateTasting)
    public updateTasting(ctx: StateContext<ResultsAndReportsStateModel>, action: ResultsAndReports.UpdateTasting): Observable<void> {
        return ctx.dispatch(new Tasting.Update(action.tasting, action.tastingId));
    }

    @Action(ResultsAndReports.UploadPackshot)
    public uploadPackshot(ctx: StateContext<ResultsAndReportsStateModel>, action: ResultsAndReports.UploadPackshot): Observable<any> {
        return this.resultsAndReportsService.uploadPackshot(action.file, action.tastingId).pipe(
            tap(() => {
                ctx.dispatch([
                    new FeedbackMessages.Set({ text: 'ALERT.MSG.PACKSHOT_UPLOADED', type: AlertTypeEnum.SUCCESS }),
                    new ResultsAndReports.BustShowCache(),
                ]);
            })
        );
    }

    @Action(ResultsAndReports.BustShowCache)
    public bustShowCache(ctx: StateContext<ResultsAndReportsStateModel>): void {
        ctx.patchState({
            details: null,
        });
    }

    @Action(ResultsAndReports.GetAvailableYearsForVideoGeneration)
    public getAvailableYearsForVideoGeneration(ctx: StateContext<ResultsAndReportsStateModel>): Observable<number[]> {
        const state: ResultsAndReportsStateModel = ctx.getState();

        if (state.availableStaYearsForVideoGeneration.length) {
            return null;
        }

        return this.resultsAndReportsService.getAvailableYearsForVideoGeneration().pipe(
            tap((staYears: number[]) => {
                ctx.patchState({
                    availableStaYearsForVideoGeneration: staYears,
                });
            })
        );
    }

    @Action(ResultsAndReports.GenerateVideo)
    public generateVideo(ctx: StateContext<ResultsAndReportsStateModel>, action: ResultsAndReports.GenerateVideo): Observable<string> {
        return this.resultsAndReportsService.generateVideo(action.tastingIds).pipe(
            tap(() => {
                ctx.dispatch([new FeedbackMessages.Set({ text: 'ALERT.MSG.VIDEO_GENERATED', type: AlertTypeEnum.SUCCESS })]);
            })
        );
    }

    private formatTableData(data: IResultsAndReportsData[]): IResultsAndReportsTableData[] {
        const tableData: IResultsAndReportsTableData[] = [];

        data.forEach((item: IResultsAndReportsData) => {
            const { product } = item;
            const { user } = product;

            const tableDataItem: IResultsAndReportsTableData = {
                tastingId: item.id ?? null,
                public_id: item.public_id ?? null,
                companyName: user.company_name ?? null,
                productName: product.name ?? null,
                userId: user.id ?? null,
                productId: product.id ?? null,
                sta_year: item.sta_year ?? null,
                stars: parseInt(item.scores.stars as unknown as string, 10) ?? null, // TODO temporary solution because Bruno sends stars in IScore payload as string instead of number here
                scores: item.scores ?? null,
                prestigeAward: item.prestige_award ?? null,
                categoryName: product.category?.name ?? null,
                categoryId: product.category?.id ?? null,
                contactOwner: user.contact_owner ?? null,
                bcComments: item.bc_comments ?? null,
                payment_status: user.payment_status ?? null,
                sensoryResultsPDF: item.sensory_results_path ?? null,
                certificate: item.certificate_path ?? null,
                resultsPublicToclient: item.result_public_to_client ?? null,
                packshot: item.packshot_path ?? null,
                publishedOnTheWeb: item.published_on_website ?? null ?? null,
                evaluationReport: item.evaluation_report_path ?? null,
                reportPublicToClient: item.report_public_to_client ?? null,
                hasCommentsAndSuggestions: item.options.comments_suggestions ?? null,
                hasFoodPairing: item.options.food_pairing ?? null,
                hasQuestions: item.options.question ?? null,
                feedback_only: item.feedback_only ?? null,
                companyNameOnCertificate: item.product.user.company_name_on_certificate ?? null,
                testDate: item.test_date ? new Date(item.test_date) : null,
                licenceAgreementValidated: item.licence_validated ?? null,
                movie_url: item.movie_url ?? null,
                should_regenerate_video: item.should_regenerate_video,
                videoPublicToClient: item.video_public_to_client,
                logo: !!user.logo_path,
            };

            tableData.push(tableDataItem);
        });

        return tableData;
    }
}
