import { ITableColumn } from '../interfaces/table-column.interface';

export const scrollableColumns: ITableColumn[] = [
    {
        field: 'categoryName',
        header: 'MY_WORKSPACE.NEW_ANNOUNCEMENTS.TABLE.COL_HEADER.CAT_NAME',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'description',
        header: 'MY_WORKSPACE.NEW_ANNOUNCEMENTS.TABLE.COL_HEADER.DESCRIPTION',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'definition',
        header: 'MY_WORKSPACE.NEW_ANNOUNCEMENTS.TABLE.COL_HEADER.DEFINITION',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'announcement_creator',
        header: 'MY_WORKSPACE.NEW_ANNOUNCEMENTS.TABLE.COL_HEADER.ANN_CREATOR',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'isFoodexValidated',
        header: 'MY_WORKSPACE.NEW_ANNOUNCEMENTS.TABLE.COL_HEADER.FOODEX_OK',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'link',
        header: 'MY_WORKSPACE.NEW_ANNOUNCEMENTS.TABLE.COL_HEADER.LINK',
        sortable: false,
        editable: true,
        checked: true,
    },
];

export const frozenColumns: ITableColumn[] = [
    {
        field: 'announcementId',
        header: 'MY_WORKSPACE.NEW_ANNOUNCEMENTS.TABLE.COL_HEADER.ANNOUNCEMENT_ID',
        sortable: true,
        editable: false,
        checked: true,
    },
];
