import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/* SEE https://stackoverflow.com/questions/40107008/detect-click-outside-angular-component */
@Injectable({ providedIn: 'root' })
export class UtilitiesService {
    public clickedTarget$: Observable<HTMLElement>;
    private documentClickedTarget: Subject<HTMLElement>;

    constructor() {
        this.documentClickedTarget = new Subject<HTMLElement>();
        this.clickedTarget$ = this.documentClickedTarget.asObservable();
    }

    public setTarget(target: EventTarget): void {
        if (target instanceof HTMLElement) {
            this.documentClickedTarget.next(target);
        }
    }
}
