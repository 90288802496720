import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { IProduct } from '../interfaces/product.interface';
import { mockProducts } from './mock-data';
import { IRes } from './mock-response.interface';

export const mockProductsRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: IProduct[] = mockProducts;

    if (req.method === 'GET') {
        const res: IRes = {
            status: 200,
            body: { products: data },
        };

        return of(new HttpResponse(res));
    }

    if (req.method === 'PUT') {
        const productId: string = req.url.match(/(?<id>\d+$)/)[0] || '';

        const res: IRes = {
            status: 200,
            body: { product: req.body.product },
        };

        return of(new HttpResponse(res));
    }
    return of(new HttpResponse());
};
