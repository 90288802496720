import { Component, Input, OnInit, Optional, Self, TemplateRef } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FormControlComponent } from '../../../abstracts/form-control.abstract';
import { generateUniqueId } from '../../../utils/utility-functions';

@Component({
    selector: 'app-group-select-input',
    templateUrl: './group-select-input.component.html',
    styleUrls: ['./group-select-input.component.scss'],
})
export class GroupSelectInputComponent extends FormControlComponent implements OnInit {
    @Input() public optgroups: any[];
    @Input() public optgroupKey: string;
    @Input() public optionsKey: string;
    @Input() public idField: string;
    @Input() public labelField: string;
    @Input() public inputId: string;
    @Input() public placeholder: string;
    @Input() public optionTemplate?: TemplateRef<any>;

    public selectedOptionId: string;
    public selectedOption: any;
    public readonly: boolean;

    constructor(@Self() @Optional() public control: NgControl) {
        super();
        this.control.valueAccessor = this;
    }

    ngOnInit(): void {
        this.inputId = this.inputId || generateUniqueId();
        this.idField = this.idField || 'id';
        this.labelField = this.labelField || 'label';
        this.optgroups = this.optgroups || [];
        this.placeholder = this.placeholder || 'No Value';
        this.readonly = this.readonly || false;
    }

    public writeValue(obj: any): void {
        this.selectedOption = obj;
    }

    public onSelect(event: Event): void {
        const value: string = (event.target as HTMLSelectElement).value;

        let selectedOption: any = null;

        this.optgroups.forEach((optgroup: any) => {
            optgroup[this.optionsKey].forEach((opt: any) => {
                if (opt[this.idField].toString() === value) {
                    selectedOption = opt;
                }
            });
        });

        this.selectedOption = selectedOption;

        this.onChange(this.selectedOption);
    }

    public isSelected(idField: string | number): boolean {
        if (this.selectedOption === null || this.selectedOption === undefined) {
            return false;
        }
        const normalizedIdField: string = idField.toString();
        this.selectedOptionId = this.selectedOption[this.idField] ? this.selectedOption[this.idField].toString() : null;

        return this.selectedOptionId === normalizedIdField;
    }

    public onBlur(): void {
        this.markAsTouched();
    }
}
