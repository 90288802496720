export enum PretestTranslationsFiltersLabelEnum {
    VALIDATION_ALL = 'VALIDATION_ALL',
    VALIDATION_FR = 'VALIDATION_FR',
    VALIDATION_IT = 'VALIDATION_IT',
    VALIDATION_ES = 'VALIDATION_ES',
    CRITERIA_TYPE = 'CRITERIA_TYPE',
}

export enum PretestTranslationsSortConditionsEnum {
    TASTING_ID = 'tasting.id',
    PRODUCT_ID = 'product.id',
    COMPANY_NAME = 'user.company_name',
    CAT_NAME = 'category.name',
    PRODUCT_NAME = 'product.name',
    VALIDATION_FR = 'tasting.validation_fr',
    VALIDATION_IT = 'tasting.validation_it',
    VALIDATION_ES = 'tasting.validation_es',
    CREATED_AT = 'tasting.created_at',
}
