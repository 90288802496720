import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ModalNamesEnum } from '../../enums/modal-names.enum';
import { CancelBtnClick, CloseModal, OpenModal } from '../actions/modal.actions';

export interface ModalStateModel {
    isModalOpened: boolean;
    title: string;
    name: ModalNamesEnum;
}

@State<ModalStateModel>({
    name: 'modal',
    defaults: {
        isModalOpened: false,
        title: '',
        name: null,
        // FOR TESTING
        // isModalOpened: true,
        // title: 'Assign Announcement',
        // name: ModalNamesEnum.PRE_TEST_ASSIGN_ANNOUNCEMENT,
    },
})
@Injectable()
export class ModalState {
    @Selector()
    public static isModalOpened(state: ModalStateModel): boolean {
        return state.isModalOpened;
    }

    @Selector()
    public static title(state: ModalStateModel): string {
        return state.title;
    }

    @Selector()
    public static modalName(state: ModalStateModel): string {
        return state.name;
    }

    @Action(OpenModal)
    public openModal(ctx: StateContext<ModalStateModel>, action: OpenModal): void {
        ctx.patchState({
            isModalOpened: true,
            title: action.payload.title,
            name: action.payload.name,
        });
    }

    @Action(CloseModal)
    public closeModal(ctx: StateContext<ModalStateModel>): void {
        ctx.patchState({
            isModalOpened: false,
            title: '',
            name: null,
        });
    }

    @Action(CancelBtnClick)
    public cancelBtnClick(ctx: StateContext<ModalStateModel>): void {
        ctx.dispatch(new CloseModal());
    }
}
