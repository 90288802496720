import { IReportDetailsUpdatePayload } from '../../interfaces/report-details.interface';

export namespace ReportManagement {
    export class GetDetails {
        public static readonly type = '[Report Management] get details';
        constructor(public id: number) {}
    }

    export class Update {
        public static readonly type = '[Report Management] update';
        constructor(public details: IReportDetailsUpdatePayload, public id: number) {}
    }
}
