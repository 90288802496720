import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { mockResultsAndReportsData, mockResultsAndReportsFilters } from './mock-data';

export const mockResultsAndReportsRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: any = mockResultsAndReportsData;

    if (req.method === 'POST') {
        const res: any = {
            status: 200,
            body: data,
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};

export const mockResultsAndReportsFiltersRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: any = mockResultsAndReportsFilters;

    if (req.method === 'GET') {
        const res: any = {
            status: 200,
            body: data,
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
