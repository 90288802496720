import { SpecStatusEnum } from './../enums/spec-status.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ISpecStatusItem = IGenericListItem<SpecStatusEnum>;

export const specStatusList: ISpecStatusItem[] = Object.keys(SpecStatusEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: SpecStatusEnum[key as keyof typeof SpecStatusEnum],
    label: `SPEC_STATUS.${key}`,
}));
