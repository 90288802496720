import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { HeaderErrorEnum } from '../enums/header-errors.enum';
import { IProduct, IProductsDTOIn } from '../interfaces/product.interface';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { IAssignAnnouncementPayload } from './../interfaces/announcement.interface';
import { IProductDTOIn } from './../interfaces/product.interface';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private http: HttpClient) {}

    public getAllProducts(): Observable<IProduct[]> {
        return this.http
            .get<IProductsDTOIn>(environment.itqiApi + SubUrlsEnum.PRODUCTS)
            .pipe(map((result: IProductsDTOIn) => result.products));
    }

    public updateProduct(product: Partial<IProduct>, id: number): Observable<IProduct> {
        return this.http
            .put<IProductDTOIn>(environment.itqiApi + SubUrlsEnum.PRODUCTS + '/' + id, { product })
            .pipe(map((result: IProductDTOIn) => result.product));
    }

    public createProduct(product: Partial<IProduct>): Observable<IProduct> {
        return this.http
            .post<IProductDTOIn>(environment.itqiApi + SubUrlsEnum.PRODUCTS, { product })
            .pipe(map((result: IProductDTOIn) => result.product));
    }

    public assignAnnouncement(productIds: number[], announcementId: number): Observable<void> {
        const payload: IAssignAnnouncementPayload = {
            product_ids: productIds,
            announcement_id: announcementId,
        };

        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.PRODUCTS_ASSIGN_ANNOUNCEMENT, payload);
    }

    public getById(id: number): Observable<IProduct> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .get<IProductDTOIn>(environment.itqiApi + SubUrlsEnum.PRODUCTS + '/' + id.toString(), { headers })
            .pipe(map((res: IProductDTOIn) => res.product));
    }

    public uploadIngredientsList(id: number, ingredientsList: File): Observable<void> {
        const headers: HttpHeaders = new HttpHeaders({
            ['Content-Type']: 'multipart/form-data',
        });

        const formData: FormData = new FormData();

        formData.append('file', ingredientsList);

        return this.http.post<void>(
            environment.itqiApi + SubUrlsEnum.PRODUCTS_UPLOAD_INGREDIENT_LIST.replace('{{id}}', id.toString()),
            formData,
            {
                headers,
            }
        );
    }
}
