import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { DiscountStatusEnum } from '../enums/discounts.enum';
import { HeaderErrorEnum } from '../enums/header-errors.enum';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IDiscountDTOIn, IDiscountDTOOut, IDiscountsDTOIn } from '../interfaces/discounts.interface';

@Injectable({
    providedIn: 'root',
})
export class DiscountsService {
    constructor(private http: HttpClient) {}

    public getAllDiscounts(sort: string): Observable<IDiscountDTOIn[]> {
        return this.http
            .get<IDiscountsDTOIn>(environment.itqiApi + SubUrlsEnum.DISCOUNTS + '?sort=' + sort)
            .pipe(map((result: IDiscountsDTOIn) => result.discounts));
    }

    public createDiscount(discount: IDiscountDTOOut): Observable<IDiscountDTOIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .post<IDiscountsDTOIn>(environment.itqiApi + SubUrlsEnum.DISCOUNTS, discount, { headers })
            .pipe(map((result: any) => result.discount));
    }

    public updateDiscount(discount: IDiscountDTOOut): Observable<IDiscountDTOIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .put<IDiscountsDTOIn>(environment.itqiApi + SubUrlsEnum.DISCOUNTS + '/' + discount.discount.id, discount, { headers })
            .pipe(map((result: any) => result.discount));
    }

    public disableDiscount(id: number): Observable<void> {
        return this.http.delete<void>(environment.itqiApi + SubUrlsEnum.DISCOUNTS + '/' + id);
    }

    public enableDiscount(id: number): Observable<IDiscountDTOIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .put<IDiscountsDTOIn>(
                environment.itqiApi + SubUrlsEnum.DISCOUNTS + '/' + id,
                { status: DiscountStatusEnum.USABLE },
                { headers }
            )
            .pipe(map((result: any) => result.discount));
    }
}
