import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IFeedbackMessage } from '../../../shared/interfaces/feedback-message.interface';
import { FeedbackMessages } from '../../../shared/store/actions/feedback-messages.actions';
import { AlertTypeEnum } from './../../../shared/enums/alert.enum';
import { FeedbackMessageState } from './../../../shared/store/state/feedback-message.state';

@Component({
    selector: 'app-alerts-container',
    templateUrl: './alerts-container.component.html',
    styleUrls: ['./alerts-container.component.scss'],
})
export class AlertsContainerComponent {
    @Select(FeedbackMessageState.messages) public messages$: Observable<IFeedbackMessage[]>;

    /* ENUMS */
    public alertTypes = AlertTypeEnum;

    constructor(private store: Store) {}

    public removeMessage(messageId: number): void {
        this.store.dispatch(new FeedbackMessages.Reset(messageId));
    }
}
