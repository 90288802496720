import { IGenericListItem } from '../interfaces/generic-list-item.interface';
import { CriteriaTypeEnum } from './../enums/criteria-type.enum';

export type ICriteriaTypeItem = IGenericListItem<CriteriaTypeEnum>;

export const criteriaTypeList: ICriteriaTypeItem[] = Object.keys(CriteriaTypeEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: CriteriaTypeEnum[key as keyof typeof CriteriaTypeEnum],
    label: `CRITERIA_TYPE.${key}`,
}));
