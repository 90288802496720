import { IProduct } from '../../interfaces/product.interface';

export namespace Product {
    export class GetAll {
        public static readonly type = '[Products] Get all';
    }

    export class Update {
        public static readonly type = '[Products] update';

        constructor(public product: Partial<IProduct>, public id: number) {}
    }

    export class Create {
        public static readonly type = '[Products] Create';

        constructor(public product: Partial<IProduct>) {}
    }

    export class AssignAnnouncement {
        public static readonly type = '[Products] assign announcement';

        constructor(public productIds: number[], public announcementId: number) {}
    }

    export class GetById {
        public static readonly type = '[Products] get by id';
        constructor(public id: number) {}
    }

    export class UpdloadIngredientsList {
        public static readonly type = '[Products] upload ingredients list';

        constructor(public id: number, public ingredientsList: File) {}
    }
}
