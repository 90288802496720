import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Select } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { BaseComponent } from '../../abstracts/base.abstract';
import { ICategory } from '../../interfaces/category.interface';
import { ICheckboxControlValue } from '../../interfaces/checkbox-input-config.interface';
import { IFilter } from '../../interfaces/filter.interface';
import { CategoryState } from '../../store/state/category.state';
import { CriteriaTypeEnum } from './../../enums/criteria-type.enum';
import { PaymentStatusEnum } from './../../enums/payment-status.enum';
import { PretestFiltersTypeEnum } from './../../enums/prestest-filters.enum';
import { ProductCategoriesEnum } from './../../enums/product-categories.enum';
import { IFilterOption } from './../../interfaces/filter.interface';

@Component({
    selector: 'app-filter-item',
    templateUrl: './filter-item.component.html',
    styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent extends BaseComponent implements OnInit {
    @Select(CategoryState.motherCategories) public motherCats$: Observable<ICategory[]>;
    @Select(CategoryState.childCategories) public childCategories$: Observable<ICategory[]>;

    @Input() public filter: IFilter;
    @Input() public fg: FormGroup;
    @Input() public appliedFilter$: Observable<IFilter>;

    @Output() public filterChangeEvent = new EventEmitter<IFilter>();
    @Output() public submitFormEvent = new EventEmitter<void>();
    @Output() public toggleFilterEvent = new EventEmitter<FilterItemComponent>();

    public checkedFilterCount: number;
    public showFilter: boolean = false;
    public checkboxFA: FormArray;
    public checkBoxGlobalControl: FormControl;
    public hasRenderedFilters: boolean = false;

    /* enums */
    public pretestFiltersTypeEnum = PretestFiltersTypeEnum;
    public paymentStatusEnum = PaymentStatusEnum;

    private motherCats: ICategory[];
    private childCats: ICategory[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.checkboxFA = this.fg.get([this.filter.label]) as FormArray;

        this.uns = combineLatest([this.motherCats$, this.childCategories$]).subscribe(
            ([motherCats, childCats]: [ICategory[], ICategory[]]) => {
                this.motherCats = motherCats;
                this.childCats = childCats;
            }
        );

        this.setupControl();

        this.uns = this.checkboxFA.valueChanges.pipe(debounceTime(50)).subscribe((values: ICheckboxControlValue[]) => {
            this.updateCheckedFilterCount(values);
            this.updateSelectedOptions(values);
            this.updateGlobalControl(values);
        });

        /* Debounce time nécessaire parce que l'opération qui se produit lorsqu'on a plusieurs filtres de catégorie
        (voir pretest filter component - setupValueChangeSubs) a lui même un debounce time,
        qui faisait que  cette subscription se lançait avant un nouveau calcul des checkboxes, et enlevait donc le statut checked dans certains cas*/
        this.uns = this.appliedFilter$.pipe(debounceTime(200)).subscribe((relevantFilter: IFilter) => {
            if (relevantFilter) {
                this.checkboxFA.controls.forEach((control: AbstractControl) => {
                    const value: ICheckboxControlValue = control.value;

                    let relevantFilterValues: any[];

                    /* If FOOD_DRINK, I need to parse the relevant filter values (category ids) into FOOD DRINK values (ProductCategoriesEnum) */
                    if (relevantFilter.label.includes('FOOD_DRINK')) {
                        relevantFilterValues = this.getFoodDrinkFilterValuesFromCatId(relevantFilter.options);
                        /* If MOTHER_CAT, I need to parse the relevant filter values (category ids) into MOTHER CAT values (mothercategories.id) */
                    } else if (relevantFilter.label.includes('MOTHER_CAT')) {
                        relevantFilterValues = this.getMotherCatFilterValuesFromCatId(relevantFilter.options);
                    } else {
                        relevantFilterValues = relevantFilter.options.map((filterOpt: IFilterOption) => filterOpt.value);
                    }

                    if (relevantFilterValues.includes(value.value) && this.filter.label === relevantFilter.label) {
                        control.patchValue({ ...value, checked: true });
                    }
                });

                /* If all my checkbox controls are checked, checkBox Global control should be checked as well */
                if (!this.checkboxFA.controls.some((control: AbstractControl) => !(control.value as ICheckboxControlValue).checked)) {
                    this.checkBoxGlobalControl.patchValue(true);
                }
            } else {
                this.checkboxFA.controls.forEach((control: AbstractControl) => {
                    const value: ICheckboxControlValue = control.value;

                    control.patchValue({ ...value, checked: false });
                });
            }
        });
    }

    public toggleFilterContent(): void {
        this.hasRenderedFilters = true;
        this.toggleFilterEvent.emit(this);
    }

    public clearFilter(event: MouseEvent): void {
        event.stopPropagation();

        this.checkboxFA.controls.forEach((control: AbstractControl) => {
            const value: ICheckboxControlValue = control.value;

            if (value.checked) {
                control.patchValue({ ...value, checked: false });
            }
        });

        this.updateCheckedFilterCount(this.checkboxFA.value);
        this.updateSelectedOptions(this.checkboxFA.value);
        this.submitFormEvent.emit();
    }

    private updateCheckedFilterCount(values: ICheckboxControlValue[]): void {
        this.checkedFilterCount = values.filter((value: ICheckboxControlValue) => value.checked).length;
    }

    private updateSelectedOptions(values: ICheckboxControlValue[]): void {
        this.filter = {
            ...this.filter,
            options: values
                .filter((value: ICheckboxControlValue) => value.checked)
                .map((value: ICheckboxControlValue) => ({ value: value.value, label: value.label })),
        };

        this.filterChangeEvent.emit(this.filter);
    }

    private setupControl(): void {
        const hasAllFiltersChecked: boolean = this.checkboxFA.controls.every(
            (control: AbstractControl) => (control.value as ICheckboxControlValue).checked
        );

        this.checkBoxGlobalControl = new FormControl(hasAllFiltersChecked);

        this.uns = this.checkBoxGlobalControl.valueChanges.subscribe((value: boolean) => {
            this.checkboxFA.controls.forEach((control: AbstractControl) => control.patchValue({ ...control.value, checked: value }));
        });
    }

    private updateGlobalControl(values: ICheckboxControlValue[]): void {
        if (values.some((value: ICheckboxControlValue) => value.checked === false)) {
            if (this.checkBoxGlobalControl.value) {
                this.checkBoxGlobalControl.patchValue(false, { emitEvent: false });
            }
        } else {
            if (!this.checkBoxGlobalControl.value) {
                this.checkBoxGlobalControl.patchValue(true, { emitEvent: false });
            }
        }
    }

    private getFoodDrinkFilterValuesFromCatId(options: IFilterOption[]): ProductCategoriesEnum[] {
        let hasFoodCats: boolean = false;
        let hasDrinkCats: boolean = false;
        const selectedCatIds: number[] = options.map((opt: IFilterOption) => opt.value);

        const selectedChildCats: ICategory[] = this.childCats.filter((cat: ICategory) => selectedCatIds.includes(cat.id));

        selectedChildCats.forEach((cats: ICategory) => {
            if ([CriteriaTypeEnum.FOOD, CriteriaTypeEnum.FOOD_OLIVE].includes(cats.criteria_type)) {
                hasFoodCats = true;
            } else {
                hasDrinkCats = true;
            }
        });

        const categoryTypes: ProductCategoriesEnum[] = [];

        if (hasDrinkCats) {
            categoryTypes.push(ProductCategoriesEnum.DRINK);
        }

        if (hasFoodCats) {
            categoryTypes.push(ProductCategoriesEnum.FOOD);
        }

        return categoryTypes;
    }

    private getMotherCatFilterValuesFromCatId(options: IFilterOption[]): number[] {
        const selectedCatIds: number[] = options.map((opt: IFilterOption) => opt.value);

        const selectedChildCatParentIds: number[] = this.childCats
            .filter((cat: ICategory) => selectedCatIds.includes(cat.id))
            .map((cat: ICategory) => cat.parentId);

        const selectedMotherCatIds: number[] = this.motherCats
            .filter((cat: ICategory) => selectedChildCatParentIds.includes(cat.id))
            .map((cat: ICategory) => cat.id);

        return selectedMotherCatIds;
    }
}
