import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { mergeMap, tap } from 'rxjs/operators';

import { BaseComponent } from '../../abstracts/base.abstract';
import { ICheckboxControlValue } from '../../interfaces/checkbox-input-config.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { IFilter, IFilterOption } from './../../interfaces/filter.interface';

@Component({
    selector: 'app-table-actions',
    templateUrl: './table-actions.component.html',
    styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public columns$: Observable<ITableColumn[]>;
    @Input() public totalRecords$: Observable<number>;
    @Input() public totalRecordsTranslation: string;
    @Input() public hasAppliedFilters: boolean;
    @Input() public showFilters: boolean;
    @Input() public activeFilters: IFilter[] = [];

    @Output() public columnsUpdateEvent = new EventEmitter<ITableColumn[]>();
    @Output() public columnsResetEvent = new EventEmitter<void>();
    @Output() public filtersToggleEvent = new EventEmitter<boolean>();
    @Output() public clearFilterEvent = new EventEmitter<IFilter>();

    public cols: ITableColumn[];
    public columnsForm: FormGroup;
    public columnCount: number;
    public formattedActiveFilters: IFormattedFilter[];

    constructor(private fb: FormBuilder, private translateService: TranslateService) {
        super();
    }

    public get columnsFA(): FormArray {
        return this.columnsForm.get('columns') as FormArray;
    }

    ngOnInit(): void {
        this.createForm();
        this.setupFormValueSubs();

        this.uns = this.columns$
            .pipe(
                tap((cols: ITableColumn[]) => {
                    this.cols = cols;
                    this.columnCount = this.cols.filter((col: ITableColumn) => col.checked).length;
                    this.initForm();
                }),
                mergeMap(() => this.columnsFA.valueChanges)
            )
            .subscribe(() => {
                this.updateColumnsCheckedValue();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeFilters) {
            this.formatActiveFilters(changes.activeFilters.currentValue);
        }
    }

    public onFilterClick(): void {
        this.filtersToggleEvent.emit(!this.showFilters);
    }

    public resetColumns(): void {
        this.columnsResetEvent.emit();
    }

    public removeFilterTrigger(filter: IFilter): void {
        this.clearFilterEvent.emit(filter);
    }

    private createForm(): void {
        this.columnsForm = this.fb.group({
            allColumns: [false],
            columns: this.fb.array([]),
        });
    }

    private initForm(): void {
        const areAllColumnsChecked: boolean = !this.cols.some((col: ITableColumn) => !col.checked);

        this.columnsForm.patchValue(
            {
                allColumns: areAllColumnsChecked,
            },
            { emitEvent: false }
        );

        this.columnsForm.removeControl('columns');
        this.columnsForm.addControl('columns', this.fb.array([]));

        this.cols.forEach((col: ITableColumn) => this.addToColumnsFA(col));
    }

    private addToColumnsFA(col: ITableColumn): void {
        const control: FormControl = this.fb.control({ value: col, label: col.header, checked: col.checked });
        this.columnsFA.push(control);
    }

    private setupFormValueSubs(): void {
        this.uns = this.columnsForm.get('allColumns').valueChanges.subscribe((value: boolean) => {
            this.columnsFA.controls.forEach((control: AbstractControl) =>
                control.patchValue({ ...control.value, checked: value }, { emitEvent: false })
            );
            this.updateColumnsCheckedValue();
        });
    }

    private updateColumnsCheckedValue(): void {
        const checkboxValues: ICheckboxControlValue[] = this.columnsFA.value;

        const updatedCols: ITableColumn[] = checkboxValues.map((checkValue: ICheckboxControlValue) => {
            const col: ITableColumn = checkValue.value;

            return {
                ...col,
                checked: checkValue.checked,
            };
        });

        this.columnsUpdateEvent.emit(updatedCols);
    }

    private formatActiveFilters(activeFilters: IFilter[]): void {
        this.formattedActiveFilters = activeFilters.length
            ? activeFilters.map((f: IFilter) => ({
                  label: f.label,
                  values: f.options
                      .map((option: IFilterOption) =>
                          typeof option.label === 'string' ? this.translateService.instant(option.label) : option.label
                      )
                      .join(', '),
                  rawFilter: f,
              }))
            : [];
    }
}

interface IFormattedFilter {
    label: string;
    values: string;
    rawFilter: IFilter;
}
