export enum AnnouncementFiltersDTOEnum {
    CAT = 'announcement.category',
    CONTACT_OWNER = 'announcement.contact_owners',
}

export enum AnnouncementFiltersLabelEnum {
    FOOD_DRINK = 'ANNOUNCEMENT.FOOD_DRINK',
    CAT = 'ANNOUNCEMENT.CAT',
    ANN_CREATOR = 'ANNOUNCEMENT.ANN_CREATOR',
}

export enum AnnouncementsSortConditionsEnum {
    CREATED_AT = 'announcement.created_at',
    ANNOUNCEMENT_ID = 'announcement.id',
    VALIDATION_STATUS = 'announcement.validation_status',
    CATEGORY_ID = 'announcement.category_id',
    CATEGORY_NAME = 'category.name',
    CRITERIA_TYPE = 'category.criteria_type',
    FOODEX_OK = 'announcement.foodex_ok',
    CONTACT_OWNER = 'announcement.contact_owner',
}
