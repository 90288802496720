import { TastingOptionsEnum } from '../../enums/tasting-options.enum';
import { ITasting } from '../../interfaces/tasting.interface';

export namespace Tasting {
    export class Show {
        public static readonly type = '[Tasting] show';

        constructor(public id: number) {}
    }
    export class Update {
        public static readonly type = '[Tasting] update';

        constructor(public tasting: Partial<ITasting>, public id: number) {}
    }

    export class AssignUser {
        public static readonly type = '[Tasting] assign user';

        constructor(public tastingIds: number[], public userId: number) {}
    }

    export class AssignStaYear {
        public static readonly type = '[Tasting] assign sta year';

        constructor(public tastingIds: number[], public staYear: number) {}
    }

    export class ChangeProduct {
        public static readonly type = '[Tasting] change product';

        constructor(public tastingIds: number[], public productId: number) {}
    }

    export class SetOptionToRemove {
        public static readonly type = '[Tasting] Set option to remove';

        constructor(public option: TastingOptionsEnum) {}
    }

    export class DeleteOption {
        public static readonly type = '[Tasting] Delete option';

        constructor(public tastingId: number, public option: TastingOptionsEnum) {}
    }

    export class Delete {
        public static readonly type = '[Tasting] Delete';

        constructor(public tastingId: number) {}
    }

    export class Unplan {
        public static readonly type = '[Tasting] Unplan';

        constructor(public tastingId: number) {}
    }

    export class BulkUnplan {
        public static readonly type = '[Tasting] Bulk Unplan';

        constructor(public tastingIds: number[]) {}
    }
}
