import { IExportColumn } from '../interfaces/export-column.interface';
import { IEnumData } from './../interfaces/enum-data.interface';
import { IFilter, IFilterOption, IFilterToDto } from './../interfaces/filter.interface';

/* See https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript */
export const generateUniqueId = (): string => Math.random().toString(36).substring(2, 10);

/* See https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript */
export const generateNumberBetweenTwoNumbers = (min: number, max: number): string =>
    Math.floor(Math.random() * (max - min + 1) + min).toString();

export const enumToArray = (enumToTransform: any): IEnumData[] =>
    Object.keys(enumToTransform)
        .filter((e: any) => isNaN(Number(e)))
        .map((key: string, i: number) => ({ id: i, value: enumToTransform[key] }));

export const capitalize = (word: string): string => word[0].toUpperCase() + word.slice(1).toLowerCase();

/* Sort function to order export fields according to masterfile. */

export const getSortedExportColumns = <T>(sortingEnum: T): ((a: IExportColumn, b: IExportColumn) => number) => {
    const pretestExportOrderData: string[] = enumToArray(sortingEnum).map((data: IEnumData) => data.value);

    return (a: IExportColumn, b: IExportColumn) => {
        const aIndex: number = pretestExportOrderData.indexOf(a.field);
        const bIndex: number = pretestExportOrderData.indexOf(b.field);

        return aIndex > bIndex ? 1 : -1;
    };
};

/* See  https://stackoverflow.com/questions/2283566/how-can-i-round-a-number-in-javascript-tofixed-returns-a-string */
export const toFixedNumber = (num: number, digits: number, base: number = 10): number => {
    const power: number = Math.pow(base, digits);

    return Math.round(num * power) / power;
};

export const mapFiltersToDTO = (filters: IFilter[]): IFilterToDto[] =>
    filters?.length ? filters.map((f: IFilter) => ({ type: f.type, values: f.options.map((option: IFilterOption) => option.value) })) : [];
