export enum PretestFiltersTypeEnum {
    CLAIMS = 'product.claims',
    STORAGE_CONDITIONS = 'product.storage_conditions',
    DEFROSTING_TIME = 'product.defrosting_time',
    CATEGORY = 'product.category',
    SEASON = 'tasting.season',
    OPTIONS = 'tasting.options',
    CLIENT_SPECS = 'tasting.view_client_specs',
    BC_SPECS = 'tasting.view_bc_specs',
    PAYMENT_STATUS = 'user.payment_status',
    CLIENT_DELIVERY_INSTRUCTIONS = 'tasting.client_delivery_instructions',
    SAMPLES_DELIVERED = 'tasting.samples_delivered',
    NEW_CLIENT = 'tasting.new_client',
    STA_YEAR = 'tasting.sta_year',
    TASTING_STATUS = 'tasting.tasting_status',
    CANDIDATE_AWARD = 'tasting.candidate_award',
    PAST_STARS = 'tasting.past_stars',
    FEEDBACK_ONLY = 'tasting.feedback_only',
    SPICINESS_LEVEL = 'product.spiciness_level',
    COMPANY_SIZE = 'user.company_size',
    NON_STANDARD_INGREDIENT = 'product.non_standard_ingredient',
    CONTACT_OWNER = 'user.contact_owner',
    COUNTRY = 'user.country',
    DELIVERY_STATUS = 'tasting.delivery_status',
    SPECIFIC_PLANNING = 'tasting.specific_planning',
    OPTION_COUNT = 'tasting.options_count',
    ANNOUNCEMENT_READY = 'announcement.ready',
    PLANNING_ID = 'tasting.planning_id',
    PLANNING_DETAILS = 'planning_item.day',
    INGREDIENTS_LIST = 'product.ingredient_list',
    ITI_CLASS = 'product.iti_class',
    TRANSPORT_CONDITIONS = 'product.transport_conditions',
    PACKSHOT = 'product.image',
    LOGO = 'user.logo',
}

export enum PretestFiltersLabelEnum {
    STA_YEAR = 'PRE_TEST.STA_YEAR',
    CONTACT_OWNER = 'PRE_TEST.CONTACT_OWNER',
    TASTING_STATUS = 'PRE_TEST.TASTING_STATUS',
    SEASON = 'PRE_TEST.SEASON',
    FOOD_DRINK = 'PRE_TEST.FOOD_DRINK',
    INGREDIENTS_LIST = 'PRE_TEST.INGREDIENTS_LIST',
    ITI_CLASS = 'PRE_TEST.ITI_CLASS',
    TRANSPORT_CONDITIONS = 'PRE_TEST.TRANSPORT_CONDITIONS',
    MOTHER_CAT = 'PRE_TEST.MOTHER_CAT',
    CAT = 'PRE_TEST.CAT',
    OPTIONS = 'PRE_TEST.OPTIONS',
    FEEDBACK_ONLY = 'PRE_TEST.FEEDBACK_ONLY',
    NEW_CLIENT = 'PRE_TEST.NEW_CLIENT',
    PAST_STARS = 'PRE_TEST.PAST_STARS',
    CANDIDATE_AWARD = 'PRE_TEST.CANDIDATE_AWARD',
    PAYMENT_STATUS = 'PRE_TEST.PAYMENT_STATUS',
    CLIENT_DELIVERY_INSTRUCTIONS = 'PRE_TEST.CLIENT_DELIVERY_INSTRUCTIONS',
    SAMPLES_DELIVERED = 'PRE_TEST.SAMPLES_DELIVERED',
    DELIVERY_STATUS = 'PRE_TEST.DELIVERY_STATUS',
    SPECIFIC_PLANNING = 'PRE_TEST.SPECIFIC_PLANNING',
    CLIENT_SPECS = 'PRE_TEST.CLIENT_SPECS',
    BC_SPECS = 'PRE_TEST.BC_SPECS',
    CLAIMS = 'PRE_TEST.CLAIMS',
    STORAGE_CONDITIONS = 'PRE_TEST.STORAGE_CONDITIONS',
    DEFROSTING_TIME = 'PRE_TEST.DEFROSTING_TIME',
    SPICINESS_LEVEL = 'PRE_TEST.SPICINESS_LEVEL',
    NON_STANDARD_INGREDIENT = 'PRE_TEST.NON_STANDARD_INGREDIENT',
    COUNTRY = 'PRE_TEST.COUNTRY',
    COMPANY_SIZE = 'PRE_TEST.COMPANY_SIZE',
    OPTION_COUNT = 'PRE_TEST.OPTION_COUNT',
    ANNOUNCEMENT_READY = 'PRE_TEST.ANNOUNCEMENT_READY',
    PLANNING_ID = 'PRE_TEST.PLANNING_ID',
    PLANNING_DETAILS = 'PRE_TEST.PLANNING_DETAILS',
    PACKSHOT = 'PRE_TEST.PACKSHOT',
    LOGO = 'PRE_TEST.LOGO',
}

export enum PretestSortConditionsEnum {
    USER_ID = 'user.id',
    PRODUCT_ID = 'product.id',
    ORDER_DATE = 'tasting.orderdate',
    STA_YEAR = 'tasting.sta_year',
    CREATED_AT = 'tasting.created_at',
    TASTING_ID = 'tasting.id',
    SEASON = 'tasting.season',
    TASTING_STATUS = 'tasting.tasting_status',
    CLIENT_SPECS = 'tasting.view_client_specs',
    BC_SPECS = 'tasting.view_bc_specs',
    COUNTRY = 'user.country',
    NEW_CLIENT = 'tasting.new_client',
    CONTACT_OWNER = 'user.contact_owner',
    CANDIDATE_AWARD = 'tasting.candidate_award',
    PAYMENT_STATUS = 'tasting.payment_status',
    SAMPLES_DELIVERED = 'tasting.samples_delivered',
    PAST_STARS = 'tasting.past_stars',
    CATEGORY_ID = 'product.category_id',
    DELIVERY_STATUS = 'tasting.delivery_status',
    NON_STANDARD_INGREDIENT = 'product.non_standard_ingredient',
    SPECIFIC_PLANNING = 'tasting.specific_planning',
    ANNOUNCEMENT_ID = 'product.announcement_id',
    SPICINESS_LEVEL = 'product.spiciness_level',
    BC_PREPARATION_INSTRUCTIONS = 'product.instructions_id',
    PLANNING_DETAILS = 'planning_item.day',
    SHELF_LIFE = 'tasting.shelf_life'
}
