import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { TastingCriteriaEnum } from '../enums/tasting-criteria.enum';
import {
    IFoodPairingOptionContent,
    IQuestionOptionContent,
    IReportCriteriaContent,
    IReportDetails,
    IReportDetailsDTOIn,
    IReportJuryContent,
    IReportRedactorContent,
} from '../interfaces/report-details.interface';
import { ReportDetailsCriteriaEnum } from './../enums/report-details-main-criteria';
import { IReportDetailsUpdatePayload, ISuggestionsContent } from './../interfaces/report-details.interface';

@Injectable({
    providedIn: 'root',
})
export class ReportManagementService {
    constructor(private http: HttpClient) {}

    public show(id: number): Observable<IReportDetails> {
        return this.http
            .get<IReportDetailsDTOIn>(environment.itqiApi + SubUrlsEnum.REPORT_DETAILS + `/${id}`)
            .pipe(map((result: IReportDetailsDTOIn) => this.mapReportPayload(result)));
    }

    public update(payload: IReportDetailsUpdatePayload, reportId: number): Observable<IReportDetails> {
        return this.http
            .put<IReportDetailsDTOIn>(environment.itqiApi + SubUrlsEnum.REPORT_DETAILS + `/${reportId}`, payload)
            .pipe(map((result: IReportDetailsDTOIn) => this.mapReportPayload(result)));
    }

    private mapReportPayload(report: IReportDetailsDTOIn): IReportDetails {
        const criteriaContent: IReportCriteriaContent[] = [
            {
                label: `REPORT_DETAILS.${ReportDetailsCriteriaEnum.GENERAL.toUpperCase()}`,
                type: ReportDetailsCriteriaEnum.GENERAL,
                score: report.final_score,
                redactor_comment: report?.redactor_content?.overall_comment ?? null,
                jury_content: report.test_comments.map((juryContent: IReportJuryContent) => ({
                    name: juryContent.tester_name,
                    comment: {
                        original: juryContent.overall_comment.original,
                        translated_en: juryContent.overall_comment.translated_en,
                        pos: juryContent.overall_comment.pos,
                        pos_en: juryContent.overall_comment.pos_en,
                        neg: juryContent.overall_comment.neg,
                        neg_en: juryContent.overall_comment.neg_en,
                    },
                })),
            },
        ];

        /* add all criteria to criteria content in addition to general comments */
        criteriaContent.push(
            ...Object.entries(TastingCriteriaEnum)
                .filter(([key, value]: [string, TastingCriteriaEnum]) => value !== TastingCriteriaEnum.CR1) // Filter out CR1, it's first impression and not used in report details
                .map(([key, value]: [string, TastingCriteriaEnum]) => ({
                    label: `REPORT_CRITERIA.${report.criteria_type.toUpperCase()}.${value.toUpperCase()}`,
                    type: ReportDetailsCriteriaEnum[key as keyof typeof ReportDetailsCriteriaEnum],
                    score: report[value] as string,
                    redactor_comment: report?.redactor_content?.[value as keyof IReportRedactorContent] ?? null,
                    jury_content:
                        report.test_comments.map((juryContent: IReportJuryContent) => ({
                            name: juryContent.tester_name,
                            comment: {
                                original: juryContent[value].original,
                                translated_en: juryContent[value].translated_en,
                                pos: juryContent[value]?.pos,
                                pos_en: juryContent[value]?.pos_en,
                                neg: juryContent[value]?.neg,
                                neg_en: juryContent[value]?.neg_en,
                            },
                        })) || null,
                }))
        );

        let questionOptionContent: IQuestionOptionContent = null;
        let pairingOptionContent: IFoodPairingOptionContent = null;
        let suggestionsContent: ISuggestionsContent = null;

        if (report.options.comments_suggestions) {
            suggestionsContent = {
                label: 'REPORT_DETAILS.SUGGESTIONS',
                redactor_comment: report?.redactor_content?.suggestion ?? null,
                jury_content: report.test_comments
                    .filter((juryContent: IReportJuryContent) => juryContent.suggestion)
                    .map((juryContent: IReportJuryContent) => ({
                        name: juryContent.tester_name,
                        comment: {
                            original: juryContent?.suggestion?.original ?? null,
                            translated_en: juryContent?.suggestion?.translated_en ?? null,
                        },
                    })),
            };
        }

        if (report.options.food_pairing) {
            pairingOptionContent = {
                label: 'REPORT_DETAILS.FOOD_PAIRING',
                redactor_pairing_explanation: report?.redactor_content?.pairing_explanation || null,
                jury_content: report.test_comments
                    .filter(
                        (juryContent: IReportJuryContent) =>
                            juryContent.pairing_explanation ||
                            juryContent.pairing_explanation_two ||
                            juryContent.pairing_suggestion ||
                            juryContent.pairing_suggestion_two
                    )
                    .map((juryContent: IReportJuryContent) => ({
                        name: juryContent.tester_name,
                        pairing_explanation: {
                            original: juryContent?.pairing_explanation?.original ?? null,
                            translated_en: juryContent?.pairing_explanation?.translated_en ?? null,
                        },
                        pairing_suggestion: {
                            original: juryContent?.pairing_suggestion?.original ?? null,
                            translated_en: juryContent?.pairing_suggestion?.translated_en ?? null,
                        },
                        pairing_explanation_two: {
                            original: juryContent?.pairing_explanation_two?.original ?? null,
                            translated_en: juryContent?.pairing_explanation_two?.translated_en ?? null,
                        },
                        pairing_suggestion_two: {
                            original: juryContent?.pairing_suggestion_two?.original ?? null,
                            translated_en: juryContent?.pairing_suggestion_two?.translated_en ?? null,
                        },
                    })),
            };
        }

        if (report.options.question) {
            questionOptionContent = {
                label: 'REPORT_DETAILS.QUESTIONS',
                specific_question: report.specific_question,
                specific_question_two: report.specific_question_two ?? null,
                specific_question_three: report.specific_question_three ?? null,
                redactor_question: report?.redactor_content?.specific_question ?? null,
                redactor_question_two: report.redactor_content?.specific_question_two ?? null,
                redactor_question_three: report?.redactor_content?.specific_question_three ?? null,
                jury_content: report.test_comments
                    .filter((juryContent: IReportJuryContent) => juryContent.specific_question)
                    .map((juryContent: IReportJuryContent) => ({
                        name: juryContent.tester_name,
                        specific_question: {
                            original: juryContent?.specific_question?.original ?? null,
                            translated_en: juryContent?.specific_question?.translated_en ?? null,
                        },
                        specific_question_two: {
                            original: juryContent?.specific_question_two?.original ?? null,
                            translated_en: juryContent?.specific_question_two?.translated_en ?? null,
                        },
                        specific_question_three: {
                            original: juryContent?.specific_question_three?.original ?? null,
                            translated_en: juryContent?.specific_question_three?.translated_en ?? null,
                        },
                    })),
            };
        }

        const details: IReportDetails = {
            tasting_id: report.tasting_id,
            report_id: report.report_id,
            redaction_status: report.redaction_status,
            highlighted_comment: report.highlighted_comment ?? null,
            redactor: report.redactor_mail ?? null,
            alcohol_level: report.alcohol_level ?? null,
            spiciness_level: report.spiciness_level ?? null,
            storage_conditions: report.storage_conditions ?? null,
            cr1: report.cr1,
            announcement_desc: report.announcement_desc,
            options: report.options,
            criteria_content: criteriaContent,
            suggestions: suggestionsContent,
            question_option_content: questionOptionContent,
            pairing_option_content: pairingOptionContent,
            redactor_content: report?.redactor_content ?? null,
        };

        return details;
    }
}
