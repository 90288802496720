export enum FeatureEnum {
    ALL_ACCESS = 'all_access',
    PRETEST_ACCESS = 'pretest_access',
    PRETEST_FULL_COLUMN_ACCESS = 'pretest_full_column_access',
    PRETEST_TRAD = 'pretest_trad',
    PRETEST_FOODEX_PLAN = 'pretest_foodex',
    PRETEST_UPS_EXPORT = 'pretest_ups',
    ANNOUNCEMENT_ACCESS = 'announcement_access',
    ANNOUNCEMENT_TRAD = 'announcement_trad',
    ANNOUNCEMENT_FOODEX = 'announcement_foodex',
    ANNOUNCEMENT_EXPORT = 'announcement_export',
    NEW_ANNOUNCEMENT_ACCESS = 'new_announcement_access',
    NEW_ANNOUNCEMENT_FOODEX = 'new_announcement_foodex',
    RESULT_ACCESS = 'result_access',
    RESULT_UPS_EXPORT = 'result_ups_export',
    INVOICE_ACCESS = 'invoice_access',
    INVOICE_ICNP_EXPORT_STA = 'invoice_icnp_export_sta',
    DISCOUNTS_ACCESS = 'discount_access',
    DISCOUNTS_EDITION = 'discount_edition',
    DISCOUNTS_DISABLED = 'discount_disabled',
    PLANNING_ACCESS = 'planning_access',
    PLANNING_EDITION = 'planning_edition',
    REPORT_ASSIGNATION_ACCESS = 'report_assignation_access',
    RESULT_VALIDATION_ACCESS = 'result_validation_access',
    USER_RIGHTS_MANAGEMENT_ACCESS = 'user_right_management_access',
    PRETEST_DESTRUCTIVE_ACTION = 'pretest_destructive_action',
    REPORT_MANAGEMENT_ACCESS = 'report_management_access',
    REPORT_REDACTION_ACCESS = 'report_management_redaction_access',
    REPORT_MANAGEMENT_ASSIGNATION = 'report_management_assignation',
    REPORT_MANAGEMENT_REDACTION = 'report_management_redaction',
    RESULT_VALIDATION_TOOL_ACCESS = 'result_validation_tool_access',
}
