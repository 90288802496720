export enum InvoiceExportFieldsEnum {
    ID = 'invoice.id',
    CREATED_AT = 'invoice.created_at',
    USER_ID = 'invoice.user_id',
    XTVA_AMOUNT = 'invoice.htva_amount',
    VAT_AMOUNT = 'invoice.tva_amount',
    VAT_CODE = 'invoice.vat_code',
    AMOUNT = 'invoice.amount',
    VAT_NUMBER = 'invoice.tva',
    BILLING_COMPANY_NAME = 'invoice.company_name',
    BILLING_ADDRESS = 'invoice.street',
    BILLING_ZIP = 'invoice.zip_code',
    BILLING_CITY = 'invoice.city_reference',
    BILLING_COUNTRY = 'invoice.country',
    CONTACT_OWNER = 'contact_owner.name',
    STA_YEAR = 'invoice.sta_year',
    DISCOUNT_AMOUNT = 'invoice.discount_amount',
    DISCOUNT_DESCRIPTION = 'discount.description',
    COMPANY_SIZE = 'user.company_size',
}
