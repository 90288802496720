import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { featuresWithPermissions } from '../../../shared/data/permissions';
import { AuthState } from '../../../shared/store/state/auth.state';

@Component({
    selector: 'app-sub-nav',
    templateUrl: './sub-nav.component.html',
    styleUrls: ['./sub-nav.component.scss'],
})
export class SubNavComponent {
    @Select(AuthState.isAuthenticated) public isAuth$: Observable<boolean>;
    public permissions = featuresWithPermissions;
}
