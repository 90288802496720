import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IAdminRole, IRole } from '../interfaces/user-rigts.interface';

@Injectable({
    providedIn: 'root',
})
export class UserRightService {
    constructor(private http: HttpClient) {}

    public getAdminsRights(): Observable<IAdminRole[]> {
        return this.http.get<IAdminRole[]>(environment.itqiApi + SubUrlsEnum.ADMINS).pipe(map((result: any) => result.administrators));
    }

    public updateAdminsRights(id: number, roleName: string): Observable<IAdminRole> {
        return this.http
            .put<IAdminRole>(environment.itqiApi + SubUrlsEnum.ADMINS + '/' + id, { administrator: { role: roleName } })
            .pipe(map((result: any) => result.administrator));
    }

    public getRolesAndRights(): Observable<IRole[]> {
        return this.http.get<IRole[]>(environment.itqiApi + SubUrlsEnum.ROLES).pipe(map((result: any) => result.roles));
    }

    public createAdmin(role: string, email: string): Observable<void> {
        const admin: IAdminPayload = { administrator: { role, email } };
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.ADMINS, admin);
    }

    public validateAdminEmail(token: string, password: string): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.ADMIN_REGISTER + token, { password });
    }

    public changeAdminPassword(email: string, password: string): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.CHANGE_PASSWORD, { email, password });
    }

    public deleteAdmin(userId: number): Observable<void> {
        return this.http.delete<void>(environment.itqiApi + SubUrlsEnum.DELETE_ADMIN + userId);
    }
}

interface IAdminPayload {
    administrator: {
        role: string;
        email: string;
    };
}
