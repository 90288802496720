export enum ReportRedactionFiltersTypeEnum {
    STA_YEAR = 'tasting.sta_year',
    CATEGORY = 'product.category',
    OPTIONS = 'tasting.options',
    FEEDBACK_ONLY = 'tasting.feedback_only',
    STARS = 'final_score.stars',
    OPTION_COUNT = 'tasting.options_count',
    REDACTION_STATUS = 'report_management.redaction_status',
}

export enum ReportRedactionFiltersLabelEnum {
    STA_YEAR = 'PRE_TEST.STA_YEAR',
    FOOD_DRINK = 'PRE_TEST.FOOD_DRINK',
    MOTHER_CAT = 'PRE_TEST.MOTHER_CAT',
    CAT = 'PRE_TEST.CAT',
    STARS = 'FINAL_SCORE.FINAL_STARS',
    OPTIONS = 'PRE_TEST.OPTIONS',
    FEEDBACK_ONLY = 'PRE_TEST.FEEDBACK_ONLY',
    OPTION_COUNT = 'PRE_TEST.OPTION_COUNT',
    REDACTION_STATUS = 'REPORT_MANAGEMENT.REDACTION_STATUS',
}

export enum ReportRedactionSortConditionEnum {
    TASTING_ID = 'tasting.id',
    PRODUCT_ID = 'product.id',
    USER_ID = 'user.id',
    FINAL_STARS = 'final_score.final_stars',
    REDACTION_STATUS = 'report_management.redaction_status',
    QUESTION = 'new_option.question',
    FOOD_PAIRING = 'new_option.food_pairing',
    COMMENT = 'new_option.comment',
    CREATED_AT = 'tasting.created_at',
}
