import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { BaseComponent } from './base.abstract';

@Directive()
export abstract class FormValidatorComponent extends BaseComponent implements Validator {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public validatorOnChange = (): any => {};

    public validate(control: AbstractControl): ValidationErrors {
        return null;
    }

    public registerOnValidatorChange?(fn: () => void): void {
        this.validatorOnChange = fn;
    }
}
