export enum CompanySizesEnum {
    SMALL = 'BELOW 250K Euros',
    MEDIUM = 'BELOW 10M Euros',
    BIG = 'ABOVE 10M Euros',
}

export enum CompanySizes {
    SMALL = 'BELOW 250K Euros',
    MEDIUM = 'BELOW 10M Euros',
    LARGE = 'ABOVE 10M Euros',
}

export enum CompanySizesFromDTOEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}
