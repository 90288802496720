import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { DiscountStatusEnum } from '../../enums/discounts.enum';
import { IDiscountDTOIn } from '../../interfaces/discounts.interface';
import { DiscountsService } from '../../services/discounts.service';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import {
    CreateDiscount,
    DisableDiscount,
    EnableDiscount,
    GetAllDiscounts,
    SetSortDiscount,
    UpdateDiscount,
} from './../actions/discounts.actions';

export interface DiscountsStateModel {
    discounts: IDiscountDTOIn[];
    sort: string;
}

@State<DiscountsStateModel>({
    name: 'discounts',
    defaults: {
        discounts: [],
        sort: 'status,-created_at',
    },
})
@Injectable()
export class DiscountsState {
    @Selector()
    public static discounts(state: DiscountsStateModel): IDiscountDTOIn[] {
        return state.discounts;
    }

    @Selector()
    public static discountsCodes(state: DiscountsStateModel): string[] {
        return state.discounts.map((d: IDiscountDTOIn) => d.code);
    }

    @Selector()
    public static discountsDescriptions(state: DiscountsStateModel): string[] {
        return state.discounts.map((d: IDiscountDTOIn) => d.description);
    }

    constructor(private discountsService: DiscountsService) {}

    @Action(GetAllDiscounts)
    public getAllDiscounts(ctx: StateContext<DiscountsStateModel>): Observable<IDiscountDTOIn[]> {
        const state: DiscountsStateModel = ctx.getState();

        return this.discountsService.getAllDiscounts(state.sort).pipe(
            tap((result: IDiscountDTOIn[]) => {
                ctx.patchState({
                    discounts: result,
                });
            })
        );
    }

    @Action(UpdateDiscount)
    public updateDiscount(ctx: StateContext<DiscountsStateModel>, action: UpdateDiscount): Observable<IDiscountDTOIn> {
        const state = ctx.getState();
        return this.discountsService.updateDiscount(action.discount).pipe(
            tap((result: IDiscountDTOIn) => {
                ctx.setState(
                    patch({
                        discounts: updateItem<IDiscountDTOIn>((d: IDiscountDTOIn) => d.id === result.id, result),
                    })
                );
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 422) {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'DISCOUNT.ERROR_SAME_CODE', type: AlertTypeEnum.ERROR }));
                    return throwError('DISCOUNT.ERROR_SAME_CODE');
                } else {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ERRORS.GENERIC', type: AlertTypeEnum.ERROR }));
                    return of(null);
                }
            })
        );
    }

    @Action(CreateDiscount)
    public createDiscount(ctx: StateContext<DiscountsStateModel>, action: CreateDiscount): Observable<IDiscountDTOIn> {
        const state = ctx.getState();
        return this.discountsService.createDiscount(action.discount).pipe(
            tap((result: IDiscountDTOIn) => {
                ctx.patchState({
                    discounts: [...state.discounts, result],
                });
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 422) {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'DISCOUNT.ERROR_SAME_CODE', type: AlertTypeEnum.ERROR }));
                    return throwError('DISCOUNT.ERROR_SAME_CODE');
                } else {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ERRORS.GENERIC', type: AlertTypeEnum.ERROR }));
                    return of(null);
                }
            })
        );
    }

    @Action(DisableDiscount)
    public disableDiscount(ctx: StateContext<DiscountsStateModel>, action: DisableDiscount): Observable<void> {
        return this.discountsService.disableDiscount(action.payload.id).pipe(
            tap(() => {
                const discount: IDiscountDTOIn = {
                    ...ctx.getState().discounts.find((d: IDiscountDTOIn) => d.id === action.payload.id),
                    status: DiscountStatusEnum.DISABLE,
                };
                ctx.setState(
                    patch({
                        discounts: updateItem<IDiscountDTOIn>((d: IDiscountDTOIn) => d.id === action.payload.id, discount),
                    })
                );
            })
        );
    }

    @Action(EnableDiscount)
    public enableDiscount(ctx: StateContext<DiscountsStateModel>, action: EnableDiscount): Observable<IDiscountDTOIn> {
        return this.discountsService.enableDiscount(action.id).pipe(
            tap((discount: IDiscountDTOIn) => {
                ctx.setState(
                    patch({
                        discounts: updateItem<IDiscountDTOIn>((d: IDiscountDTOIn) => d.id === action.id, discount),
                    })
                );
            })
        );
    }

    @Action(SetSortDiscount)
    public setSortDiscount(ctx: StateContext<DiscountsStateModel>, action: SetSortDiscount): void {
        ctx.patchState({
            sort: action.sort,
        });
    }
}
