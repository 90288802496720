import { Component, Input } from '@angular/core';

import { AlertTypeEnum } from '../../../shared/enums/alert.enum';
import { IFeedbackMessage } from './../../../shared/interfaces/feedback-message.interface';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    @Input() public message: IFeedbackMessage;
    @Input() public index: number;

    public types = AlertTypeEnum;
}
