import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SetIsMobile } from '../actions/header.actions';

export interface HeaderStateModel {
    isMobile: boolean;
}
@State<HeaderStateModel>({
    name: 'header',
    defaults: {
        isMobile: false,
    },
})
@Injectable()
export class HeaderState {
    @Selector()
    public static isMobile(state: HeaderStateModel): boolean {
        return state.isMobile;
    }

    @Action(SetIsMobile)
    public setIsMobile(ctx: StateContext<HeaderStateModel>, action: SetIsMobile): void {
        ctx.patchState({
            isMobile: window.innerWidth < 768 || action.isMobile,
        });
    }
}
