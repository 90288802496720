import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { frozenColumns, scrollableColumns } from '../../data/pre-test-columns';
import { PretestSortConditionsEnum } from '../../enums/prestest-filters.enum';
import { IFilter } from '../../interfaces/filter.interface';
import { Tasting } from '../actions/tasting.actions';
import { ICNCode, ICNCodeDict, ICNCodePayload } from './../../interfaces/cn-code.interface';
import { IFieldToValidate } from './../../interfaces/field-to-validate.interface';
import { IPretestData, IPretestDataDTOIn, IPretestQueryOptions, IPretestTableData } from './../../interfaces/pretest.interface';
import { PretestService } from './../../services/pretest.service';
import { IAwardItem, awardList } from './../../utils/award.utils';
import { IClaimsItem, claimsList } from './../../utils/claims.utils';
import { ISpicinessItem, spicinessList } from './../../utils/spiciness.utils';
import { IStorageConditionItem, storageConditionList } from './../../utils/storage-conditions.utils';
import { rowPerPageOptions } from './../../utils/table.utils';
import { Pretest } from './../actions/pretest.actions';
import { Product } from './../actions/product.actions';
import { UpdateUser } from './../actions/profile.actions';
import { SharedTablesSelectors } from './../selectors/shared-tables.selectors';
import { TableStateModel } from './table.state';

export interface PretestStateModel extends TableStateModel<IPretestTableData, IPretestQueryOptions> {
    data: IPretestData[];
    selectedRow: IPretestData;
    specificQuestions: IFieldToValidate[];
    multipleSelectedRows: IPretestData[];
    cnCodes: ICNCode[];
    cnCodesDictionary: ICNCodeDict;
}

const queryOptionsDefaults: IPretestQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    sortCondition: '-' + PretestSortConditionsEnum.CREATED_AT,
    filters: [],
    textFilter: '',
};
@State<PretestStateModel>({
    name: 'pretest',
    defaults: {
        data: [],
        tableData: [],
        scrollableColumns,
        frozenColumns,
        selectedRow: null,
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        specificQuestions: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
        multipleSelectedRows: [],
        cnCodes: [],
        cnCodesDictionary: null,
    },
})
@Injectable()
export class PretestState extends SharedTablesSelectors {
    constructor(private pretestService: PretestService, private store: Store) {
        super();
    }

    @Action(Pretest.GetAll)
    public getAll(ctx: StateContext<PretestStateModel>): Observable<IPretestDataDTOIn> {
        const state: PretestStateModel = ctx.getState();

        return this.pretestService.getPretestData(state.queryOptions).pipe(
            tap((result: IPretestDataDTOIn) => {
                const tableData: IPretestTableData[] = this.formatTableData(result.tastings, ctx.getState().cnCodesDictionary);

                ctx.setState(
                    patch<PretestStateModel>({
                        data: result.tastings,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    })
                );
            })
        );
    }

    @Action(Pretest.SetSelectedRow)
    public setSelectedRow(ctx: StateContext<PretestStateModel>, action: Pretest.SetSelectedRow): void {
        const state: PretestStateModel = ctx.getState();

        const selectedRow: IPretestData = state.data.find((dataItem: IPretestData) => dataItem.id === action.tastingId) ?? null;

        ctx.patchState({ selectedRow });
    }

    @Action(Pretest.UpdateSelectedUser)
    public updateSelectedUser(ctx: StateContext<PretestStateModel>, action: Pretest.UpdateSelectedUser): Observable<void> {
        /* Remove ID since the backend disallows sending it */
        delete action.profile.id;

        return ctx.dispatch(new UpdateUser(action.profile, action.userId));
    }

    @Action(Pretest.UpdateSelectedProduct)
    public updateSelectedProduct(ctx: StateContext<PretestStateModel>, action: Pretest.UpdateSelectedProduct): Observable<void> {
        return ctx.dispatch(new Product.Update(action.product, action.productId));
    }

    @Action(Pretest.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<PretestStateModel>, action: Pretest.SetQueryOptions): void {
        const state: PretestStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IPretestQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<PretestStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(Pretest.GetFilters)
    public getPretestFilters(ctx: StateContext<PretestStateModel>): Observable<IFilter[]> {
        return this.pretestService.getPretestFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.setState(
                    patch<PretestStateModel>({
                        filtersData: result,
                    })
                );
            })
        );
    }

    @Action(Pretest.UpdateColumnPreferences)
    public updateColumnPreferences(ctx: StateContext<PretestStateModel>, action: Pretest.UpdateColumnPreferences): void {
        ctx.setState(
            patch<PretestStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(Pretest.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<PretestStateModel>): void {
        ctx.setState(
            patch<PretestStateModel>({
                scrollableColumns,
            })
        );
    }

    @Action(Pretest.UpdateTasting)
    public updateTasting(ctx: StateContext<PretestStateModel>, action: Pretest.UpdateTasting): Observable<void> {
        return ctx.dispatch(new Tasting.Update(action.tasting, action.tastingId));
    }

    @Action(Pretest.SetMultipleSelectedRows)
    public setMultipleSelectedRows(ctx: StateContext<PretestStateModel>, action: Pretest.SetMultipleSelectedRows): void {
        const state: PretestStateModel = ctx.getState();

        const multipleSelectedRows: IPretestData[] = state.data.filter((pretestData: IPretestData) =>
            action.tastingIds.includes(pretestData.id)
        );

        ctx.setState(
            patch<PretestStateModel>({
                multipleSelectedRows,
            })
        );
    }

    @Action(Pretest.ToggleFilters)
    public setFiltersDisplay(ctx: StateContext<PretestStateModel>, action: Pretest.ToggleFilters): void {
        ctx.setState(
            patch<PretestStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    @Action(Pretest.GetCNCodes)
    public getCNCodes(ctx: StateContext<PretestStateModel>): Observable<ICNCode[]> {
        const state: PretestStateModel = ctx.getState();

        if (state.cnCodes.length) {
            return null;
        }

        return this.pretestService.getCNCodes().pipe(
            tap((cnCodes: ICNCode[]) => {
                ctx.setState(
                    patch<PretestStateModel>({
                        cnCodes,
                        cnCodesDictionary: this.parseCnCodes(cnCodes),
                    })
                );
            })
        );
    }

    private formatTableData(data: IPretestData[], cnDictionary: ICNCodeDict): IPretestTableData[] {
        const tableData: IPretestTableData[] = [];

        data.forEach((item: IPretestData) => {
            const { product } = item;
            const { user } = product;

            const candidateAwardLabel: string = awardList.find((award: IAwardItem) => award.value === item.candidate_award)?.label ?? null;
            const claimLabel: string = claimsList.find((claim: IClaimsItem) => claim.value === product.claims)?.label ?? null;
            const storageConditionLabel: string =
                storageConditionList.find((cond: IStorageConditionItem) => cond.value === product.storage_conditions)?.label ?? null;
            const spicinessLabel: string =
                spicinessList.find((spice: ISpicinessItem) => spice.value === product.spiciness_level)?.label ?? null;

            const planningDetails: string = this.formatPlanningDetails(item);

            const tableDataItem: IPretestTableData = {
                productId: product.id ?? null,
                tastingId: item.id ?? null,
                announcementId: product?.announcement?.id ?? null,
                categoryId: product.category?.id ?? null,
                productName: product.name ?? null,
                alcoholLevel: product.alcohol_level ?? null,
                claims: claimLabel,
                defrosting_time: product.defrosting_time ?? null,
                description: product.description ?? null, // = client_announcement
                spiciness_level: spicinessLabel,
                storage_conditions: storageConditionLabel ?? null,
                clientInstructions: product?.instruction?.client_local ?? null,
                announcementDescription: product?.announcement?.description ?? null,
                announcementDefinition: product?.announcement?.definition ?? null,
                client_spec: item.client_spec ?? null,
                orderdate: item.orderdate ?? null,
                sta_year: item.sta_year ?? null,
                hasFoodPairing: item.options.food_pairing ?? null,
                hasCommentsAndSuggestions: item.options.comments_suggestions ?? null,
                hasQuestions: item.options.question ?? null,
                client_delivery_instructions: item.client_delivery_instructions ?? null,
                samples_delivered: item.samples_delivered ?? null,
                delivery_date: item.delivery_date ?? null,
                feedback_only: item.feedback_only ?? null,
                season: item.season ?? null,
                bc_comments: item.bc_comments ?? null,
                specific_planning: item.specific_planning ?? null,
                comment_about_delivery: item.comment_about_delivery ?? null,
                past_stars: item.past_stars ?? null,
                candidate_award: candidateAwardLabel,
                status: item.status,
                delivery_status: item.delivery_status ?? null,
                categoryName: product.category?.name ?? null,
                companyName: user.company_name ?? null,
                contactOwner: user.contact_owner ?? null,
                new_client: item.new_client ?? null,
                payment_status: user.payment_status ?? null,
                userId: user.id ?? null,
                bc_specs: item.bc_specs ?? null,
                bcInstructions: product?.instruction?.bc_en ?? null,
                non_standard_ingredient: product.non_standard_ingredient ?? null,
                validation_status: item.validation_status,
                planning_id: item?.planning?.id ?? null,
                planning_details: planningDetails,
                iti_class: product.iti_class,
                shelf_life: item.shelf_life,
                cn_code: product.cn_code ?? null,
                cn_status: cnDictionary?.[product.cn_code]?.status ?? null,
                cn_title: cnDictionary?.[product.cn_code]?.title ?? null,
                cn_chapter: cnDictionary?.[product.cn_code]?.chapter ?? null,
                ingredient_list_path: product.ingredient_list_path ?? null,
                transport_conditions: product.transport_conditions ?? null,
                packshot: !!product?.packshot_path,
                logo: !!user?.logo_path,
            };
            tableData.push(tableDataItem);
        });

        return tableData;
    }

    private formatPlanningDetails(pretestData: IPretestData): string {
        let planningDetails: string = null;

        if (
            pretestData.planning &&
            pretestData.planning.day &&
            pretestData.planning.group &&
            pretestData.planning.order !== null &&
            pretestData.planning.order !== undefined // order can be 0, I need a more refined check than just 'is not false'
        ) {
            planningDetails = `D${pretestData.planning.day}-G${pretestData.planning.group}-${pretestData.planning.order + 1}`;
        }

        return planningDetails;
    }

    private parseCnCodes(cnCodes: ICNCode[]): ICNCodeDict {
        const dictionary: ICNCodeDict = {};

        cnCodes.forEach((codeItem: ICNCode) => {
            const { chapter, codes } = codeItem;

            codes.forEach((payload: ICNCodePayload) => {
                dictionary[payload.hsCode] = {
                    chapter,
                    status: payload.status,
                    title: payload.title,
                };
            });
        });

        return dictionary;
    }
}
