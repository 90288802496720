import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

import { BaseComponent } from '../../abstracts/base.abstract';
import { CollapsibleService } from './../../services/internal/collapsible.service';
import { generateUniqueId } from './../../utils/utility-functions';

@Component({
    selector: 'app-collapsible',
    templateUrl: './collapsible.component.html',
    styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    /* This article helped with the transition https://css-tricks.com/using-css-transitions-auto-dimensions/ */
    @ViewChild('collapsible') public collapsibleRef: ElementRef;

    @Input() public expanded: boolean = false;
    @Input() public disabled: boolean = false;

    public uniqueId: string = null;
    public collapsibleContent: HTMLDivElement = null;
    public contentHeight: number;

    constructor(private renderer: Renderer2, private collapsibleService: CollapsibleService) {
        super();
    }

    ngOnInit(): void {
        this.uniqueId = generateUniqueId();
        this.collapsibleService.registerCollapsible(this.uniqueId);

        this.uns = this.collapsibleService.activeCollapsible$.subscribe((activeId: string) => {
            if (activeId && activeId !== this.uniqueId) {
                this.collapseSection();
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.collapsibleRef) {
            this.collapsibleContent = this.collapsibleRef.nativeElement;
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.collapsibleService.unregisterCollapsible(this.uniqueId);
    }

    public triggerCollapse(): void {
        if (this.expanded) {
            this.collapseSection();
        } else {
            this.expandSection();
        }
    }

    public onTransitionEnd(event: TransitionEvent): void {
        if (event.target !== this.collapsibleContent || event.propertyName !== 'height') {
            return;
        }

        /* When the collapsible has transitioned to it's full height,
        set its height back to auto (so it does not stay fixed manually, as in the expandSection method)
        */
        if (this.expanded) {
            this.renderer.setStyle(this.collapsibleContent, 'height', 'auto');
            this.collapsibleContent.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    private collapseSection(): void {
        // get the height of the element's inner content, regardless of its actual size
        this.contentHeight = this.collapsibleContent.scrollHeight;

        // temporarily disable all css transitions
        const elementTransition: string = this.collapsibleContent.style.transition;

        this.renderer.setStyle(this.collapsibleContent, 'transition', '');

        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto' (would not work);
        requestAnimationFrame(() => {
            this.renderer.setStyle(this.collapsibleContent, 'height', this.contentHeight + 'px');
            this.renderer.setStyle(this.collapsibleContent, 'transition', elementTransition);

            // on the next frame (as soon as the previous style change has taken effect),
            // have the element transition to height: 0
            requestAnimationFrame(() => {
                this.renderer.setStyle(this.collapsibleContent, 'height', 0 + 'px');
            });
        });

        this.expanded = false;
    }

    private expandSection(): void {
        if (this.disabled) {
            return;
        }

        this.expanded = true;

        // get the height of the element's inner content, regardless of its actual size
        this.contentHeight = this.collapsibleContent.scrollHeight;

        this.renderer.setStyle(this.collapsibleContent, 'height', this.contentHeight + 'px');

        this.collapsibleService.notifyExpansion(this.uniqueId);
    }
}
