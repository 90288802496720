import { Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormControlComponent } from '../../../abstracts/form-control.abstract';
import { generateUniqueId } from '../../../utils/utility-functions';

/* Checkbox input should receive as a value from its form an object of the form ICheckboxControlValue:
{
    value: any;
    label: string;
    checked: boolean
}

Alternatively, you can pass just a value in the form control, and a label via input
*/
@Component({
    selector: 'app-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CheckboxInputComponent,
            multi: true,
        },
    ],
})
export class CheckboxInputComponent extends FormControlComponent implements OnChanges {
    @Input() public isSwitchInput: boolean = false;
    @Input() public invalid = false;
    @Input() public label = '';
    @Input() public readonly: boolean;

    @ContentChild('labelTemplate', { static: false }) public labelTemplate: TemplateRef<any>;

    public inputId: string;
    public valueIsObject: boolean;

    constructor() {
        super();
        this.inputId = generateUniqueId();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.label && changes.label.currentValue) {
            this.label = changes.label.currentValue;
        }

        if (changes.invalid && changes.invalid.currentValue) {
            this.invalid = changes.invalid.currentValue;
        }
    }

    public onClick(event: Event): void {
        this.markAsTouched();

        if (this.valueIsObject) {
            this.value = {
                ...this.value,
                checked: (event.target as HTMLInputElement).checked,
            };
        } else {
            this.value = (event.target as HTMLInputElement).checked;
        }

        this.onChange(this.value);
    }

    public writeValue(obj: any): void {
        super.writeValue(obj);

        this.valueIsObject = this.checkIfObject(obj);
    }

    private checkIfObject(value: any): boolean {
        return typeof value === 'object' && value !== null && !Array.isArray(value);
    }
}
