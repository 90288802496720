import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { BaseComponent } from '../../abstracts/base.abstract';
import { UtilitiesService } from '../../services/internal/utilities.service';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends BaseComponent implements OnInit {
    @ViewChild('dropdown') public dropdownRef: ElementRef;

    @Input() public title: string;
    public isOpen: boolean;

    constructor(private utilitiesService: UtilitiesService) {
        super();
    }

    ngOnInit(): void {
        this.uns = this.utilitiesService.clickedTarget$.subscribe((element: HTMLElement) => {
            if (this.dropdownRef && !this.dropdownRef.nativeElement.contains(element)) {
                this.isOpen = false;
            }
        });
    }

    public toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }
}
