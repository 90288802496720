import { IGenericListItem } from '../interfaces/generic-list-item.interface';
import { ProductCategoriesEnum } from './../enums/product-categories.enum';

export type ICategoryTypeItem = IGenericListItem<ProductCategoriesEnum>;

export const categoryTypeList: ICategoryTypeItem[] = Object.keys(ProductCategoriesEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: ProductCategoriesEnum[key as keyof typeof ProductCategoriesEnum],
    label: `CATEGORY_TYPE.${key}`,
}));
