export enum SeasonsEnum {
    BXL_AUTUMN = 'BXL_AUTUMN',
    BXL_SPRING = 'BXL_SPRING',
    NO_VALUE = 'NO_VALUE',
    DUBAI_AUTUMN = 'DUBAI_AUTUMN',
    DUBAI_SPRING = 'DUBAI_SPRING',
    DUBAI_PORK_SPRING = 'DUBAI_PORK_SPRING',
    DUBAI_PORK_AUTUMN = 'DUBAI_PORK_AUTUMN',
    NEXT_STA = 'NEXT_STA',
    TO_CANCEL = 'TO_CANCEL',
}
