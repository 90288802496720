import { IAnnouncement, IAnnouncementQueryOptions } from '../../interfaces/announcement.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';

export namespace NewAnnouncementsTable {
    export class GetAll {
        public static readonly type = '[New Announcements Table] get new data';

        constructor(public type: { new: boolean } = { new: false }) {}
    }
    export class SetSelectedRow {
        public static readonly type = '[New Announcements Table] set selected row';

        constructor(public announcementId: number) {}
    }

    export class SetQueryOptions {
        public static readonly type = '[New Announcements Table] Set query options';

        /* resetUnchangedFields: Reset query options that were not passed in the options parameter to state default */
        constructor(
            public options: Partial<IAnnouncementQueryOptions>,
            public flags: { resetUnchangedFields: boolean } = { resetUnchangedFields: false }
        ) {}
    }

    export class GetFilters {
        public static readonly type = '[New Announcements Table] get filters';
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[New Announcements Table] set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[New Announcements Table] reset column prefs';
    }

    export class Update {
        public static readonly type = '[New Announcements Table] update';

        constructor(public announcement: Partial<IAnnouncement>, public id: number) {}
    }

    export class Create {
        public static readonly type = '[New Announcements Table] create';

        constructor(public announcement: Partial<IAnnouncement>) {}
    }

    export class ToggleFilters {
        public static readonly type = '[New Announcements Table] Set new announcement filters display status';

        constructor(public showFilters: boolean) {}
    }
}
