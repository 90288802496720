import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, mergeMap, skipWhile } from 'rxjs/operators';

import { IFeaturePermissions } from '../interfaces/feature-permissions.interface';
import { GetProfile, RefreshToken } from '../store/actions/auth.actions';
import { AuthState } from '../store/state/auth.state';
import { RoleEnum } from './../enums/user-right.enums';
import { PermissionsService } from './../services/internal/permissions.service';

@Injectable()
export class AuthGuard implements CanActivate {
    @Select(AuthState.isAuthenticated) public auth$: Observable<boolean>;
    @Select(AuthState.userRole) public userRole$: Observable<RoleEnum>;

    constructor(
        private store: Store,
        private localize: LocalizeRouterService,
        private router: Router,
        private permissionsService: PermissionsService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const isAuthenticated: boolean = this.store.selectSnapshot(AuthState.isAuthenticated);
        const feature: IFeaturePermissions = route.data?.feature;

        if (!isAuthenticated) {
            return this.store.dispatch(new RefreshToken()).pipe(
                mergeMap(() => this.auth$),
                mergeMap((auth: boolean) => {
                    if (!auth) {
                        return this.goBackToLogin();
                    }

                    this.store.dispatch(new GetProfile());
                    return this.checkIsAuthorized(feature);
                })
            );
        }

        return this.checkIsAuthorized(feature);
    }

    private goBackToLogin(): Observable<UrlTree> {
        return of(this.router.createUrlTree([this.localize.translateRoute('/login')]));
    }

    private checkIsAuthorized(featurePermissions: IFeaturePermissions): Observable<boolean | UrlTree> {
        return this.userRole$.pipe(
            skipWhile((role: RoleEnum) => !role),
            map((role: RoleEnum) => {
                if (!featurePermissions) {
                    return true;
                }

                return (
                    this.permissionsService.checkPermission(role, featurePermissions) ||
                    this.router.createUrlTree([this.localize.translateRoute(this.permissionsService.getHomeURL())])
                );
            })
        );
    }
}
