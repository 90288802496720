import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AnnouncementsExportFieldsEnum } from '../enums/announcement-export.enum';
import { AnnouncementFiltersDTOEnum, AnnouncementFiltersLabelEnum } from '../enums/announcements-filters.enum';
import {
    IAnnouncement,
    IAnnouncementDTOIn,
    IAnnouncementDTOOut,
    IAnnouncementQueryOptions,
    IAnnouncementQueryOptionsDTOOut,
    IAnnouncementsDTOIn,
    IAnnouncementTranslations,
} from '../interfaces/announcement.interface';
import { IExportColumn } from '../interfaces/export-column.interface';
import { IFilter, IFilterToDto } from '../interfaces/filter.interface';
import { categoryTypeList, ICategoryTypeItem } from '../utils/category-type.utils';
import { mapFiltersToDTO } from '../utils/utility-functions';
import { AnnouncementTranslationsFiltersLabelEnum } from './../enums/announcement-translations-filters.enum';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { IAnnouncementLinkingDTOOut, IAnnouncementTranslationsDTOIn } from './../interfaces/announcement.interface';
import { IContactOwner } from './../interfaces/contact-owner.interface';
import { IFilterFromDto } from './../interfaces/filter.interface';

@Injectable({ providedIn: 'root' })
export class AnnouncementsService {
    constructor(private http: HttpClient, private translate: TranslateService) {}

    public getAnnouncements(queryOptions: IAnnouncementQueryOptions): Observable<IAnnouncementsDTOIn> {
        const body: IAnnouncementQueryOptionsDTOOut = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            new: queryOptions.new,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IAnnouncementsDTOIn>(environment.itqiApi + SubUrlsEnum.ANNOUNCEMENTS_INDEX, body, { params });
    }

    public getAnnouncementFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.ANNOUNCEMENTS_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.mapFilters(result)));
    }

    public updateAnnouncement(announcement: Partial<IAnnouncement>, id: number): Observable<IAnnouncement> {
        const announcementToDto: IAnnouncementDTOOut = {
            announcement,
        };

        return this.http
            .patch<IAnnouncementDTOIn>(environment.itqiApi + SubUrlsEnum.ANNOUNCEMENTS + '/' + id.toString(), announcementToDto)
            .pipe(map((result: IAnnouncementDTOIn) => result.announcement));
    }

    public createAnnouncement(announcement: Partial<IAnnouncement>): Observable<IAnnouncement> {
        const announcementToDto: IAnnouncementDTOOut = {
            announcement,
        };

        return this.http
            .post<IAnnouncementDTOIn>(environment.itqiApi + SubUrlsEnum.ANNOUNCEMENTS, announcementToDto)
            .pipe(map((ann: IAnnouncementDTOIn) => ann.announcement));
    }

    public linkAnnouncement(oldAnnouncementId: number, announcementToLinkId: number): Observable<void> {
        const linkObjectToDto: IAnnouncementLinkingDTOOut = {
            new_announcement_id: announcementToLinkId,
        };

        return this.http.put<void>(
            environment.itqiApi + SubUrlsEnum.ANNOUNCEMENT_LINKING.replace('{{id}}', oldAnnouncementId.toString()),
            linkObjectToDto
        );
    }

    /* ANNOUNCEMENT TRANSLATION METHODS  */

    public getAnnouncementTranslations(queryOptions: IAnnouncementQueryOptions): Observable<IAnnouncementTranslationsDTOIn> {
        const body: IAnnouncementQueryOptionsDTOOut = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
            new: queryOptions.new,
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IAnnouncementTranslationsDTOIn>(environment.itqiApi + SubUrlsEnum.ANNOUNCEMENT_TRANSLATIONS, body, {
            params,
        });
    }

    public getAnnouncementTranslationsFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.ANNOUNCEMENT_TRANSLATIONS_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.mapAnnouncementTranslationsFilters(result)));
    }

    public updateAnnouncementTranslations(translationObject: Partial<IAnnouncementTranslations>, id: number): Observable<unknown> {
        return this.http.put<unknown>(environment.itqiApi + SubUrlsEnum.ANNOUNCEMENT_TRANSLATIONS + `/${id}`, {
            announcement: translationObject,
        });
    }

    public export(queryOptions: IAnnouncementQueryOptions): Observable<string> {
        const activeFilters: IFilterToDto[] = mapFiltersToDTO(queryOptions.filters);
        const activeSort: string = queryOptions.sortCondition;
        const textFilter: string = queryOptions.textFilter;

        const formattedColumns: IExportColumn[] = this.getExportColumns();

        return this.http.post(
            environment.itqiApi + SubUrlsEnum.ANNOUNCEMENT_EXPORT,
            {
                columns: formattedColumns,
                sort: activeSort,
                filters: activeFilters,
                q: textFilter,
            },
            { responseType: 'text' }
        );
    }

    /* PRIVATE METHODS */
    private mapFilters(filterObj: IFilterFromDto): IFilter[] {
        const filterArray: IFilter[] = [];

        Object.values(AnnouncementFiltersLabelEnum).forEach((label: string) => {
            switch (label) {
                case AnnouncementFiltersLabelEnum.FOOD_DRINK:
                    filterArray.push({
                        type: AnnouncementFiltersDTOEnum.CAT + '##' + AnnouncementFiltersLabelEnum.FOOD_DRINK,
                        label: AnnouncementFiltersLabelEnum.FOOD_DRINK,
                        options: null,
                    });
                    break;
                case AnnouncementFiltersLabelEnum.CAT:
                    filterArray.push({
                        type: AnnouncementFiltersDTOEnum.CAT,
                        label: AnnouncementFiltersLabelEnum.CAT,
                        options: null,
                    });
                    break;
                /* Label says Announcement Creator but backend filter is on Contact Owner */
                case AnnouncementFiltersLabelEnum.ANN_CREATOR:
                    filterArray.push({
                        type: AnnouncementFiltersDTOEnum.CONTACT_OWNER,
                        label: AnnouncementFiltersLabelEnum.ANN_CREATOR,
                        options: filterObj[AnnouncementFiltersDTOEnum.CONTACT_OWNER].values.map((cOwn: IContactOwner) => ({
                            value: cOwn.id,
                            label: cOwn.name,
                        })),
                    });
            }
        });
        return filterArray;
    }

    private mapAnnouncementTranslationsFilters(filterObj: IFilterFromDto): IFilter[] {
        const filterArray: IFilter[] = [];

        Object.keys(filterObj).forEach((key: string) => {
            switch (key) {
                case AnnouncementTranslationsFiltersLabelEnum.CRITERIA_TYPE.toLowerCase():
                    filterArray.push({
                        type: key,
                        label: 'TRANSLATIONS.' + key.toUpperCase(),
                        options: categoryTypeList.map((item: ICategoryTypeItem) => ({
                            value: item.value.toLowerCase(),
                            label: item.label,
                        })),
                    });
                    break;
                default:
                    filterArray.push({
                        type: key,
                        label: 'TRANSLATIONS.' + key.toUpperCase(),
                        options: filterObj[key].values.map((value: any) => ({
                            value,
                            label: value ? 'MY_WORKSPACE.FILTERS.OK' : 'MY_WORKSPACE.FILTERS.NOT_OK',
                        })),
                    });
            }
        });
        return filterArray;
    }

    private getExportColumns(): IExportColumn[] {
        const prefix: string = 'TRANSLATIONS_MODULE.ANNOUNCEMENT.EXPORT.';

        return [
            {
                field: AnnouncementsExportFieldsEnum.ANNOUNCEMENT_ID,
                label: this.translate.instant(prefix + 'ID'),
            },
            {
                field: AnnouncementsExportFieldsEnum.CATEGORY_NAME,
                label: this.translate.instant(prefix + 'CAT'),
            },
            {
                field: AnnouncementsExportFieldsEnum.FOOD_DRINK,
                label: this.translate.instant(prefix + 'FOOD_DRINK'),
            },
            {
                field: AnnouncementsExportFieldsEnum.DESCRIPTION_EN,
                label: this.translate.instant(prefix + 'DESC'),
            },
            {
                field: AnnouncementsExportFieldsEnum.DEFINITION_EN,
                label: this.translate.instant(prefix + 'DEF'),
            },
            {
                field: AnnouncementsExportFieldsEnum.FOODEX_OK,
                label: this.translate.instant(prefix + 'FOODEX_OK'),
            },
            {
                field: AnnouncementsExportFieldsEnum.ANNOUNCEMENT_FR,
                label: this.translate.instant(prefix + 'DESC_FR'),
            },
            {
                field: AnnouncementsExportFieldsEnum.DEFINITION_FR,
                label: this.translate.instant(prefix + 'DEF_FR'),
            },
            {
                field: AnnouncementsExportFieldsEnum.ANNOUNCEMENT_FR_VALIDATED,
                label: this.translate.instant(prefix + 'VALIDATION_FR'),
            },
            {
                field: AnnouncementsExportFieldsEnum.ANNOUNCEMENT_IT,
                label: this.translate.instant(prefix + 'DESC_IT'),
            },
            {
                field: AnnouncementsExportFieldsEnum.DEFINITION_IT,
                label: this.translate.instant(prefix + 'DEF_IT'),
            },
            {
                field: AnnouncementsExportFieldsEnum.ANNOUNCEMENT_IT_VALIDATED,
                label: this.translate.instant(prefix + 'VALIDATION_IT'),
            },
            {
                field: AnnouncementsExportFieldsEnum.ANNOUNCEMENT_ES,
                label: this.translate.instant(prefix + 'DESC_ES'),
            },
            {
                field: AnnouncementsExportFieldsEnum.DEFINITION_ES,
                label: this.translate.instant(prefix + 'DEF_ES'),
            },
            {
                field: AnnouncementsExportFieldsEnum.ANNOUNCEMENT_ES_VALIDATED,
                label: this.translate.instant(prefix + 'VALIDATION_ES'),
            },
        ];
    }
}
