import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    frozenAnnouncementTranslationsColumns,
    scrollableAnnouncementTranslationsColumns,
} from '../../data/announcement-translations-columns';
import { AnnouncementsSortConditionsEnum } from '../../enums/announcements-filters.enum';
import { IAnnouncementQueryOptions } from '../../interfaces/announcement.interface';
import { ICategory } from '../../interfaces/category.interface';
import { IFilter } from '../../interfaces/filter.interface';
import { AnnouncementsService } from '../../services/announcements.service';
import { criteriaTypeList, ICriteriaTypeItem } from '../../utils/criteria-type.utils';
import { rowPerPageOptions } from '../../utils/table.utils';
import { AnnouncementTranslations } from '../actions/announcement-translations.actions';
import { SharedTablesSelectors } from '../selectors/shared-tables.selectors';
import { AnnouncementTranslationsFiltersLabelEnum } from './../../enums/announcement-translations-filters.enum';
import { CriteriaTypeEnum } from './../../enums/criteria-type.enum';
import { TranslationLanguagesEnum } from './../../enums/translation-languages.enum';
import {
    IAnnouncementTranslations,
    IAnnouncementTranslationsDTOIn,
    IAnnouncementTranslationsTableData,
} from './../../interfaces/announcement.interface';
import { TranslationsService } from './../../services/translations.service';
import { CategoryState } from './category.state';
import { TableStateModel } from './table.state';

export interface AnnouncementTranslationsStateModel extends TableStateModel<IAnnouncementTranslationsTableData, IAnnouncementQueryOptions> {
    announcementTranslations: IAnnouncementTranslations[];
    selectedRow: IAnnouncementTranslations;
    modalContext: TranslationLanguagesEnum;
}

const queryOptionsDefaults: IAnnouncementQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    new: false,
    sortCondition: '-' + AnnouncementsSortConditionsEnum.ANNOUNCEMENT_ID,
    filters: [
        {
            type: 'validation_all',
            label: 'TRANSLATIONS.' + AnnouncementTranslationsFiltersLabelEnum.VALIDATION_ALL,
            options: [{ value: false, label: 'MY_WORKSPACE.FILTERS.NOT_OK' }],
        },
    ],
    textFilter: '',
};

@State<AnnouncementTranslationsStateModel>({
    name: 'announcementTranslations',
    defaults: {
        announcementTranslations: [],
        tableData: [],
        scrollableColumns: [...scrollableAnnouncementTranslationsColumns],
        frozenColumns: [...frozenAnnouncementTranslationsColumns],
        selectedRow: null,
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        modalContext: null,
        filtersData: [],
    },
})
@Injectable()
export class AnnouncementTranslationsState extends SharedTablesSelectors {
    constructor(
        private announcementsService: AnnouncementsService,
        private store: Store,
        private translationsService: TranslationsService
    ) {
        super();
    }

    @Action(AnnouncementTranslations.GetAll)
    public getAll(
        ctx: StateContext<AnnouncementTranslationsStateModel>,
        action: AnnouncementTranslations.GetAll
    ): Observable<IAnnouncementTranslationsDTOIn> {
        ctx.setState(
            patch<AnnouncementTranslationsStateModel>({
                queryOptions: patch<IAnnouncementQueryOptions>({
                    new: action.type.new,
                }),
            })
        );

        const state: AnnouncementTranslationsStateModel = ctx.getState();

        return this.announcementsService.getAnnouncementTranslations(state.queryOptions).pipe(
            tap((result: IAnnouncementTranslationsDTOIn) => {
                const tableData: IAnnouncementTranslationsTableData[] = this.formatTableData(result.announcements);

                ctx.setState(
                    patch<AnnouncementTranslationsStateModel>({
                        announcementTranslations: result.announcements,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    })
                );
            })
        );
    }

    @Action(AnnouncementTranslations.SetSelectedRow)
    public setSelectedRow(ctx: StateContext<AnnouncementTranslationsStateModel>, action: AnnouncementTranslations.SetSelectedRow): void {
        const state: AnnouncementTranslationsStateModel = ctx.getState();

        const selectedRow: IAnnouncementTranslations =
            state.announcementTranslations.find((dataItem: IAnnouncementTranslations) => dataItem.id === action.announcementId) ?? null;

        ctx.patchState({ selectedRow });
    }

    @Action(AnnouncementTranslations.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<AnnouncementTranslationsStateModel>, action: AnnouncementTranslations.SetQueryOptions): void {
        const state: AnnouncementTranslationsStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IAnnouncementQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<AnnouncementTranslationsStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(AnnouncementTranslations.GetFilters)
    public getFilters(ctx: StateContext<AnnouncementTranslationsStateModel>): Observable<IFilter[]> {
        return this.announcementsService.getAnnouncementTranslationsFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.setState(
                    patch<AnnouncementTranslationsStateModel>({
                        filtersData: result,
                    })
                );
            })
        );
    }

    @Action(AnnouncementTranslations.UpdateColumnPreferences)
    public updateColumnPreferences(
        ctx: StateContext<AnnouncementTranslationsStateModel>,
        action: AnnouncementTranslations.UpdateColumnPreferences
    ): void {
        ctx.setState(
            patch<AnnouncementTranslationsStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(AnnouncementTranslations.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<AnnouncementTranslationsStateModel>): void {
        ctx.setState(
            patch<AnnouncementTranslationsStateModel>({
                scrollableColumns: scrollableAnnouncementTranslationsColumns,
            })
        );
    }

    @Action(AnnouncementTranslations.ToggleFilters)
    public toggleFilters(ctx: StateContext<AnnouncementTranslationsStateModel>, action: AnnouncementTranslations.ToggleFilters): void {
        ctx.setState(
            patch<AnnouncementTranslationsStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    @Action(AnnouncementTranslations.SetModalContext)
    public setModalContext(ctx: StateContext<AnnouncementTranslationsStateModel>, action: AnnouncementTranslations.SetModalContext): void {
        ctx.patchState({
            modalContext: action.context,
        });
    }

    @Action(AnnouncementTranslations.Update)
    public update(ctx: StateContext<AnnouncementTranslationsStateModel>, action: AnnouncementTranslations.Update): Observable<unknown> {
        return this.announcementsService.updateAnnouncementTranslations(action.announcement, action.id);
    }

    @Action(AnnouncementTranslations.SetAutomaticTranslation)
    public setAutomaticTranslation(
        ctx: StateContext<AnnouncementTranslationsStateModel>,
        action: AnnouncementTranslations.SetAutomaticTranslation
    ): void {
        const { announcementTranslationObj } = action;

        /* Call dispatch only if there is english content and the context language has no content*/
        if (
            announcementTranslationObj.definition &&
            !announcementTranslationObj?.[`definition_${action.language}` as keyof IAnnouncementTranslations]
        ) {
            ctx.dispatch(new AnnouncementTranslations.CallGoogleTradService('definition', announcementTranslationObj, action.language));
        }

        if (
            announcementTranslationObj.description &&
            !announcementTranslationObj?.[`description_${action.language}` as keyof IAnnouncementTranslations]
        ) {
            ctx.dispatch(new AnnouncementTranslations.CallGoogleTradService('description', announcementTranslationObj, action.language));
        }
    }

    @Action(AnnouncementTranslations.CallGoogleTradService)
    public callGoogleTradService(
        ctx: StateContext<AnnouncementTranslationsStateModel>,
        action: AnnouncementTranslations.CallGoogleTradService
    ): Observable<string> {
        const { translationObj, languageContext, fieldToUpdate } = action;

        return this.translationsService
            .getTranslation({
                source: 'en',
                target: languageContext,
                text: translationObj[fieldToUpdate] as string,
            })
            .pipe(
                tap((result: string) => {
                    ctx.setState(
                        patch<AnnouncementTranslationsStateModel>({
                            selectedRow: patch<IAnnouncementTranslations>({
                                [`${fieldToUpdate}_${languageContext}`]: result,
                            }),
                        })
                    );
                })
            );
    }

    private formatTableData(data: IAnnouncementTranslations[]): IAnnouncementTranslationsTableData[] {
        const categories: ICategory[] = this.store.selectSnapshot(CategoryState.childCategories);

        return data.map((item: IAnnouncementTranslations) => {
            const criteriaType: CriteriaTypeEnum = categories.find((cat: ICategory) => cat.id === item.category_id)?.criteria_type ?? null;

            const criteriaTypeLabel: string = criteriaTypeList.find((type: ICriteriaTypeItem) => type.value === criteriaType)?.label ?? '';

            return {
                announcementId: item.id,
                criteriaType: criteriaTypeLabel,
                categoryId: item.category_id,
                categoryName: item.category_name,
                announcementDescription: item.description,
                announcementCreator: item.announcement_creator,
                frenchTranslationStatus: item.validation_fr,
                italianTranslationStatus: item.validation_it,
                spanishTranslationStatus: item.validation_es,
                globalTranslationStatus: item.validation_fr && item.validation_it && item.validation_es,
            };
        });
    }
}
