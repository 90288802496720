import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LoaderState } from './../../../shared/store/state/loader.state';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
    @Select(LoaderState.isLoading) public isLoading$: Observable<boolean>;
    @Select(LoaderState.isLongLoader) public isLongLoader$: Observable<boolean>;
}
