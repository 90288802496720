export enum PrestestExportFieldsEnum {
    TASTING_ID = 'tasting.id',
    USER_ID = 'user.id',
    PRODUCT_ID = 'product.id',
    COMPANY_NAME = 'user.company_name',
    PRODUCT_NAME = 'product.name',
    STA_YEAR = 'tasting.sta_year',
    CONTACT_OWNER = 'user.contact_owner',
    STATUS = 'tasting.status',
    ORDERDATE = 'tasting.orderdate',
    SEASON = 'tasting.season',
    INGREDIENTS_LIST = 'product.ingredient_list',
    BC_COMMENTS = 'tasting.bc_comments',
    FOOD_DRINK = 'tasting.food_drink',
    CATEGORY_ID = 'tasting.category_id',
    CATEGORY_NAME = 'product.category_name',
    COMMENTS_SUGGESTIONS = 'tasting.options.comments_suggestions',
    FOOD_PAIRING = 'tasting.options.food_pairing',
    JURY_QUESTION = 'tasting.options.question',
    FEEDBACK_ONLY = 'tasting.feedback_only',
    OPTION_COUNT = 'tasting.option_count',
    NEW_CLIENT = 'tasting.new_client',
    PAST_STARS = 'tasting.past_stars',
    CANDIDATE_AWARD = 'tasting.candidate_award',
    PAYMENT_STATUS = 'user.payment_status',
    CLIENT_DELIVERY_INSTRUCTIONS = 'tasting.client_delivery_instructions',
    SAMPLE_DELIVERY_INFO = 'tasting.delivery_status',
    DELIVERY_DATE = 'tasting.delivery_date',
    DELIVERY_STATUS = 'tasting.samples_delivered',
    COMMENT_ABOUT_DELIVERY = 'tasting.comment_about_delivery',
    SPECIFIC_PLANNING = 'tasting.specific_planning',
    PLANNING_ID = 'planning_item.planning_id',
    PLANNING_DETAILS = 'planning_item.details',
    TEST_DATE = 'score.test_date',
    CLIENT_SPECS = 'tasting.client_spec',
    BC_SPECS = 'tasting.bc_specs',
    ANNOUNCEMENT_ID = 'product.announcement.id',
    ANNOUNCEMENT_DESCRIPTION = 'product.announcement.description',
    CLIENT_INSTRUCTION = 'product.instruction.client_local',
    BC_INSTRUCTION = 'product.instruction.bc_en',
    NON_STANDARD_INGREDIENT = 'tasting.non_standard_ingredient',
    CLAIMS = 'product.claims',
    STORAGE_CONDITIONS = 'product.storage_conditions',
    DEFROSTING_TIME = 'product.defrosting_time',
    ALCOHOL_LEVEL = 'product.alcohol_level',
    SPICINESS = 'product.spiciness_level',
    COUNTRY = 'tasting.country',
    COMPANY_SIZE = 'user.company_size',
    ITI_CLASS = 'product.iti_class',
    SHELF_LIFE = 'product.shelf_life',
    TRANSPORT_CONDITIONS = 'product.transport_conditions',
    CN_CODE = 'product.cn_code',
}
