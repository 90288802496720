import { ClientDeliveryInstructionsEnum } from './../enums/client-delivery-instructions.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IClientDeliveryInstructionsItem = IGenericListItem<ClientDeliveryInstructionsEnum>;

export const clientDeliveryInstructionsList: IClientDeliveryInstructionsItem[] = Object.keys(ClientDeliveryInstructionsEnum).map(
    (key: string, i: number) => ({
        id: i + 1,
        value:
            ClientDeliveryInstructionsEnum[key as keyof typeof ClientDeliveryInstructionsEnum] !== ClientDeliveryInstructionsEnum.NO_VALUE
                ? ClientDeliveryInstructionsEnum[key as keyof typeof ClientDeliveryInstructionsEnum]
                : null,
        label: `CLIENT_DELIVERY_INSTRUCTIONS.${key}`,
    })
);
