import { IAnnouncementQueryOptions } from '../../interfaces/announcement.interface';

export namespace Announcements {
    export class GetAll {
        public static readonly type = '[Announcements] get announcements';

        constructor(public type: { new: boolean } = { new: false }) {}
    }

    export class SetSelectedRow {
        public static readonly type = '[Announcements] set selected row';

        constructor(public announcementId: number) {}
    }

    export class SetQueryOptions {
        public static readonly type = '[Announcements] Set query options';

        /* resetUnchangedFields: Reset query options that were not passed in the options parameter to state default */
        constructor(
            public options: Partial<IAnnouncementQueryOptions>,
            public flags: { resetUnchangedFields: boolean } = { resetUnchangedFields: false }
        ) {}
    }

    export class Link {
        public static readonly type = '[Announcements] Link announcements';

        constructor(public announcementToReplaceId: number, public announcementToLinkId: number) {}
    }
}
