import { IResultValidationCalibration, IResultValidationsQueryOptions } from '../../interfaces/results-validation.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';

export namespace ResultValidations {
    export class FetchAll {
        public static readonly type = '[resultsValidation] Get results';
    }

    export class GetFilters {
        public static readonly type = '[resultsValidation] Get filters';
    }

    export class GetStats {
        public static readonly type = '[resultsValidation] Get Stats';
    }

    export class ResetStats {
        public static readonly type = '[resultsValidation] Reset Stats';
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[resultsValidation] Set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[resultsValidation] reset column prefs';
    }

    export class SetQueryOptions {
        public static readonly type = '[resultsValidation] Set query options';

        constructor(
            public options: Partial<IResultValidationsQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class ToggleFilters {
        public static readonly type = '[resultsValidation] Set results filters display status';
        constructor(public showFilters: boolean) {}
    }

    export class GetStatistics {
        public static readonly type = '[resultsValidation] get stats';
    }

    export class SetMultipleSelectedRows {
        public static readonly type = '[resultsValidation] set multiple rows as selected';

        constructor(public tastingIds: number[]) {}
    }

    export class Validate {
        public static readonly type = '[resultsValidation] validate tastings';
        constructor(public tastingIds: number[]) {}
    }

    export class Invalidate {
        public static readonly type = '[resultsValidation] invalidate tastings';
        constructor(public tastingIds: number[]) {}
    }

    export class Calibrate {
        public static readonly type = '[resultsValidation] calibrate';
        constructor(public calibration: IResultValidationCalibration) {}
    }

    export class GetScoresAndComments {
        public static readonly type = '[resultsValidation] score and comments';
        constructor(public tastingId: number) {}
    }

    export class UpdateFinalScore {
        public static readonly type = '[resultsValidation] update final score';
        constructor(public tastingId: number, public newScore: number) {}
    }
}
