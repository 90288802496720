import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { IGoogleTranslationDTOIn } from '../interfaces/google-translations.interface';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { IGoogleTranslationDTOOut } from './../interfaces/google-translations.interface';

@Injectable({
    providedIn: 'root',
})
export class TranslationsService {
    constructor(private http: HttpClient) {}

    public getTranslation(payload: IGoogleTranslationDTOOut): Observable<string> {
        return this.http
            .post<IGoogleTranslationDTOIn>(environment.itqiApi + SubUrlsEnum.TRANSLATIONS, payload)
            .pipe(map((result: IGoogleTranslationDTOIn) => result.translation));
    }
}
