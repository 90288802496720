export enum AnnouncementsExportFieldsEnum {
    ANNOUNCEMENT_ID = 'announcement.id',
    CATEGORY_NAME = 'category.name',
    FOOD_DRINK = 'category.criteria_type',
    DESCRIPTION_EN = 'announcement.description_en',
    DEFINITION_EN = 'announcement.definition_en',
    FOODEX_OK = 'announcement.foodex_ok',
    ANNOUNCEMENT_FR = 'announcement.description_fr',
    DEFINITION_FR = 'announcement.definition_fr',
    ANNOUNCEMENT_FR_VALIDATED = 'announcement.validation_fr',
    ANNOUNCEMENT_IT = 'announcement.description_it',
    DEFINITION_IT = 'announcement.definition_it',
    ANNOUNCEMENT_IT_VALIDATED = 'announcement.validation_it',
    ANNOUNCEMENT_ES = 'announcement.description_es',
    DEFINITION_ES = 'announcement.definition_es',
    ANNOUNCEMENT_ES_VALIDATED = 'announcement.validation_es',
}
