import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { BaseComponent } from '../abstracts/base.abstract';
import { IFeaturePermissions } from '../interfaces/feature-permissions.interface';
import { PermissionsService } from '../services/internal/permissions.service';
import { AuthState } from '../store/state/auth.state';
import { RoleEnum } from './../enums/user-right.enums';

@Directive({
    selector: '[appCheckPermissions]',
})
export class CheckPermissionsDirective extends BaseComponent implements OnInit {
    @Select(AuthState.userRole) public userRole$: Observable<RoleEnum>;
    @Input() public appCheckPermissions: IFeaturePermissions;

    constructor(
        private permissionService: PermissionsService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.uns = this.userRole$
            .pipe(
                skipWhile(() => !this.appCheckPermissions),
                tap((role: RoleEnum) => {
                    if (role && (!this.appCheckPermissions || this.permissionService.checkPermission(role, this.appCheckPermissions))) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    } else {
                        this.viewContainer.clear();
                    }
                    this.permissionService.notifyPermissionsChanged();
                })
            )
            .subscribe();
    }
}
