export enum AnnouncementTranslationsFiltersLabelEnum {
    VALIDATION_ALL = 'VALIDATION_ALL',
    VALIDATION_FR = 'VALIDATION_FR',
    VALIDATION_IT = 'VALIDATION_IT',
    VALIDATION_ES = 'VALIDATION_ES',
    CRITERIA_TYPE = 'CRITERIA_TYPE'
}

export enum AnnouncementTranslationsSortConditionsEnum {
    ANNOUNCEMENT_ID = 'announcement.id',
    VALIDATION_STATUS = 'announcement.validation_status',
    CATEGORY_ID = 'announcement.category_id',
    DESCRIPTION = 'announcement.description',
    CATEGORY_NAME = 'category.name',
    CRITERIA_TYPE = 'category.criteria_type',
    CONTACT_OWNER = 'announcement.contact_owner',
    VALIDATION_FR = 'announcement.validation_fr',
    VALIDATION_IT = 'announcement.validation_it',
    VALIDATION_ES = 'announcement.validation_es',
}
