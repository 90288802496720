import { ProductClaimsEnum } from './../enums/product-claims.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IClaimsItem = IGenericListItem<ProductClaimsEnum>;

const itiClaimsItemCount: number = Object.keys(ProductClaimsEnum).length;

export const claimsList: IClaimsItem[] = Object.keys(ProductClaimsEnum)
    .map((key: string, i: number) => ({
        id: i + 1,
        value: ProductClaimsEnum[key as keyof typeof ProductClaimsEnum],
        label: `CLAIMS.${key}`,
    }))
    .concat([{ id: itiClaimsItemCount + 1, value: null, label: 'UTIL.NO_VALUE' }]);
