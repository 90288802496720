import { IGenericListItem } from '../interfaces/generic-list-item.interface';
import { TastingOptionsEnum } from './../enums/tasting-options.enum';

export type ITastingOptionsItem = IGenericListItem<TastingOptionsEnum>;

export const tastingOptionsList: ITastingOptionsItem[] = Object.keys(TastingOptionsEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: TastingOptionsEnum[key as keyof typeof TastingOptionsEnum],
    label: `TASTING_OPTIONS.${key}`,
}));
