import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { FormControlComponent } from '../../../abstracts/form-control.abstract';
import { BasicInputTypeEnum } from '../../../enums/basic-input-type.enum';
import { IBasicInputConfig } from '../../../interfaces/basic-input-config.interface';
import { generateUniqueId } from '../../../utils/utility-functions';

@Component({
    selector: 'app-basic-input',
    templateUrl: './basic-input.component.html',
    styleUrls: ['./basic-input.component.scss'],
})
export class BasicInputComponent extends FormControlComponent implements OnInit {
    @Input() public config: IBasicInputConfig;

    public placeholder: string;
    public inputId: string;
    public type: string;
    public readonly: boolean;

    public basicControl = new FormControl();

    /* ENUM */
    public basicInputTypeEnum = BasicInputTypeEnum;

    constructor(@Self() @Optional() public control: NgControl) {
        super();
        this.control.valueAccessor = this;
    }

    ngOnInit(): void {
        this.inputId = this.config.inputId || generateUniqueId();
        this.placeholder = this.config.placeholder || '';
        this.type = this.config.type;
        this.readonly = this.config.readonly || false;

        this.uns = this.basicControl.valueChanges
            .pipe(
                tap((value: any) => {
                    if (this.config.type === this.basicInputTypeEnum.NUMBER) {
                        value = value === null || value === undefined || value === '' ? null : parseFloat(value);
                    }

                    this.onChange(value);
                })
            )
            .subscribe();
    }

    public writeValue(obj: any): void {
        this.basicControl.patchValue(obj, { emitEvent: false });
    }

    public setDisabledState(disabled: boolean): void {
        super.setDisabledState(disabled);

        if (this.disabled) {
            this.basicControl.disable();
        } else {
            this.basicControl.enable();
        }
    }
}
