import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertTypeEnum } from '../enums/alert.enum';
import { HeaderErrorEnum } from '../enums/header-errors.enum';
import { FeedbackMessages } from '../store/actions/feedback-messages.actions';
import { BackendErrorEnum } from './../enums/backend-errors.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: any) => {
                /* We want to deal with the error ourself in the state or in the component itself */
                if (request.headers.get(HeaderErrorEnum.OWN_ERROR) === 'true') {
                    return throwError(error);
                }

                /* Business logic error from back, Bruno sends a message with it */
                if (error.status === 409) {
                    const errorMessage: string = error.error.error.code
                        ? 'ERRORS.BACKEND.' + (error.error.error.code as string).toUpperCase()
                        : 'ERRORS.GENERIC';
                    this.store.dispatch(new FeedbackMessages.Set({ text: errorMessage, type: AlertTypeEnum.ERROR }));
                }

                /* Should return no error, authguard will do its job, TODO test with Bruno and lower ttl for token (hard to test for) */
                if (error.status === 422 && error.error.error.code === BackendErrorEnum.TOKEN_EXPIRED) {
                    return of(error);
                }

                if (error.status === 400 || error.status === 500) {
                    this.store.dispatch(
                        new FeedbackMessages.Set({
                            text: 'ERRORS.GENERIC',
                            type: AlertTypeEnum.ERROR,
                        })
                    );

                    return of(error);
                }

                return throwError(error);
            })
        );
    }
}
