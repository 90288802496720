import { SpecificQuestionKeyEnum } from '../../enums/specific-question-key.enum';
import { IPretestQueryOptions } from '../../interfaces/pretest.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { TranslationLanguagesEnum } from './../../enums/translation-languages.enum';
import { IPretestTranslations } from './../../interfaces/pretest.interface';

export namespace PretestTranslations {
    export class GetData {
        public static readonly type = '[Pretest Translation] get data';
    }

    export class SetSelectedRow {
        public static readonly type = '[Pretest Translation] set selected row';

        constructor(public tastingId: number) {}
    }

    export class SetQueryOptions {
        public static readonly type = '[Pretest Translation] Set query options';

        constructor(
            public options: Partial<IPretestQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class GetFilters {
        public static readonly type = '[Pretest Translation] get filters';
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[Pretest Translation] set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[Pretest Translation] reset column prefs';
    }

    export class ToggleFilters {
        public static readonly type = '[Pretest Translation] toggle filters';

        constructor(public showFilters: boolean) {}
    }

    export class SetModalContext {
        public static readonly type = '[Pretest Translation] set modal context';

        constructor(public context: TranslationLanguagesEnum) {}
    }

    export class Update {
        public static readonly type = '[Pretest Translation] update translations';

        constructor(public tastingToUpdate: Partial<IPretestTranslations>, public id: number) {}
    }

    export class SetAutomaticTranslation {
        public static readonly type = '[Pretest Translation] set automatic translations';

        constructor(public tastingTranslation: IPretestTranslations, public language: TranslationLanguagesEnum) {}
    }

    export class CallGoogleTradService {
        public static readonly type = '[Pretest Translation] call google trad service';

        constructor(
            public questionToUpdate: SpecificQuestionKeyEnum,
            public translationObj: IPretestTranslations,
            public languageContext: TranslationLanguagesEnum
        ) {}
    }
}
