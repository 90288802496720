import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { reportAssignationColumns, reportAssignationFrozenColumns } from '../../data/report-assignation-columns';
import { IFilter } from '../../interfaces/filter.interface';
import {
    IReportAssignation,
    IReportAssignationDTO,
    IReportAssignationQueryOptions,
    IReportAssignationStats,
    IReportAssignationTableData,
} from '../../interfaces/report-assignation.interface';
import { ReportAssignationToolService } from '../../services/report-assignation-tool.service';
import { rowPerPageOptions } from '../../utils/table.utils';
import { ReportAssignation } from '../actions/report-assignation.actions';
import { SharedTablesSelectors } from '../selectors/shared-tables.selectors';
import { ReportAssignationSortConditionEnum } from './../../../views/my-workspace/enums/report-assignation-filters.enum';
import { TableStateModel } from './table.state';

export interface ReportAssignationStateModel extends TableStateModel<IReportAssignationTableData, IReportAssignationQueryOptions> {
    data: IReportAssignationTableData[];
    stats: IReportAssignationStats;
    multipleSelectedRows: IReportAssignationTableData[];
    selectedRow: IReportAssignationTableData;
    currentReport: IReportAssignation;
}

const queryOptionsDefaults: IReportAssignationQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    sortCondition: '-' + ReportAssignationSortConditionEnum.CREATED_AT,
    filters: [],
    textFilter: '',
};
@State<ReportAssignationStateModel>({
    name: 'reportAssignation',
    defaults: {
        data: [],
        tableData: [],
        stats: null,
        scrollableColumns: [...reportAssignationColumns],
        frozenColumns: [...reportAssignationFrozenColumns],
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
        multipleSelectedRows: [],
        selectedRow: null,
        currentReport: null,
    },
})
@Injectable()
export class ReportAssignationState extends SharedTablesSelectors {
    constructor(private service: ReportAssignationToolService) {
        super();
    }

    @Action(ReportAssignation.GetFilters)
    public getReportAssignationFilters(ctx: StateContext<ReportAssignationStateModel>): any {
        return this.service.getReportAssignationFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.patchState({
                    filtersData: result,
                });
            })
        );
    }

    @Action(ReportAssignation.FetchAll)
    public getAllReportAssignation(ctx: StateContext<ReportAssignationStateModel>): Observable<IReportAssignationDTO> {
        const state: ReportAssignationStateModel = ctx.getState();
        return this.service.getAllReportAssignations(state.queryOptions).pipe(
            tap((result: IReportAssignationDTO) => {
                if (result) {
                    const tableData: IReportAssignationTableData[] = this.formatTableData(result.report_managements);
                    ctx.patchState({
                        data: tableData,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    });
                }
            })
        );
    }

    @Action(ReportAssignation.GetOne)
    public getReportAssignation(
        ctx: StateContext<ReportAssignationStateModel>,
        action: ReportAssignation.GetOne
    ): Observable<IReportAssignation> {
        return this.service.getReportAssignation(action.reportId).pipe(
            tap((result: IReportAssignation) => {
                if (result) {
                    ctx.patchState({
                        currentReport: result,
                    });
                }
            })
        );
    }

    @Action(ReportAssignation.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<ReportAssignationStateModel>, action: ReportAssignation.SetQueryOptions): void {
        const state: ReportAssignationStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IReportAssignationQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;
        ctx.setState(
            patch<ReportAssignationStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(ReportAssignation.UpdateColumnPreferences)
    public updateColumnPreferences(
        ctx: StateContext<ReportAssignationStateModel>,
        action: ReportAssignation.UpdateColumnPreferences
    ): void {
        ctx.setState(
            patch<ReportAssignationStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(ReportAssignation.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<ReportAssignationStateModel>): void {
        ctx.setState(
            patch<ReportAssignationStateModel>({
                scrollableColumns: reportAssignationColumns,
            })
        );
    }

    @Action(ReportAssignation.ToggleFilters)
    public setFiltersDisplay(ctx: StateContext<ReportAssignationStateModel>, action: ReportAssignation.ToggleFilters): void {
        ctx.setState(
            patch<ReportAssignationStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    @Action(ReportAssignation.SetMultipleSelectedRows)
    public setMultipleSelectedRows(
        ctx: StateContext<ReportAssignationStateModel>,
        action: ReportAssignation.SetMultipleSelectedRows
    ): void {
        const state: ReportAssignationStateModel = ctx.getState();

        const multipleSelectedRows: IReportAssignationTableData[] = state.data.filter((pretestData: IReportAssignationTableData) =>
            action.tastingIds.includes(pretestData.tasting_id)
        );

        ctx.setState(
            patch<ReportAssignationStateModel>({
                multipleSelectedRows,
            })
        );
    }

    private formatTableData(data: IReportAssignation[]): IReportAssignationTableData[] {
        const tableData: IReportAssignationTableData[] = [];

        data.forEach((item: IReportAssignation) => {
            const report: IReportAssignationTableData = {
                report_id: item.id,
                user_id: item.product.user.id,
                sta_year: item.product.tasting.sta_year,
                contact_owner: item.product.user.contact_owner,
                tasting_id: item.product.tasting.id,
                company_name: item.product.user.company_name,
                product_name: item.product.name,
                comments: item.product.tasting.options.comments_suggestions,
                question: item.product.tasting.options.question,
                food_pairing: item.product.tasting.options.food_pairing,
                product_id: item.product.id,
                feedback_only: item.product.tasting.feedback_only,
                final_stars: item.final_star,
                redactor_assigned: item.assigned_redactor ? item.assigned_redactor.email : '',
                redaction_status: item.redaction_status,
                evaluation_report: item.evaluation_report_path,
                publish: item.public,
            };
            tableData.push(report);
        });

        return tableData;
    }
}
