import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ResultsAndReportsExportFieldsEnum } from '../enums/results-and-reports-export.enum';
import { IExportColumn } from '../interfaces/export-column.interface';
import {
    IResultsAndReportsDataDTOIn,
    IResultsAndReportsDetails,
    IResultsAndReportsQueryOptions,
} from '../interfaces/results-and-reports.interface';
import { IEmptyFilterItem, emptyFilterList } from '../utils/empty-filter.utils';
import { IReadinessFilterItem, readinessFilterList } from '../utils/readiness-filter.utils';
import { mapFiltersToDTO } from '../utils/utility-functions';
import { ResultsAndReportsFiltersLabelEnum, ResultsAndReportsFiltersTypeEnum } from './../enums/results-and-reports-filters.enum';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { IFilter, IFilterFromDto, IFilterToDto } from './../interfaces/filter.interface';
import { IResultsAndReportsQueryOptionsDTO } from './../interfaces/results-and-reports.interface';
import { IAwardItem, awardList } from './../utils/award.utils';
import { IBooleanItem, booleanOptions } from './../utils/boolean-select.utils';
import { ICategoryTypeItem, categoryTypeList } from './../utils/category-type.utils';
import { IClaimsItem, claimsList } from './../utils/claims.utils';
import { ICompanySizeItem, companySizeList } from './../utils/company-size.utils';
import { IPaymentStatusItem, paymentStatusList } from './../utils/payment-status.utils';
import { ITastingOptionsItem, tastingOptionsList } from './../utils/tasting-options.utils';

@Injectable({
    providedIn: 'root',
})
export class ResultsAndReportsService {
    constructor(private http: HttpClient, private translateService: TranslateService) {}

    public getResultsAndReportsData(queryOptions: IResultsAndReportsQueryOptions): Observable<IResultsAndReportsDataDTOIn> {
        const body: IResultsAndReportsQueryOptionsDTO = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IResultsAndReportsDataDTOIn>(environment.itqiApi + SubUrlsEnum.RESULTS_AND_REPORTS, body, { params });
    }

    public getResultsAndReportsFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.RESULTS_AND_REPORTS_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.mapResultsAndReportsFilter(result)));
    }

    public showResultsAndReportsDetails(id: number): Observable<IResultsAndReportsDetails> {
        return this.http.get<IResultsAndReportsDetails>(environment.itqiApi + SubUrlsEnum.RESULTS_AND_REPORTS + `/${id.toString()}`);
    }

    public export(queryOptions: IResultsAndReportsQueryOptions): Observable<string> {
        const activeFilters: IFilterToDto[] = mapFiltersToDTO(queryOptions.filters);
        const activeSort: string = queryOptions.sortCondition;
        const textFilter: string = queryOptions.textFilter;

        const formattedColumns: IExportColumn[] = this.getExportColumns();

        return this.http.post(
            environment.itqiApi + SubUrlsEnum.RESULTS_AND_REPORTS_EXPORT,
            {
                columns: formattedColumns,
                sort: activeSort,
                filters: activeFilters,
                q: textFilter,
            },
            { responseType: 'text' }
        );
    }

    public uploadPackshot(file: File, tastingId: number): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders({
            ['Content-Type']: 'multipart/form-data',
        });

        const formData: FormData = new FormData();

        formData.append('image', file);

        return this.http.post<unknown>(environment.itqiApi + SubUrlsEnum.RESULTS_AND_REPORTS_UPLOAD_PACKSHOT + `/${tastingId}`, formData, {
            headers,
        });
    }

    public generateVideo(tastingIds: number[]): Observable<string> {
        return this.http.post<string>(environment.itqiApi + SubUrlsEnum.RESULTS_AND_REPORTS_GENERATE_VIDEO, { tasting_ids: tastingIds });
    }

    public getAvailableYearsForVideoGeneration(): Observable<number[]> {
        return this.http.get<number[]>(environment.itqiApi + SubUrlsEnum.VIDEO_AVAILABLE_FOR_STA_YEAR);
    }

    private mapResultsAndReportsFilter(filterObj: IFilterFromDto): IFilter[] {
        const filterArray: IFilter[] = [];

        Object.values(ResultsAndReportsFiltersLabelEnum).forEach((label: string) => {
            switch (label) {
                case ResultsAndReportsFiltersLabelEnum.STA_YEAR:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.STA_YEAR,
                        label,
                        options: filterObj[ResultsAndReportsFiltersTypeEnum.STA_YEAR].values.sort().map((value: number) => ({
                            value,
                            label: value.toString(),
                        })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.CONTACT_OWNER:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.CONTACT_OWNER,
                        label,
                        options: filterObj[ResultsAndReportsFiltersTypeEnum.CONTACT_OWNER].values.map(
                            (contact: { id: number; name: string }) => ({
                                value: contact.id,
                                label: contact.name,
                            })
                        ),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.FOOD_DRINK:
                    /* Need to add a way to differentiate between category types, or filters will overwrite each other */
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.CATEGORY + '##' + ResultsAndReportsFiltersLabelEnum.FOOD_DRINK,
                        label,
                        options: categoryTypeList.map((item: ICategoryTypeItem) => ({ value: item.value, label: item.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.MOTHER_CAT:
                    /* Same as for FOOD_DRINK cats */
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.CATEGORY + '##' + ResultsAndReportsFiltersLabelEnum.MOTHER_CAT,
                        label,
                        options: null,
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.CAT:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.CATEGORY,
                        label,
                        options: null,
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.CLAIMS:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.CLAIMS,
                        label,
                        options: claimsList.map((c: IClaimsItem) => ({ value: c.value, label: c.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.STARS:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.STARS,
                        label,
                        options: [...new Array(4)].map((item: void, index: number) => ({ value: index, label: index.toString() })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.PRESTIGE_AWARD:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.PRESTIGE_AWARD,
                        label,
                        options: awardList.map((a: IAwardItem) => ({ value: a.value, label: a.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.PAYMENT_STATUS:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.PAYMENT_STATUS,
                        label,
                        options: paymentStatusList.map((p: IPaymentStatusItem) => ({ value: p.value, label: p.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.RESULTS_PUBLIC_CLIENT:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.RESULTS_PUBLIC_CLIENT,
                        label,
                        options: booleanOptions.map((b: IBooleanItem) => ({ value: b.value, label: b.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.PUBLISHED_ON_WEBSITE:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.PUBLISHED_ON_WEBSITE,
                        label,
                        options: booleanOptions.map((b: IBooleanItem) => ({ value: b.value, label: b.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.REPORT_PUBLIC_CLIENT:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.REPORT_PUBLIC_CLIENT,
                        label,
                        options: booleanOptions.map((b: IBooleanItem) => ({ value: b.value, label: b.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.LICENCE_AGREEMENT_VALIDATED:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.LICENCE_AGREEMENT_VALIDATED,
                        label,
                        options: booleanOptions.map((b: IBooleanItem) => ({ value: b.value, label: b.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.OPTIONS:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.OPTIONS,
                        label,
                        options: tastingOptionsList.map((o: ITastingOptionsItem) => ({ value: o.value, label: o.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.FEEDBACK_ONLY:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.FEEDBACK_ONLY,
                        label,
                        options: booleanOptions.map((b: IBooleanItem) => ({ value: b.value, label: b.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.OPTION_COUNT:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.OPTION_COUNT,
                        label,
                        options: [...new Array(4)].map((item: void, index: number) => ({ value: index, label: index.toString() })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.COUNTRY:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.COUNTRY,
                        label,
                        options: null,
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.COMPANY_SIZE:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.COMPANY_SIZE,
                        label,
                        options: companySizeList.map((item: ICompanySizeItem) => ({ value: item.name, label: item.size })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.CANDIDATE_AWARD:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.CANDIDATE_AWARD,
                        label,
                        options: awardList.map((a: IAwardItem) => ({ value: a.value, label: a.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.TEST_DATE:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.TEST_DATE,
                        label,
                        options: filterObj[ResultsAndReportsFiltersTypeEnum.TEST_DATE].values.map((value: string) => ({
                            value,
                            label: new Date(value).toLocaleDateString(),
                        })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.PACKSHOT:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.PACKSHOT,
                        label,
                        options: emptyFilterList.map((e: IEmptyFilterItem) => ({ value: e.value, label: e.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.LOGO:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.LOGO,
                        label,
                        options: emptyFilterList.map((e: IEmptyFilterItem) => ({ value: e.value, label: e.label })),
                    });
                    break;
                case ResultsAndReportsFiltersLabelEnum.VIDEO_GENERATED:
                    filterArray.push({
                        type: ResultsAndReportsFiltersTypeEnum.VIDEO_GENERATED,
                        label,
                        options: readinessFilterList.map((e: IReadinessFilterItem) => ({ value: e.value, label: e.label })),
                    });
                    break;
            }
        });

        return filterArray;
    }

    private getExportColumns(): IExportColumn[] {
        return [
            {
                field: ResultsAndReportsExportFieldsEnum.TASTING_ID,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.TASTING_ID'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.TASTING_PUBLIC_ID,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.TASTING_PUBLIC_ID'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.USER_ID,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.USER_ID'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.PRODUCT_ID,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PROD_ID'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.COMPANY_NAME,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.COMPANY_NAME'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.PRODUCT_NAME,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PROD_NAME'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.STA_YEAR,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.STA_YEAR'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.CONTACT_OWNER,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.CONTACT_OWNER'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.COMPANY_NAME_ON_CERTIFICATE,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.NAME_ON_CERTIF'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.ORDER_DATE,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.ORDER_DATE'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.TEST_DATE,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.TEST_DATE'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.TASTING_STATUS,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.TASTING_STATUS'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.BC_COMMENT,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.COMMENT_FIELD'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.STAR,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.STARS'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.SCORE,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.SCORE'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.PRESTIGE_AWARD,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PRESTIGE_AWARD'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.NEW_CLIENT,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.NEW_CLIENT'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.FOOD_DRINK,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.FOOD_DRINK'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.CATEGORY_ID,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.CATEGORY_ID'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.MOTHER_CAT,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.MOTHER_CATEGORY'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.CATEGORY,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.CAT_NAME'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.CLAIMS,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.CLAIMS'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.PAYMENT_STATUS,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PAYMENT_STATUS'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.RESULTS_PUBLIC,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.RESULTS_PUBLIC'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.PUBLISHED_ON_WEBSITE,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PUBLISHED'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.REPORT_PUBLIC,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.REPORT_PUBLIC'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.LICENCE_VALIDATED,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.LICENCE'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.COMMENTS,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.COMMENTS_SUGGESTIONS'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.FOOD_PAIRING,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.FOOD_PAIRING'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.QUESTIONS,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.SPECIFIC_QUESTIONS'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.FEEDBACK_ONLY,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.FEEDBACK_ONLY'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.OPTION_COUNT,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.OPTION_COUNT'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.COUNTRY,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.COUNTRY'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.COMPANY_SIZE,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.COMPANY_SIZE'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.CANDIDATE_AWARD,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.CANDIDATE_AWARD'),
            },
            {
                field: ResultsAndReportsExportFieldsEnum.MOVIE_DOWNLOAD,
                label: this.translateService.instant('MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.EXPORT.MOVIE_DOWNLOAD'),
            },
        ];
    }
}
