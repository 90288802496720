export enum RedactionStatusEnum {
    READY = 'ready',
    UNASSIGNED = 'unassigned',
    NOT_STARTED = 'not_started',
    ONGOING = 'ongoing',
}

export enum ProofreaderStatusEnum {
    READY = 'ready',
    UNASSIGNED = 'unassigned',
    NOT_STARTED = 'not_started',
}
