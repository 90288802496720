import { IReportAssignationQueryOptions } from '../../interfaces/report-assignation.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';

export namespace ReportAssignation {
    export class GetFilters {
        public static readonly type = '[ReportAssignation] Get filters';
    }

    export class FetchAll {
        public static readonly type = '[ReportAssignation] Get reports';
    }

    export class GetOne {
        public static readonly type = '[ReportAssignation] get one';

        constructor(public reportId: number) {}
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[ReportAssignation] Set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[ReportAssignation] reset column prefs';
    }

    export class SetQueryOptions {
        public static readonly type = '[ReportAssignation] Set query options';

        constructor(
            public options: Partial<IReportAssignationQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class ToggleFilters {
        public static readonly type = '[ReportAssignation] Set report assignation filters display status';
        constructor(public showFilters: boolean) {}
    }

    export class SetMultipleSelectedRows {
        public static readonly type = '[ReportAssignation] set multiple rows as selected';

        constructor(public tastingIds: number[]) {}
    }
}
