import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormControlComponent } from '../../../abstracts/form-control.abstract';
import { generateUniqueId } from '../../../utils/utility-functions';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileInputComponent,
            multi: true,
        },
    ],
})
export class FileInputComponent extends FormControlComponent implements OnInit {
    @Input() public inputId: string;
    @Input() public label = 'File';
    @Input() public accept: string = null;

    public name: string;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!this.inputId) {
            this.inputId = generateUniqueId();
        }
    }

    public writeValue(formValue: File): void {
        this.value = formValue;
        this.name = this.value?.name || null;
    }

    public onFileSelect(event: Event): void {
        if (this.disabled) {
            return;
        }
        this.markAsTouched();

        const file: File = (event.target as HTMLInputElement).files[0] || null;

        this.value = file;

        this.name = file ? file.name : null;
        this.onChange(this.value);
    }

    public onFileRemove(event: Event, input: HTMLInputElement): void {
        event.preventDefault();

        this.value = null;
        this.name = null;
        input.value = null;

        this.onChange(this.value);
    }
}
