import { IInvoiceQueryOptions } from '../../interfaces/invoices.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { ICreditNoteDTOOut, IInvoiceDTOOut, IPaymentDTOOut } from './../../interfaces/invoices.interface';

export namespace Invoice {
    export class GetInvoicesFilters {
        public static readonly type = '[Invoices] Get filters';
    }
    export class GetAllInvoices {
        public static readonly type = '[Invoices] Get invoices';
    }

    export class CreateInvoice {
        public static readonly type = '[Invoices] Create invoice';
        constructor(public invoice: IInvoiceDTOOut) {}
    }

    export class CreateCreditNote {
        public static readonly type = '[Invoices] Create credit note';
        constructor(public creditNote: ICreditNoteDTOOut) {}
    }

    export class CreatePayment {
        public static readonly type = '[Invoices] Create payment';
        constructor(public payment: IPaymentDTOOut) {}
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[Invoices] Set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[Invoices] reset column prefs';
    }

    export class SetQueryOptions {
        public static readonly type = '[Invoices] Set query options';

        constructor(
            public options: Partial<IInvoiceQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class ToggleFilters {
        public static readonly type = '[Invoices] Set invoices filters display status';
        constructor(public showFilters: boolean) {}
    }

    export class GetStatistics {
        public static readonly type = '[Invoices] get stats';
    }
}
