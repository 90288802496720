export enum ResultsAndReportsExportFieldsEnum {
    TASTING_ID = 'tasting.id',
    TASTING_PUBLIC_ID = 'tasting.public_id',
    USER_ID = 'user.id',
    PRODUCT_ID = 'product.id',
    COMPANY_NAME = 'user.company_name',
    PRODUCT_NAME = 'product.name',
    STA_YEAR = 'tasting.sta_year',
    CONTACT_OWNER = 'user.contact_owner',
    COMPANY_NAME_ON_CERTIFICATE = 'user.company_name_on_certificate',
    ORDER_DATE = 'tasting.orderdate',
    TEST_DATE = 'tasting.test_date',
    TASTING_STATUS = 'tasting.tasting_status',
    BC_COMMENT = 'tasting.bc_comments',
    STAR = 'final_score.stars',
    SCORE = 'final_score.total',
    PRESTIGE_AWARD = 'tasting.prestige_award',
    NEW_CLIENT = 'tasting.new_client',
    FOOD_DRINK = 'tasting.food_drink',
    CATEGORY_ID = 'product.category_id',
    MOTHER_CAT = 'product.mother_category_name',
    CATEGORY = 'product.category_name',
    CLAIMS = 'product.claims',
    PAYMENT_STATUS = 'user.payment_status',
    RESULTS_PUBLIC = 'tasting.result_public_to_client',
    PUBLISHED_ON_WEBSITE = 'tasting.published_on_website',
    REPORT_PUBLIC = 'tasting.report_public_to_client',
    LICENCE_VALIDATED = 'tasting.license_validated',
    COMMENTS = 'tasting.comments',
    FOOD_PAIRING = 'tasting.food_pairing',
    QUESTIONS = 'tasting.questions',
    FEEDBACK_ONLY = 'tasting.feedback_only',
    OPTION_COUNT = 'tasting.option_count',
    COUNTRY = 'user.country',
    COMPANY_SIZE = 'user.company_size',
    CANDIDATE_AWARD = 'tasting.candidate_award',
    MOVIE_DOWNLOAD = 'movie_download',
}
