import { StorageConditionsEnum } from './../enums/storage-conditions.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IStorageConditionItem = IGenericListItem<StorageConditionsEnum>;

export const storageConditionList: IStorageConditionItem[] = [
    {
        id: 1,
        value: StorageConditionsEnum.FREEZER,
        label: 'STORAGE_CONDITIONS.FREEZER',
    },
    {
        id: 2,
        value: StorageConditionsEnum.FRIDGE,
        label: 'STORAGE_CONDITIONS.FRIDGE',
    },
    {
        id: 3,
        value: StorageConditionsEnum.ROOM_TEMPERATURE,
        label: 'STORAGE_CONDITIONS.ROOM_TEMP',
    },
];
