export class GetAdmins {
    public static readonly type = '[User Rights] Get Admin';

    constructor(public flags: Record<'bustCache', boolean> = { bustCache: false }) {}
}

export class GetRightsDetails {
    public static readonly type = '[User Rights] Get Rights';
}

export class UpdateAdminRight {
    public static readonly type = '[User Rights] Update User Right';
    constructor(public id: number, public roleName: string) {}
}

export class DeleteAdmin {
    public static readonly type = '[User Rights] Delete User';
    constructor(public id: number) {}
}

export class CreateAdmin {
    public static readonly type = '[User Rights] Create Admin';
    constructor(public roleName: string, public email: string) {}
}
