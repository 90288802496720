import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../environments/environment';
import { httpInterceptorProviders } from '../shared/interceptors';
import { mockInterceptorProvider } from '../shared/interceptors/mock.interceptor';
import { SharedModule } from '../shared/shared.module';
import { generateUniqueId } from './../shared/utils/utility-functions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { componentsApp } from './components';
import { appContainers } from './containers';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader =>
    new TranslateHttpLoader(http, './assets/locales/', '.json?cb=' + generateUniqueId());

@NgModule({
    declarations: [AppComponent, ...componentsApp, ...appContainers],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        NgxsModule.forRoot([], { developmentMode: !environment.production, selectorOptions: { suppressErrors: false } }),
        NgxsStoragePluginModule.forRoot({
            key: [
                'auth.accessToken',
                'auth.refreshToken',
                'pretest.scrollableColumns',
                'pretest.queryOptions',
                'announcements.announcementsTable.scrollableColumns',
                'announcements.announcementsTable.queryOptions',
                'announcements.newAnnouncementsTable.scrollableColumns',
                'announcements.newAnnouncementsTable.queryOptions',
                'pretestTranslations.scrollableColumns',
                'pretestTranslations.queryOptions',
                'announcementTranslations.scrollableColumns',
                'announcementTranslations.queryOptions',
                'invoice.scrollableColumns',
                'invoice.queryOptions',
                'reportManagement.reportAssignation.scrollableColumns',
                'reportManagement.reportAssignation.queryOptions',
                'reportManagement.reportRedaction.scrollableColumns',
                'reportManagement.reportRedaction.queryOptions',
                'resultsValidation.scrollableColumns',
                'resultsValidation.queryOptions',
                'resultsAndReports.scrollableColumns',
                'resultsAndReports.queryOptions',
            ],
        }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [environment.mock.enable ? [...httpInterceptorProviders, mockInterceptorProvider] : httpInterceptorProviders],
    bootstrap: [AppComponent],
})
export class AppModule {}
