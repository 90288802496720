export enum SubUrlsEnum {
    // AUTH
    LOGIN = 'auth/authentication',
    LOGOUT = 'auth/logout',
    REFRESH = 'auth/refresh_token',
    CHANGE_PASSWORD = 'auth/change_password',

    // USER
    USERS = 'workspace/users',
    USER = 'workspace/user',
    PROFILE = 'profile',
    DELETE_ADMIN = 'workspace/administrators/',

    // REFERENTIALS
    REFERENTIALS = 'my_workspace/referentials',

    // CATEGORIES
    CATEGORIES = 'my_workspace/categories',

    // PRODUCTS
    PRODUCTS = 'workspace/products',
    PRODUCTS_ASSIGN_ANNOUNCEMENT = 'workspace/products/assign_announcement',
    PRODUCTS_UPLOAD_INGREDIENT_LIST = 'workspace/products/{{id}}/upload_ingredient_list',

    // MY_WORKSPACE PRETEST
    PRETEST = 'workspace/pretest_data',
    PRETEST_FILTERS = 'workspace/pretest_data/filters',
    PRETEST_TRANSLATIONS = 'workspace/pretest_data_translations',
    PRETEST_TRANSLATIONS_FILTERS = 'workspace/pretest_data_translations/filters',
    PRETEST_EXPORT = 'workspace/pretest_data/export',

    // MY_WORKSPACE ANNOUNCEMENTS
    ANNOUNCEMENTS_INDEX = 'workspace/announcements/all',
    ANNOUNCEMENTS = 'workspace/announcements',
    ANNOUNCEMENTS_FILTERS = 'workspace/announcements/filters',
    ANNOUNCEMENT_TRANSLATIONS = 'workspace/announcement_translations',
    ANNOUNCEMENT_TRANSLATIONS_FILTERS = 'workspace/announcement_translations/filters',
    ANNOUNCEMENT_LINKING = 'workspace/announcements/{{id}}/switch',
    ANNOUNCEMENT_EXPORT = 'workspace/announcements/export',

    // DISCOUNTS
    DISCOUNTS = 'workspace/discounts',

    // SPECIFIC QUESTIONS
    SPECIFIC_QUESTIONS = 'workspace/specific_questions',

    // ADMIN ROLES AND RIGHTS
    ADMINS = 'workspace/administrators',
    ROLES = 'workspace/roles',
    ADMIN_REGISTER = 'workspace/administrators/register?token=',

    // INVOICES
    INVOICES = 'workspace/invoices',
    INVOICES_FILTERS = 'workspace/invoices/filters',
    CREATE_INVOICE = 'workspace/invoices/invoice',
    CREATE_CREDIT_NOTE = 'workspace/invoices/credit_note',
    CREATE_PAYMENT = 'workspace/invoices/payment',
    EXPORT_INVOICES = 'workspace/invoices/export/invoices',
    EXPORT_CREDIT_NOTES = 'workspace/invoices/export/credit_notes',
    EXPORT_BALANCE = 'workspace/invoices/export/balance',

    // TASTINGS
    TASTINGS = 'workspace/tastings',
    STA_YEAR_LIST = 'workspace/tastings/sta_years',
    TASTING_ASSIGN_USER = 'workspace/tastings/change_user',
    TASTING_CHANGE_STA_YEAR = 'workspace/tastings/change_sta_year',
    TASTING_CHANGE_PRODUCT = 'workspace/tastings/change_product',
    TASTING_DELETE_OPTION = '/workspace/tastings/{{id}}/new_options',

    // TRANSLATIONS
    TRANSLATIONS = 'workspace/translations',

    // PLANNINGS
    PLANNINGS = 'workspace/plannings',
    DISPATCH_PLANNING = 'workspace/plannings/{{id}}/dispatch',
    PLANNING_REPORTS = '/reports/plannings',
    PLANNING_UNPLAN = 'workspace/plannings/unplan',

    // RESULTS VALIDATION
    RESULTS = 'workspace/result_validation_tools',
    RESULTS_FILTERS = 'workspace/result_validation_tools/filters',
    RESULTS_CALIBRATION = 'workspace/result_validation_tools/calibrate',
    RESULTS_VALIDATE = 'workspace/result_validation_tools/validate',
    RESULS_VALIDATION_STATS = 'workspace/result_validation_tools/stats',
    RESULTS_INVALIDATE = 'workspace/result_validation_tools/invalidate',
    UPDATE_FINAL_SCORE = 'workspace/result_validation_tools/{{id}}/final_score',

    // REPORT MANAGEMENT
    REPORTS_ASSIGNATION = 'workspace/report_assignations',
    REPORTS_ASSIGNATION_FILTERS = 'workspace/report_assignations/filters',
    REPORT_ASSIGN = 'workspace/report_assignations/assign',
    REPORT_UNASSIGN = 'workspace/report_assignations/unassign',
    REPORT_REDACTION = 'workspace/report_redactions',
    REPORT_REDACTION_FILTERS = 'workspace/report_redactions/filters',
    REPORT_DETAILS = 'workspace/report_details',

    // RESULTS AND REPORTS
    RESULTS_AND_REPORTS = 'workspace/results_reports',
    RESULTS_AND_REPORTS_FILTERS = 'workspace/results_reports/filters',
    RESULTS_AND_REPORTS_EXPORT = 'workspace/results_reports/export',
    RESULS_STATS = 'workspace/results_reports/stats',
    RESULTS_AND_REPORTS_UPLOAD_PACKSHOT = 'workspace/packshots',
    RESULTS_AND_REPORTS_GENERATE_VIDEO = 'workspace/movies/export',
    VIDEO_AVAILABLE_FOR_STA_YEAR = 'workspace/movies/sta_year_ready',

    // LOGO
    DELETE_LOGO = 'workspace/users/{{id}}/delete_logo',
}
