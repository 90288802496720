import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IFilter, IFilterFromDto } from '../interfaces/filter.interface';
import {
    ICalibrationResultDtoIn,
    IResultValidationCalibration,
    IResultValidationsDto,
    IResultValidationsQueryOptions,
    IResultValidationsQueryOptionsDTO,
    IResultValidationsStats,
    IResultValidationStatsDto,
    ITastingScoreComments,
} from '../interfaces/results-validation.interface';
import { awardList, IAwardItem } from '../utils/award.utils';
import { booleanOptions, IBooleanItem } from '../utils/boolean-select.utils';
import { categoryTypeList, ICategoryTypeItem } from '../utils/category-type.utils';
import { companySizeList, ICompanySizeItem } from '../utils/company-size.utils';
import { IPastStarItem, pastStarsList } from '../utils/past-stars.utils';
import { computePourcentage } from '../utils/score-stats.utils';
import { ITastingStatusItem, tastingStatusList } from '../utils/tasting-status.utils';
import { mapFiltersToDTO } from '../utils/utility-functions';
import { ResultsValidationToolFiltersLabelEnum, ResultValidationToolFiltersTypeEnum } from './../enums/result-validation-tool-filters.enum';
import { IFilterOption } from './../interfaces/filter.interface';
import { IResultValidationStarsDto } from './../interfaces/results-validation.interface';

@Injectable({
    providedIn: 'root',
})
export class ResultValidationToolService {
    constructor(private http: HttpClient) {}

    public getAllResults(queryOptions: IResultValidationsQueryOptions): Observable<IResultValidationsDto> {
        const body: IResultValidationsQueryOptionsDTO = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IResultValidationsDto>(environment.itqiApi + SubUrlsEnum.RESULTS, body, {
            params,
        });
    }

    public getFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.RESULTS_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.parseFilter(result)));
    }

    public getStats(queryOptions: IResultValidationsQueryOptions): Observable<IResultValidationsStats> {
        const body: any = {
            sort: queryOptions.sortCondition,
            filters: queryOptions.filters,
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }
        return this.http
            .post<IResultValidationStatsDto>(environment.itqiApi + SubUrlsEnum.RESULS_VALIDATION_STATS, body, {
                params,
            })
            .pipe(map((result: IResultValidationStatsDto) => this.parseStats(result)));
    }

    public validateTastings(tastingIds: number[]): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.RESULTS_VALIDATE, { tasting_ids: tastingIds });
    }

    public invalidateTastings(tastingIds: number[]): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.RESULTS_INVALIDATE, { tasting_ids: tastingIds });
    }

    public calibrate(calibration: IResultValidationCalibration): Observable<ICalibrationResultDtoIn> {
        return this.http.post<ICalibrationResultDtoIn>(environment.itqiApi + SubUrlsEnum.RESULTS_CALIBRATION, calibration);
    }

    public getScoresAndComments(tastingId: number): Observable<ITastingScoreComments> {
        return this.http.get<ITastingScoreComments>(environment.itqiApi + SubUrlsEnum.RESULTS + '/' + tastingId);
    }

    public updateFinalScore(tastingId: number, newScore: number): Observable<void> {
        return this.http.put<void>(environment.itqiApi + SubUrlsEnum.UPDATE_FINAL_SCORE.replace('{{id}}', tastingId.toString()), {
            score: newScore,
        });
    }

    private parseFilter(filters: IFilterFromDto): IFilter[] {
        if (!filters) return [];

        const parsedFilters: IFilter[] = [];
        const starsOptions: IFilterOption[] = [...new Array(4)].map((item: void, index: number) => ({
            value: index,
            label: index.toString(),
        }));

        Object.values(ResultsValidationToolFiltersLabelEnum).forEach((label: string) => {
            switch (label) {
                case ResultsValidationToolFiltersLabelEnum.STA_YEAR:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.STA_YEAR,
                        label,
                        options: filters[ResultValidationToolFiltersTypeEnum.STA_YEAR].values.sort().map((value: number) => ({
                            value,
                            label: value.toString(),
                        })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.CONTACT_OWNER:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.CONTACT_OWNER,
                        label,
                        options: filters[ResultValidationToolFiltersTypeEnum.CONTACT_OWNER].values.map(
                            (contact: { id: number; name: string }) => ({
                                value: contact.id,
                                label: contact.name,
                            })
                        ),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.TASTING_STATUS:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.TASTING_STATUS,
                        label,
                        options: tastingStatusList.map((t: ITastingStatusItem) => ({ value: t.value, label: t.label })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.TEST_DATE:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.TEST_DATE,
                        label,
                        options: filters[ResultValidationToolFiltersTypeEnum.TEST_DATE].values.map((value: string) => ({
                            value,
                            label: new Date(value).toLocaleDateString(),
                        })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.FOOD_DRINK:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.CATEGORY + '##' + ResultsValidationToolFiltersLabelEnum.FOOD_DRINK,
                        label,
                        options: categoryTypeList.map((item: ICategoryTypeItem) => ({ value: item.value, label: item.label })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.MOTHER_CAT:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.CATEGORY + '##' + ResultsValidationToolFiltersLabelEnum.MOTHER_CAT,
                        label,
                        options: null,
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.CAT:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.CATEGORY,
                        label,
                        options: null,
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.CANDIDATE_AWARD:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.CANDIDATE_AWARD,
                        label,
                        options: awardList.map((a: IAwardItem) => ({ value: a.value, label: a.label })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.PAST_STARS:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.PAST_STARS,
                        label,
                        options: pastStarsList.map((p: IPastStarItem) => ({ value: p.value, label: p.label })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.TEST_STARS:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.TEST_STARS,
                        label,
                        options: starsOptions,
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.FINAL_STARS:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.FINAL_STARS,
                        label,
                        options: starsOptions,
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.STARS_VAR:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.STARS_VAR,
                        label,
                        options: filters[ResultValidationToolFiltersTypeEnum.STARS_VAR].values.map((value: any) => ({
                            value,
                            label: value.toString(),
                        })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.MODIFIED:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.MODIFIED,
                        label,
                        options: booleanOptions.map((b: IBooleanItem) => ({ value: b.value, label: b.label })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.PRESTIGE_AWARD:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.PRESTIGE_AWARD,
                        label,
                        options: awardList.map((a: IAwardItem) => ({ value: a.value, label: a.label })),
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.COUNTRY:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.COUNTRY,
                        label,
                        options: null,
                    });
                    break;
                case ResultsValidationToolFiltersLabelEnum.COMPANY_SIZE:
                    parsedFilters.push({
                        type: ResultValidationToolFiltersTypeEnum.COMPANY_SIZE,
                        label,
                        options: companySizeList.map((item: ICompanySizeItem) => ({ value: item.name, label: item.size })),
                    });
                    break;
            }
        });

        return parsedFilters;
    }

    private parseStats(result: IResultValidationStatsDto): IResultValidationsStats {
        const starsArray: number[] = [0, 1, 2, 3];
        const stats: IResultValidationsStats = {
            total: result.total,
            ratings: starsArray.map((star: number) => ({
                nb_stars: star,
                test_count: result.test[star as keyof IResultValidationStarsDto],
                test_percentage: result.total ? computePourcentage(result.test[star as keyof IResultValidationStarsDto], result.total) : 0,
                auto_count: result.auto[star as keyof IResultValidationStarsDto],
                auto_percentage: result.total ? computePourcentage(result.auto[star as keyof IResultValidationStarsDto], result.total) : 0,
                final_count: result.final[star as keyof IResultValidationStarsDto],
                final_percentage: result.total
                    ? computePourcentage(result.final[star as keyof IResultValidationStarsDto], result.total)
                    : 0,
            })),
        };
        return stats;
    }
}
