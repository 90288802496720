import { ITableColumn } from '../interfaces/table-column.interface';

export const scrollablePretestTranslationColumns: ITableColumn[] = [
    {
        field: 'productId',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.PROD_ID',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'categoryName',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.CAT_NAME',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'contactOwner',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.CONTACT_OWNER',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'frenchTranslationStatus',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.QUESTION_FR',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'italianTranslationStatus',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.QUESTION_IT',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'spanishTranslationStatus',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.QUESTION_ES',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'globalTranslationStatus',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.GLOBAL_TRANSLATION_STATUS',
        sortable: false,
        editable: false,
        checked: true,
    },
];

export const frozenPretestTranslationColumns: ITableColumn[] = [
    {
        field: 'tastingId',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.TASTING_ID',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'companyName',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.COMPANY_NAME',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'productName',
        header: 'TRANSLATIONS_MODULE.PRETEST.TABLE.COL_HEADER.PROD_NAME',
        sortable: true,
        editable: false,
        checked: true,
    },
];
