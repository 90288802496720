import { ITableColumn } from '../interfaces/table-column.interface';

export const reportAssignationFrozenColumns: ITableColumn[] = [
    { field: 'checkbox', header: '', sortable: false, editable: false, checked: true },
    { field: 'tasting_id', header: 'REPORT_MANAGEMENT.COLS.TASTNG_ID', checked: true, sortable: true },
    { field: 'company_name', header: 'REPORT_MANAGEMENT.COLS.COMPANY_NAME', checked: true, sortable: true },
    { field: 'product_name', header: 'REPORT_MANAGEMENT.COLS.PRODUCT_NAME', checked: true, sortable: true },
];

export const reportAssignationColumns: ITableColumn[] = [
    { field: 'user_id', header: 'REPORT_MANAGEMENT.COLS.USER_ID', checked: true, sortable: false },
    { field: 'product_id', header: 'REPORT_MANAGEMENT.COLS.PRODUCT_ID', checked: true, sortable: true },
    { field: 'sta_year', header: 'REPORT_MANAGEMENT.COLS.STA_YEAR', checked: true, sortable: false },
    { field: 'contact_owner', header: 'REPORT_MANAGEMENT.COLS.CONTACT_OWNER', checked: true, sortable: false },
    { field: 'comments', header: 'REPORT_MANAGEMENT.COLS.COMMENTS', checked: true, sortable: true },
    { field: 'food_pairing', header: 'REPORT_MANAGEMENT.COLS.FOOD_PAIRING', checked: true, sortable: true },
    { field: 'question', header: 'REPORT_MANAGEMENT.COLS.QUESTION', checked: true, sortable: true },
    { field: 'feedback_only', header: 'REPORT_MANAGEMENT.COLS.FEEDBACK_ONLY', checked: true, sortable: false },
    { field: 'final_stars', header: 'REPORT_MANAGEMENT.COLS.FINAL_STARS', checked: true, sortable: true },
    { field: 'redactor_assigned', header: 'REPORT_MANAGEMENT.COLS.REDACTOR_ASSIGNED', checked: true, sortable: true },
    { field: 'redaction_status', header: 'REPORT_MANAGEMENT.COLS.REDACTION_STATUS', checked: true, sortable: true },
    { field: 'evaluation_report', header: 'REPORT_MANAGEMENT.COLS.EVALUTION_REPORT', checked: true, sortable: true },
];
