import { SpicinessEnum } from './../enums/spiciness.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ISpicinessItem = IGenericListItem<SpicinessEnum>;

export const spicinessList: ISpicinessItem[] = Object.keys(SpicinessEnum)
    .map((key: string, i: number) => {
        if (!isNaN(Number(key))) {
            return null;
        }

        return {
            id: i + 1,
            value: SpicinessEnum[key as keyof typeof SpicinessEnum],
            label: `SPICINESS.${key}`,
        };
    })
    .filter((item: ISpicinessItem) => item);
