import { SamplesDeliveredEnum } from './../enums/samples-delivered.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ISamplesDeliveredItem = IGenericListItem<SamplesDeliveredEnum>;

export const samplesDeliveredList: ISamplesDeliveredItem[] = Object.keys(SamplesDeliveredEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: SamplesDeliveredEnum[key as keyof typeof SamplesDeliveredEnum],
    label: `SAMPLES_DELIVERED.${key}`,
}));
