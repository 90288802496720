import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { HeaderErrorEnum } from './../enums/header-errors.enum';
import { IClientDtoIn } from './../interfaces/client.interface';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    constructor(private http: HttpClient) {}

    public getUserById(id: number): Observable<IClientDtoIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .get<IClientDtoIn>(environment.itqiApi + SubUrlsEnum.USERS + '/' + id, { headers })
            .pipe(map((result: any) => result.user));
    }
}
