import { SeasonsEnum } from './../enums/seasons.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ISeasonItem = IGenericListItem<SeasonsEnum>;

export const seasonsList: ISeasonItem[] = Object.keys(SeasonsEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: key === SeasonsEnum.NO_VALUE ? null : SeasonsEnum[key as keyof typeof SeasonsEnum],
    label: `SEASON.${key}`,
}));
