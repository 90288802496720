import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CriteriaTypeEnum } from '../enums/criteria-type.enum';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { ICategoryFoodDrink, ICategoryItem } from '../interfaces/category-food-drink.interface';
import { ICategory, ICategoryDTOIn } from '../interfaces/category.interface';
import { drinkCategoriesTrads, foodCategoriesTrads } from './../utils/category.utils';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    constructor(private http: HttpClient) {}

    public getCategories(): Observable<ICategory[]> {
        return this.http.get<ICategoryDTOIn>(environment.itqiApi + SubUrlsEnum.CATEGORIES).pipe(
            map((result: ICategoryDTOIn) => {
                const newCategories: ICategory[] = result.categories.map((cat: ICategory) => this.buildCategoryTranslation(cat));
                return newCategories;
            })
        );
    }

    private buildCategoryTranslation(category: ICategory, parentId?: number): ICategory {
        const translateKey: string = this.getKey(category);
        const name: string = translateKey ? translateKey : category.name;
        const newCat: ICategory = {
            ...category,
            name,
        };

        if (parentId) {
            newCat.parentId = parentId;
        }

        const children: ICategory[] = category.childreen
            ? category.childreen.map((c: ICategory) => this.buildCategoryTranslation(c, category.id))
            : null;

        if (children) {
            newCat.childreen = children;
        }

        if (category.id === 1191) {
            const subOtherFoodCatTitle: string = foodCategoriesTrads.find((cat: ICategoryFoodDrink) => cat.id === 1191).categories[0].title;
            /* When the category is "OTHER FOOD", there is a bug in the filters that contain food/drink, mother categories and child categories if the same
            id is used for the mother category other food and the child category other food.
            Here I arbitrarily change the mother category other food ID cause it will never be sent to the server anyways (we send child categories, and I keep the correct ID for the children here) */
            newCat.id = 11911;

            newCat.childreen = [
                {
                    ...category,
                    parentId: 11911,
                    name: subOtherFoodCatTitle,
                },
            ];
        }

        return newCat;
    }

    private getKey(category: ICategory): string {
        let name: string = null;

        const arrToSearch: ICategoryFoodDrink[] =
            category.criteria_type === CriteriaTypeEnum.DRINK ? drinkCategoriesTrads : foodCategoriesTrads;

        arrToSearch.forEach((translateObj: ICategoryFoodDrink) => {
            if (translateObj.id === category.id) {
                name = translateObj.title;
            }

            translateObj.categories.forEach((nestedObj: ICategoryItem) => {
                if (nestedObj.id === category.id && nestedObj.id !== 1191) {
                    name = nestedObj.title;
                }
            });
        });

        return name;
    }
}
