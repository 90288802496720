import { IAnnouncementQueryOptions, IAnnouncementTranslations } from '../../interfaces/announcement.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { TranslationLanguagesEnum } from './../../enums/translation-languages.enum';

export namespace AnnouncementTranslations {
    export class GetAll {
        public static readonly type = '[Announcement translations] get data';

        constructor(public type: { new: boolean } = { new: false }) {}
    }

    export class SetSelectedRow {
        public static readonly type = '[Announcement translations] set selected row';

        constructor(public announcementId: number) {}
    }

    export class SetQueryOptions {
        public static readonly type = '[Announcement translations] Set query options';

        constructor(
            public options: Partial<IAnnouncementQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class GetFilters {
        public static readonly type = '[Announcement translations] get filters';
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[Announcement translations] set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[Announcement translations] reset column prefs';
    }

    export class ToggleFilters {
        public static readonly type = '[Announcement translations] toggle filters';

        constructor(public showFilters: boolean) {}
    }

    export class SetModalContext {
        public static readonly type = '[Announcement translations] set modal context';

        constructor(public context: TranslationLanguagesEnum) {}
    }

    export class Update {
        public static readonly type = '[Announcement translations] update translations';

        constructor(public announcement: Partial<IAnnouncementTranslations>, public id: number) {}
    }

    export class SetAutomaticTranslation {
        public static readonly type = '[Announcement translations] set automatic translations';

        constructor(public announcementTranslationObj: IAnnouncementTranslations, public language: TranslationLanguagesEnum) {}
    }

    export class CallGoogleTradService {
        public static readonly type = '[Announcement translations] call google trad service';

        constructor(
            public fieldToUpdate: keyof IAnnouncementTranslations,
            public translationObj: IAnnouncementTranslations,
            public languageContext: TranslationLanguagesEnum
        ) {}
    }
}
