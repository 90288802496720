import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { AnnouncementsSortConditionsEnum } from '../../enums/announcements-filters.enum';
import { IAnnouncement, IAnnouncementQueryOptions, IAnnouncementsDTOIn } from '../../interfaces/announcement.interface';
import { Announcements } from '../actions/announcements.actions';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { AnnouncementsService } from './../../services/announcements.service';
import { AnnouncementsTableState } from './announcemments-table.state';
import { NewAnnouncementsTableState } from './new-announcements-table.state';

const queryOptionsDefaults: IAnnouncementQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    new: false,
    sortCondition: '-' + AnnouncementsSortConditionsEnum.ANNOUNCEMENT_ID,
    filters: [],
    textFilter: '',
};

export interface AnnouncementsStateModel {
    announcements: IAnnouncement[];
    selectedRow: IAnnouncement;
    queryOptions: IAnnouncementQueryOptions;
}

@State<AnnouncementsStateModel>({
    name: 'announcements',
    defaults: {
        announcements: [],
        selectedRow: null,
        queryOptions: queryOptionsDefaults,
    },
    children: [AnnouncementsTableState, NewAnnouncementsTableState],
})
@Injectable()
export class AnnouncementsState {
    constructor(private announcementsService: AnnouncementsService) {}

    @Action(Announcements.GetAll)
    public getAnnouncements(ctx: StateContext<AnnouncementsStateModel>, action: Announcements.GetAll): Observable<IAnnouncementsDTOIn> {
        ctx.setState(
            patch<AnnouncementsStateModel>({
                queryOptions: patch<IAnnouncementQueryOptions>({
                    new: action.type.new,
                }),
            })
        );

        const state: AnnouncementsStateModel = ctx.getState();

        return this.announcementsService.getAnnouncements(state.queryOptions).pipe(
            tap((result: IAnnouncementsDTOIn) => {
                ctx.setState(
                    patch<AnnouncementsStateModel>({
                        announcements: result.announcements,
                    })
                );
            })
        );
    }

    @Action(Announcements.SetSelectedRow)
    public setSelectedRow(ctx: StateContext<AnnouncementsStateModel>, action: Announcements.SetSelectedRow): void {
        const state: AnnouncementsStateModel = ctx.getState();

        const selectedRow: IAnnouncement =
            state.announcements.find((dataItem: IAnnouncement) => dataItem.id === action.announcementId) ?? null;

        ctx.patchState({ selectedRow });
    }

    @Action(Announcements.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<AnnouncementsStateModel>, action: Announcements.SetQueryOptions): void {
        const state: AnnouncementsStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IAnnouncementQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<AnnouncementsStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(Announcements.Link)
    public link(ctx: StateContext<AnnouncementsStateModel>, action: Announcements.Link): Observable<void> {
        return this.announcementsService
            .linkAnnouncement(action.announcementToReplaceId, action.announcementToLinkId)
            .pipe(tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.LINK_ANNOUNCEMENT', type: AlertTypeEnum.SUCCESS }))));
    }
}
