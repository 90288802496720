import { Selector } from '@ngxs/store';

import { PretestFiltersLabelEnum } from '../../enums/prestest-filters.enum';
import { ICategory } from '../../interfaces/category.interface';
import { ICNCode } from '../../interfaces/cn-code.interface';
import { IFilter } from '../../interfaces/filter.interface';
import { IPretestData } from '../../interfaces/pretest.interface';
import { ICountryResource } from '../../interfaces/referentials.interface';
import { CategoryState, CategoryStateModel } from '../state/category.state';
import { ReferentialsState } from '../state/referentials.state';
import { ICNCodeDict } from './../../interfaces/cn-code.interface';
import { ITableColumn } from './../../interfaces/table-column.interface';
import { capitalize } from './../../utils/utility-functions';
import { PretestState, PretestStateModel } from './../state/pretest.state';

export class PretestSelectors {
    @Selector([PretestState])
    public static selectedRow(state: PretestStateModel): IPretestData {
        return state.selectedRow;
    }

    @Selector([PretestState.bareFilters(), CategoryState, ReferentialsState.countries])
    public static filtersData(bareFilters: IFilter[], catState: CategoryStateModel, countries: ICountryResource[]): IFilter[] {
        if (!catState.categories.length || !countries.length) {
            return [];
        }

        const motherCats: ICategory[] = catState.motherCategories;
        const childCats: ICategory[] = catState.childCategories;

        const filtersWithCategoryValues: IFilter[] = bareFilters.map((filter: IFilter) => {
            if (filter.label === PretestFiltersLabelEnum.MOTHER_CAT) {
                return {
                    ...filter,
                    options: motherCats.map((c: ICategory) => ({ value: c.id, label: c.name })),
                };
            }

            if (filter.label === PretestFiltersLabelEnum.CAT) {
                return {
                    ...filter,
                    options: childCats.map((c: ICategory) => ({ value: c.id, label: c.name })),
                };
            }

            if (filter.label === PretestFiltersLabelEnum.COUNTRY) {
                return {
                    ...filter,
                    options: countries.map((country: ICountryResource) => ({ value: country.name, label: capitalize(country.name) })),
                };
            }

            return filter;
        });

        return filtersWithCategoryValues;
    }

    @Selector([PretestState])
    public static multipleSelectedRows(state: PretestStateModel): IPretestData[] {
        return state.multipleSelectedRows;
    }

    @Selector([PretestState])
    public static activeColumns(state: PretestStateModel): ITableColumn[] {
        return state.frozenColumns.concat(state.scrollableColumns).filter((column: ITableColumn) => column.checked);
    }

    @Selector([PretestState])
    public static cnCodes(state: PretestStateModel): ICNCode[] {
        return state.cnCodes;
    }

    @Selector([PretestState])
    public static cnCodesDictionary(state: PretestStateModel): ICNCodeDict {
        return state.cnCodesDictionary;
    }
}
