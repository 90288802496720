import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { IReportRedactionDTOIn } from '../interfaces/report-redaction.interface';
import { IFilterFromDto } from './../interfaces/filter.interface';
import { mockReportRedactionFilters, mockReportRedactionToolData } from './mock-data';

export const mockReporttRedactionToolRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: IReportRedactionDTOIn = mockReportRedactionToolData;
    const filters: IFilterFromDto = mockReportRedactionFilters;

    if (req.method === 'POST') {
        const res: any = {
            status: 200,
            body: data,
        };

        return of(new HttpResponse(res));
    }

    if (req.method === 'GET' && req.url.endsWith('filters')) {
        const res: any = {
            status: 200,
            body: filters,
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
