import {
    IResultValidation,
    IResultValidationRatingStats,
    IResultValidationsStats,
} from '../interfaces/results-validation.interface';

export const computeStatsBasedOnSelection = (tastings: IResultValidation[]): IResultValidationsStats => {
    const stats: IResultValidationsStats = {
        total: tastings.length,
        ratings: [
            {
                nb_stars: 0,
                test_count: 0,
                test_percentage: 0,
                auto_count: 0,
                auto_percentage: 0,
                final_count: 0,
                final_percentage: 0,
            },
            {
                nb_stars: 1,
                test_count: 0,
                test_percentage: 0,
                auto_count: 0,
                auto_percentage: 0,
                final_count: 0,
                final_percentage: 0,
            },
            {
                nb_stars: 2,
                test_count: 0,
                test_percentage: 0,
                auto_count: 0,
                auto_percentage: 0,
                final_count: 0,
                final_percentage: 0,
            },
            {
                nb_stars: 3,
                test_count: 0,
                test_percentage: 0,
                auto_count: 0,
                auto_percentage: 0,
                final_count: 0,
                final_percentage: 0,
            },
        ],
    };

    // compute count
    tastings.forEach((t: IResultValidation) => {
        // test score
        stats.ratings[t.test_score.stars].test_count += 1;
        // auto score
        stats.ratings[t.auto_score.stars].auto_count += 1;
        // final score
        stats.ratings[t.final_score.stars].final_count += 1;
    });

    // compute pourcentage
    stats.ratings.forEach((r: IResultValidationRatingStats) => {
        r.test_percentage = computePourcentage(r.test_count, stats.total);
    });
    stats.ratings.forEach((r: IResultValidationRatingStats) => {
        r.auto_percentage = computePourcentage(r.auto_count, stats.total);
    });
    stats.ratings.forEach((r: IResultValidationRatingStats) => {
        r.final_percentage = computePourcentage(r.final_count, stats.total);
    });
    return stats;
};

export const computePourcentage = (count: number, total: number): number => (100 / total) * count;
