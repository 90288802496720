import { DeliveryStatusEnum } from './../enums/delivery-status.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IDeliveryStatusItem = IGenericListItem<DeliveryStatusEnum>;

export const deliveryStatusList: IDeliveryStatusItem[] = Object.keys(DeliveryStatusEnum)
    .map((key: string, i: number) => ({
        id: i + 1,
        value: DeliveryStatusEnum[key as keyof typeof DeliveryStatusEnum],
        label: `DELIVERY_STATUS.${key}`,
    }))
    .concat([{ id: Object.keys(DeliveryStatusEnum).length + 1, value: null, label: 'UTIL.NO_VALUE' }]);
