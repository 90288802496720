import { AwardsEnum } from '../enums/awards.enum';
import { CriteriaTypeEnum } from '../enums/criteria-type.enum';
import { GenderEnum } from '../enums/gender.enum';
import { PaymentStatusEnum } from '../enums/payment-status.enum';
import { ProductClaimsEnum } from '../enums/product-claims.enum';
import { RedactionStatusEnum } from '../enums/report-management.enum';
import { StorageConditionsEnum } from '../enums/storage-conditions.enum';
import { IFieldToValidate } from '../interfaces/field-to-validate.interface';
import { IFilter } from '../interfaces/filter.interface';
import { IProfile } from '../interfaces/profile.interface';
import { IReportDetailsDTOIn } from '../interfaces/report-details.interface';
import { ITastingScoreComments } from '../interfaces/results-validation.interface';
import { ValidationStatusEnum } from './../enums/validation-status.enum';
import { IFilterFromDto } from './../interfaces/filter.interface';
import { IPretestDataDTOIn, IPretestTranslationsDTOIn } from './../interfaces/pretest.interface';
import { IProduct } from './../interfaces/product.interface';
import { IReportRedactionDTOIn } from './../interfaces/report-redaction.interface';
import { IResultsAndReportsDataDTOIn } from './../interfaces/results-and-reports.interface';

export const mockUser: IProfile = {
    id: 2033939,
    firstname: 'simon',
    lastname: 'fraipont',
    email: 'simon.fraipont@intotheweb.be',
    gender: GenderEnum.MALE,
    phone_number: '+32 9 334 42 23',
    language: 'FR',
    company_size: 'medium',
    company_name: 'ITW',
    country: 'BELGIUM',
    billing_city: 'nice city man',
    billing_company_name: 'Simon Fraipont',
    billing_country: 'BELGIUM',
    billing_email: 'company@email.com',
    billing_postal_code: '9233',
    billing_street: 'some street',
    city: 'city',
    company_name_on_certificate: 'itw',
    contact_owner: 'simon fraipont',
    job_title: '',
    mobile_phone_number: '+32 2309992908',
    postal_code: '1232',
    street: 'street',
    vat_number: 'BE 0999999999',
    website_url: 'http://my.website.com',
    applicable_tva: 21,
    customer_id: 'cus_JbsKLyQOJCZYjL',
    payment_status: PaymentStatusEnum.PAID,
};

export const mockProducts: IProduct[] = [];

/* Have to rework these cause pretestData interfaces have changed after integrating backend */
export const mockPretestData: IPretestDataDTOIn = {
    pagination: null,
    tastings: [],
};

export const mockPretestTranslationsData: IPretestTranslationsDTOIn = {
    pagination: {
        next_page: 2,
        current_page: 1,
        prev_page: null,
        total_pages: 22,
        total_items: 430,
    },
    tastings: [
        {
            id: 52103,
            product_id: 15010,
            company_name: 'TESTTEST',
            category_name: 'Teas, Infusions',
            product_name: '紅韻台茶21號(Hongyun TTES NO.21)',
            contact_owner: 'James bond',
            validation_status: ValidationStatusEnum.FOODEX_VALIDATED,
            validation_fr: true,
            validation_it: true,
            validation_es: true,
            client_specific_question: {
                id: 1,
                client_local: 'Which is the characteristic of this product that stands out most to you?',
                bc_en: 'Which is the characteristic of this product that stands out most to you?',
                question_fr: 'Quelle est la caractéristique de ce produit qui vous a le plus marqué?',
                question_it: 'Qual è la caratteristica di questo prodotto che spicca di più per te?',
                question_es: '¿Cuál es la característica de este producto que más le llama la atención?',
            },
            client_specific_question_two: {
                id: 2,
                client_local: 'Which is the characteristic of this product that stands out most to you? two',
                bc_en: 'Which is the characteristic of this product that stands out most to you? two',
                question_fr: 'Quelle est la caractéristique de ce produit qui vous a le plus marqué? two',
                question_it: 'Qual è la caratteristica di questo prodotto che spicca di più per te? two',
                question_es: '¿Cuál es la característica de este producto que más le llama la atención? two',
            },
            client_specific_question_three: {
                id: 3,
                client_local: 'Which is the characteristic of this product that stands out most to you? three',
                bc_en: 'Which is the characteristic of this product that stands out most to you? three',
                question_fr: 'Quelle est la caractéristique de ce produit qui vous a le plus marqué? three',
                question_it: 'Qual è la caratteristica di questo prodotto che spicca di più per te? three',
                question_es: '¿Cuál es la característica de este producto que más le llama la atención? three',
            },
        },
        {
            id: 52104,
            product_id: 15011,
            company_name: 'TESTTEST',
            category_name: 'Teas, Infusions',
            product_name: '紅韻台茶21號(TTES No.21 Hongyun)',
            contact_owner: 'James bond',
            validation_status: ValidationStatusEnum.FOODEX_VALIDATED,
            validation_fr: true,
            validation_it: true,
            validation_es: true,
            client_specific_question: {
                id: 37,
                client_local: 'Does this tea have the features which might be attractive for European market?',
                bc_en: 'Does this tea have the features which might be attractive for European market?',
                question_fr: 'Ce thé a-t-il les caractéristiques pour être introduit sur le marché européen?',
                question_it: 'Questo tè ha le caratteristiche che potrebbero essere interessanti per il mercato europeo?',
                question_es: '¿Este té tiene las características apropiadas para ser atractivo en el mercado europeo?',
            },
            client_specific_question_two: {
                id: 37,
                client_local: 'Does this tea have the features which might be attractive for European market? two',
                bc_en: 'Does this tea have the features which might be attractive for European market? two',
                question_fr: 'Ce thé a-t-il les caractéristiques pour être introduit sur le marché européen? two',
                question_it: 'Questo tè ha le caratteristiche che potrebbero essere interessanti per il mercato europeo? two',
                question_es: '¿Este té tiene las características apropiadas para ser atractivo en el mercado europeo? two',
            },
            client_specific_question_three: {
                id: 37,
                client_local: 'Does this tea have the features which might be attractive for European market? three',
                bc_en: 'Does this tea have the features which might be attractive for European market? three',
                question_fr: 'Ce thé a-t-il les caractéristiques pour être introduit sur le marché européen? three',
                question_it: 'Questo tè ha le caratteristiche che potrebbero essere interessanti per il mercato europeo? three',
                question_es: '¿Este té tiene las características apropiadas para ser atractivo en el mercado europeo three?',
            },
        },
    ],
};
export const mockSpecificQuestions: IFieldToValidate[] = [];

export const mockInvoiceFilter: any = {
    invoice_type: {
        type: 'integer',
        values: ['invoice', 'credit_note', 'payment'],
        db: ['invoice_type', 'in'],
    },
    sta_year: {
        type: 'integer',
        values: [2020, 2021, 2022],
        db: ['sta_year', 'in'],
    },
    payment_method: {
        type: 'enum',
        values: ['credit_card', 'bank_transfer'],
        db: ['payment_method', 'in'],
    },
    discounted: {
        type: 'integer_bool',
        values: [0, 1],
        db: [
            'discounted',
            'equal',
            {
                statement: 'having',
            },
        ],
    },
};

export const mockResultValidationToolFilters: IFilter[] = [
    {
        type: 'results.sta_year',
        label: 'Sta Year',
        options: [
            {
                label: '2020',
                value: 2020,
            },
            {
                label: '2021',
                value: 2021,
            },
        ],
    },
    {
        type: 'results.food_drink',
        label: 'Food Drink',
        options: [
            {
                label: 'Food',
                value: 'Food',
            },
            {
                label: 'Drink',
                value: 'Drink',
            },
        ],
    },
];

export const mockResultsAndReportsData: IResultsAndReportsDataDTOIn = {
    pagination: {
        next_page: 2,
        current_page: 1,
        prev_page: null,
        total_pages: 22,
        total_items: 430,
    },
    results_and_reports: [
        {
            id: 1,
            public_id: 1,
            sta_year: 2021,
            scores: {
                id: 3,
                cr1: '87.4',
                cr2: '94.3',
                cr3: '96.9',
                cr4: '88.2',
                cr5: '95.1',
                stars: 2,
                total: '98.7',
            },
            prestige_award: AwardsEnum.CRYSTAL,
            bc_comments: 'a comment',
            client_specific_question: {
                id: 9,
                client_local: 'A question 1 ?',
                bc_en: 'A Question 1 ?',
                question_es: '',
                question_fr: '',
                question_it: '',
            },
            validation_status: ValidationStatusEnum.BC_VALIDATED,
            sensory_results_path: 'urlToFile',
            certificate_path: 'urlToFile',
            result_public_to_client: true,
            packshot_path: 'https://img.cuisineaz.com/660x660/2021/02/25/i159373-pizza-margherita.jpeg',
            published_on_website: true,
            evaluation_report_path: 'urlToFile',
            report_public_to_client: false,
            options: {
                food_pairing: true,
                comments_suggestions: false,
                question: true,
            },
            feedback_only: true,
            test_date: '12 December 2021',
            licence_validated: true,
            product: {
                id: 12,
                name: 'Test product name',
                alcohol_level: 12,
                claims: ProductClaimsEnum.ORGANIC_BIO,
                definition: 'definition string',
                defrosting_time: null,
                description: 'description equal to client announcement',
                spiciness_level: 0,
                storage_conditions: StorageConditionsEnum.ROOM_TEMPERATURE,
                created_at: '21 decembre 2020',
                instruction: {
                    id: 1,
                    client_local: 'Servir et boire',
                    bc_en: 'Serve and drink',
                    validation_status: ValidationStatusEnum.BC_VALIDATED,
                    validation_fr: true,
                    validation_it: true,
                    validation_es: false,
                },
                announcement: {
                    id: 12,
                    category: { id: 20, childreen: [], name: 'truc much category', criteria_type: CriteriaTypeEnum.DRINK },
                    validation_status: ValidationStatusEnum.FOODEX_VALIDATED,
                    foodex_ok: true,
                    description: 'description announcement',
                    definition: 'definition announcement',
                    announcement_creator: 'Abdel Kader',
                    category_id: 20,
                },
                non_standard_ingredient: 'none',
                category: {
                    id: 1011,
                    name: 'category name',
                    criteria_type: CriteriaTypeEnum.FOOD,
                },
                user: mockUser,
            },
            should_regenerate_video: false,
            video_public_to_client: false,
        },
    ],
};

export const mockResultsAndReportsFilters: IFilterFromDto = {
    'product.claims': {
        type: 'enum',
        values: ['organic_bio', 'gluten_free', 'sugar_free', 'lactose_free', 'low_fat_light', 'low_sugar', 'vegetarian_vegan', 'low_salt'],
        db: ['products.claims', 'in'],
    },
    'product.category': {
        type: 'category',
        values: [], // Lots of values that we don't really use, so we can leave empty for mocks
        db: ['products.category', 'in'],
    },
    'tasting.sta_year': {
        type: 'integer',
        values: [2020, 2021, 2022],
        db: ['sta_year', 'in'],
    },
    'tasting.candidate_award': {
        type: 'enum',
        values: ['crystal', 'diamond', 'absolute'],
        db: ['candidate_award', 'in'],
    },
    'tasting.feedback_only': {
        type: 'boolean',
        db: ['feedback_only', 'equal'],
    },
    'tasting.options': {
        type: 'enum',
        values: ['food_pairing', 'comments_suggestions', 'question'],
        db: ['new_options.option', 'in'],
    },
    'tasting.options_count': {
        type: 'integer',
        values: [0, 1, 2, 3],
        db: [
            {
                '0': 'tastings.id NOT IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) \u003e= 1)',
                '1': 'tastings.id IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) = 1)',
                '2': 'tastings.id IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) = 2)',
                '3': 'tastings.id IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) = 3)',
            },
            'sql',
        ],
    },
    'user.payment_status': {
        type: 'enum',
        values: ['unpaid', 'paid'],
        db: ['users.payment_status', 'in'],
    },
    'user.contact_owner': {
        type: 'contact_owner',
        values: [
            { id: 33857818, name: 'Mohamad Idelbi' },
            { id: 51230200, name: 'Chia-Yi Shih' },
            { id: 33857817, name: 'Ecem Özes' },
            { id: 33857821, name: 'Eunhwa Chae' },
            { id: 33216255, name: 'George Papantonopoulos' },
            { id: 46630180, name: 'Stas Belo' },
            { id: 35835734, name: 'Leticia Zulian' },
            { id: 33858018, name: 'Miki Nakamura' },
            { id: 33953363, name: 'Simone Ievoli' },
            { id: 33858021, name: 'Sara Sánchez González' },
            { id: 50677572, name: 'Moritz Engel' },
            { id: 33858019, name: 'Thamolwan Krittanon' },
            { id: 33857819, name: 'WEIJIE LI' },
            { id: 42408221, name: 'Gabriela Barajas' },
            { id: 33858022, name: 'Satoko Kasperek-Koshiyama' },
        ],
        db: ['users.contact_owner_id', 'in'],
    },
    'user.company_size': {
        type: 'string',
        db: ['users.company_size', 'equal'],
    },
    'user.country': { type: 'string', db: ['users.country', 'in'] },
};

export const mockReportRedactionToolData: IReportRedactionDTOIn = {
    report_redactions: [
        {
            id: 4568,
            product_id: 20338,
            tasting_id: 57520,
            user_id: 1,
            sta_year: 2021,
            comments_suggestions: true,
            food_pairing: true,
            question: true,
            feedback_only: false,
            score: 72,
            star: 1,
            redaction_status: RedactionStatusEnum.NOT_STARTED,
            category: {
                id: 1011,
                name: 'category name',
                criteria_type: CriteriaTypeEnum.FOOD,
            },
            announcement_description:
                "Selezione di piccola pasticceria salata formata da otto ricette che rappresentano sapori caratteristici del territorio italiano. Sono prodotti con l'utilizzo di farine di Grani Antichi italiani e con alcune materie prime D.O.P. e I.G.P.",
        },
        {
            id: 4567,
            product_id: 19735,
            tasting_id: 56727,
            user_id: 1,
            sta_year: 2021,
            comments_suggestions: false,
            food_pairing: false,
            question: false,
            feedback_only: false,
            score: 72,
            star: 1,
            redaction_status: RedactionStatusEnum.NOT_STARTED,
            category: {
                id: 1011,
                name: 'category name',
                criteria_type: CriteriaTypeEnum.FOOD,
            },
            announcement_description:
                "Selezione di piccola pasticceria salata formata da otto ricette che rappresentano sapori caratteristici del territorio italiano. Sono prodotti con l'utilizzo di farine di Grani Antichi italiani e con alcune materie prime D.O.P. e I.G.P.",
        },
        {
            id: 4566,
            product_id: 19708,
            tasting_id: 56690,
            user_id: 1,
            sta_year: 2021,
            comments_suggestions: false,
            food_pairing: false,
            question: false,
            feedback_only: false,
            score: 85,
            star: 2,
            redaction_status: RedactionStatusEnum.UNASSIGNED,
            category: {
                id: 1011,
                name: 'category name',
                criteria_type: CriteriaTypeEnum.FOOD,
            },
            announcement_description:
                "Selezione di piccola pasticceria salata formata da otto ricette che rappresentano sapori caratteristici del territorio italiano. Sono prodotti con l'utilizzo di farine di Grani Antichi italiani e con alcune materie prime D.O.P. e I.G.P.",
        },
        {
            id: 4565,
            product_id: 19709,
            tasting_id: 56691,
            user_id: 1,
            sta_year: 2021,
            comments_suggestions: false,
            food_pairing: false,
            question: false,
            feedback_only: false,
            score: 85,
            star: 2,
            redaction_status: RedactionStatusEnum.READY,
            category: {
                id: 1011,
                name: 'category name',
                criteria_type: CriteriaTypeEnum.FOOD,
            },
            announcement_description:
                "Selezione di piccola pasticceria salata formata da otto ricette che rappresentano sapori caratteristici del territorio italiano. Sono prodotti con l'utilizzo di farine di Grani Antichi italiani e con alcune materie prime D.O.P. e I.G.P.",
        },
    ],
    pagination: {
        next_page: 2,
        current_page: 1,
        prev_page: null,
        total_pages: 225,
        total_items: 4492,
    },
};

export const mockReportRedactionFilters: IFilterFromDto = {
    'tasting.sta_year': {
        type: 'integer',
        values: [2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
        db: ['tastings.sta_year', 'in'],
    },
    'product.category': {
        type: 'category',
        values: [], // Lots of values that we don't really use, so we can leave empty for mocks
        db: ['products.category', 'in'],
    },
    'tasting.options': {
        type: 'enum',
        values: ['food_pairing', 'comments_suggestions', 'question'],
        db: ['new_options.option', 'in'],
    },
    'tasting.feedback_only': {
        type: 'boolean',
        db: ['feedback_only', 'equal'],
    },
    'tasting.past_stars': {
        type: 'enum',
        values: [],
        db: ['tastings.past_stars', 'in'],
    },
    'report_management.redaction_status': {
        type: 'enum',
        values: ['unassigned', 'not_started', 'ongoing', 'ready'],
        db: ['redaction_status', 'in'],
    },
    'report_management.assigned_redactor': {
        type: 'contact_owner',
        values: [
            {
                id: 2037471,
                email: 'rob.desarcy+testtest@gmail.com',
            },
            {
                id: 2037412,
                email: 'laurent.loiseau@intotheweb.be',
            },
            {
                id: 1,
                email: 'itqi.superadmin@adneomdevops.com',
            },
            {
                id: 2037290,
                email: 'robin.desarcy@intotheweb.be',
            },
            {
                id: 2037442,
                email: 'bruno.wernimont+test3@gmail.com',
            },
            {
                id: 2037465,
                email: 'simon.fraipont+bc_super_management@gmail.com',
            },
            {
                id: 2037468,
                email: 'bonos80523@specialistblog.com',
            },
            {
                id: 2037477,
                email: 'laurent@taste-institute.com',
            },
            {
                id: 2037719,
                email: 'jeremy.bruyere@intotheweb.be',
            },
            {
                id: 2037826,
                email: 'laurentvanwassenhove@gmail.com',
            },
            {
                id: 2037944,
                email: 'bruno.wernimont+role1@gmail.com',
            },
            {
                id: 2038059,
                email: 'slo@intotheweb.be',
            },
            {
                id: 2037411,
                email: 'simon.fraipont@intotheweb.be',
            },
            {
                id: 2037786,
                email: 'lxpnra@pussport.com',
            },
        ],
        db: ['report_managements.assigned_redactor_id', 'in'],
    },
    'tasting.options_count': {
        type: 'integer',
        values: [0, 1, 2, 3],
        db: [
            {
                '0': 'tastings.id NOT IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) >= 1)',
                '1': 'tastings.id IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) = 1)',
                '2': 'tastings.id IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) = 2)',
                '3': 'tastings.id IN (SELECT tasting_id FROM new_options WHERE tasting_id = tastings.id GROUP BY tasting_id HAVING COUNT(tasting_id) = 3)',
            },
            'sql',
        ],
    },
};

export const mockReportdetails: IReportDetailsDTOIn = {
    report_id: 4568,
    tasting_id: 57520,
    redaction_status: RedactionStatusEnum.NOT_STARTED,
    redactor_mail: 'redactor@red.com',
    alcohol_level: 30,
    highlighted_comment: '',
    criteria_type: CriteriaTypeEnum.FOOD,
    spiciness_level: 3,
    storage_conditions: StorageConditionsEnum.ROOM_TEMPERATURE,
    announcement_desc:
        "Selezione di piccola pasticceria salata formata da otto ricette che rappresentano sapori caratteristici del territorio italiano. Sono prodotti con l'utilizzo di farine di Grani Antichi italiani e con alcune materie prime D.O.P. e I.G.P.",
    options: {
        comments_suggestions: true,
        food_pairing: true,
        question: true,
    },
    test_comments: [
        {
            cr1: {
                original: 'cr1',
                translated_en: 'cr1 translation',
            },
            cr2: {
                original: 'cr2',
                translated_en: 'cr2 translation',
                pos: 'super pos',
                pos_en: 'super pos en ',
                neg: 'super neg',
                neg_en: 'super neg en',
            },
            cr3: {
                original: 'cr3',
                translated_en: 'cr3 translation',
            },
            cr4: {
                original: 'cr4',
                translated_en: 'cr4 translation',
            },
            cr5: {
                original: 'cr5',
                translated_en: 'cr5 translation',
            },
            overall_comment: {
                original:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                translated_en:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                pos: 'super pos',
                pos_en: 'super pos en ',
                neg: 'super neg',
                neg_en: 'super neg en',
            },
            pairing_suggestion: {
                original: 'pizza, french fries, fish, anything really',
                translated_en: 'pizza, french fries, fish, anything really translation',
            },
            pairing_explanation: {
                original: 'would go with anything',
                translated_en: 'would go with anything translation',
            },
            pairing_suggestion_two: {
                original: 'pizza, french fries, fish, anything really TWO',
                translated_en: 'pizza, french fries, fish, anything really translation TWO',
            },
            pairing_explanation_two: {
                original: 'would go with anything TWO',
                translated_en: 'would go with anything translation TWO',
            },
            specific_question: {
                original: 'réponse question 1',
                translated_en: 'réponse question 1 translation',
            },
            specific_question_two: {
                original: 'réponse question 1',
                translated_en: 'réponse question 2 translation',
            },
            specific_question_three: {
                original: 'réponse question 1',
                translated_en: 'réponse question 3 translation',
            },
            suggestion: {
                original: 'suggestion',
                translated_en: 'suggestion translation',
            },
            tester_name: 'sddsds',
        },
        {
            cr1: {
                original: 'cr1',
                translated_en: 'cr1 translation',
            },
            cr2: {
                original: 'cr2',
                translated_en: 'cr2 translation',
            },
            cr3: {
                original: 'cr3',
                translated_en: 'cr3 translation',
            },
            cr4: {
                original: 'cr4',
                translated_en: 'cr4 translation',
            },
            cr5: {
                original: 'cr5',
                translated_en: 'cr5 translation',
            },
            overall_comment: {
                original:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                translated_en:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            pairing_suggestion: null,
            pairing_explanation: null,
            pairing_suggestion_two: null,
            pairing_explanation_two: null,
            specific_question: null,
            specific_question_two: null,
            specific_question_three: null,
            suggestion: null,
            tester_name: 'bbb',
        },
        {
            cr1: {
                original: 'cr1',
                translated_en: 'cr1 translation',
            },
            cr2: {
                original: 'cr2',
                translated_en: 'cr2 translation',
            },
            cr3: {
                original: 'cr3',
                translated_en: 'cr3 translation',
            },
            cr4: {
                original: 'cr4',
                translated_en: 'cr4 translation',
            },
            cr5: {
                original: 'cr5',
                translated_en: 'cr5 translation',
            },
            overall_comment: {
                original:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                translated_en:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            pairing_suggestion: {
                original: 'pizza, french fries, fish, anything really',
                translated_en: 'pizza, french fries, fish, anything really translation',
            },
            pairing_explanation: {
                original: 'pizza, french fries, fish, anything really',
                translated_en: 'pizza, french fries, fish, anything really translation',
            },
            pairing_suggestion_two: null,
            pairing_explanation_two: null,
            specific_question: {
                original: 'réponse à la première question',
                translated_en: 'réponse à la première question translation',
            },
            specific_question_two: null,
            specific_question_three: null,
            suggestion: null,
            tester_name: 'sddsds',
        },
    ],
    specific_question: 'question 1',
    specific_question_two: 'question 2',
    specific_question_three: 'question 3',
    redactor_content: {
        overall_comment: 'overall comment',
        pairing_explanation: 'pairing explanation',
        specific_question: 'specific question 1',
        specific_question_two: 'specific question 2',
        specific_question_three: 'specific question 3',
        suggestion: 'suggestion',
        cr2: 'cr2',
        cr3: 'cr3',
        cr4: 'cr4',
        cr5: 'cr5',
    },
    cr1: '70',
    cr2: '80',
    cr3: '90',
    cr4: '73',
    cr5: '64',
    final_score: '85',
};

export const mockResultValidationDetails: ITastingScoreComments = {
    id: 2,
    tasting_id: 51633,
    criteria_type: CriteriaTypeEnum.FOOD,
    test_score: {
        id: 4,
        cr1: '90.63',
        cr2: '98.1',
        cr3: '87.5',
        cr4: '84.4',
        cr5: '83.8',
        stars: 2,
        total: '89.5',
    },
    auto_score: {
        id: 5,
        cr1: '89.79',
        cr2: '97.3',
        cr3: '86.7',
        cr4: '83.6',
        cr5: '83.0',
        stars: 2,
        total: '88.7',
    },
    final_score: {
        id: 6,
        cr1: '89.79',
        cr2: '97.3',
        cr3: '86.7',
        cr4: '83.6',
        cr5: '83.0',
        stars: 2,
        total: '88.7',
    },
    test_comments: [
        {
            cr1: {
                original: 'cr1',
                translated_en: 'cr1 translation',
            },
            cr2: {
                original: 'cr2',
                translated_en: 'cr2 translation',
            },
            cr3: {
                original: 'cr3',
                translated_en: 'cr3 translation',
            },
            cr4: {
                original: 'cr4',
                translated_en: 'cr4 translation',
            },
            cr5: {
                original: 'cr5',
                translated_en: 'cr5 translation',
            },
            overall_comment: {
                original:
                    'Original Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                translated_en:
                    'Translated Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            pairing_suggestion: {
                original: 'pizza, french fries, fish, anything really',
                translated_en: 'pizza, french fries, fish, anything really translation',
            },
            pairing_explanation: {
                original: 'would go with anything',
                translated_en: 'would go with anything translation',
            },
            specific_question: {
                original: 'réponse question 1',
                translated_en: 'réponse question 1 translation',
            },
            specific_question_two: {
                original: 'réponse question 1',
                translated_en: 'réponse question 2 translation',
            },
            specific_question_three: {
                original: 'réponse question 1',
                translated_en: 'réponse question 3 translation',
            },
            suggestion: {
                original: 'suggestion',
                translated_en: 'suggestion translation',
            },
            tester_name: 'sddsds',
        },
        {
            cr1: {
                original: 'cr1',
                translated_en: 'cr1 translation',
            },
            cr2: {
                original: 'cr2',
                translated_en: 'cr2 translation',
            },
            cr3: {
                original: 'cr3',
                translated_en: 'cr3 translation',
            },
            cr4: {
                original: 'cr4',
                translated_en: 'cr4 translation',
            },
            cr5: {
                original: 'cr5',
                translated_en: 'cr5 translation',
            },
            overall_comment: {
                original:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                translated_en:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            pairing_suggestion: null,
            pairing_explanation: null,
            specific_question: null,
            specific_question_two: null,
            specific_question_three: null,
            suggestion: null,
            tester_name: 'bbb',
        },
        {
            cr1: {
                original: 'cr1',
                translated_en: 'cr1 translation',
            },
            cr2: {
                original: 'cr2',
                translated_en: 'cr2 translation',
            },
            cr3: {
                original: 'cr3',
                translated_en: 'cr3 translation',
            },
            cr4: {
                original: 'cr4',
                translated_en: 'cr4 translation',
            },
            cr5: {
                original: 'cr5',
                translated_en: 'cr5 translation',
            },
            overall_comment: {
                original:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                translated_en:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            pairing_suggestion: {
                original: 'pizza, french fries, fish, anything really',
                translated_en: 'pizza, french fries, fish, anything really translation',
            },
            pairing_explanation: {
                original: null,
                translated_en: null,
            },
            specific_question: {
                original: 'réponse à la première question',
                translated_en: 'réponse à la première question translation',
            },
            specific_question_two: null,
            specific_question_three: null,
            suggestion: null,
            tester_name: 'sddsds',
        },
    ],
};
