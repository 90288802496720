import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { BaseComponent } from '../../abstracts/base.abstract';
import { FilterItemComponent } from '../../components/filter-item/filter-item.component';
import { BasicInputTypeEnum } from '../../enums/basic-input-type.enum';
import { ICheckboxControlValue } from '../../interfaces/checkbox-input-config.interface';
import { IFilter, IFilterOption } from '../../interfaces/filter.interface';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent extends BaseComponent implements OnInit, OnChanges {
    @ViewChildren(FilterItemComponent) public childFilterItems: QueryList<FilterItemComponent>;

    @Input() public filters$: Observable<IFilter[]>;
    @Input() public appliedFilters$: Observable<IFilter[]>;

    @Input() public appliedTextFilter: string = null;

    @Output() public formReadyEvent = new EventEmitter<FormGroup>();
    @Output() public checkFiltersSubmitEvent = new EventEmitter<IFilter[]>();
    @Output() public filtersResetEvent = new EventEmitter<void>();
    @Output() public filterSearchEvent = new EventEmitter<string>();

    public filtersForm: FormGroup;
    public filters: IFilter[];
    public selectedFilters: IFilter[] = [];

    public formWasInit: boolean = false;

    /* ENUMS */
    public basicInputTypeEnum = BasicInputTypeEnum;

    constructor(public fb: FormBuilder, public store: Store) {
        super();
    }

    public get filtersFG(): FormGroup {
        return this.filtersForm.get('filters') as FormGroup;
    }

    ngOnInit(): void {
        this.createForm();
        this.setupValueChangesSubs();

        this.uns = this.filters$.subscribe((filters: IFilter[]) => {
            if (filters.length && !this.formWasInit) {
                this.filters = filters;
                this.setupForm();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appliedTextFilter && this.filtersForm) {
            this.filtersForm.get('search').patchValue(this.appliedTextFilter, { emitEvent: false });
        }
    }

    public updateFilters(filter: IFilter): void {
        const hasFilterInSelected: boolean = this.selectedFilters.some((selectedFilter: IFilter) => selectedFilter.type === filter.type);

        if (hasFilterInSelected) {
            this.selectedFilters = this.selectedFilters.map((selectedFilter: IFilter) =>
                selectedFilter.type === filter.type ? filter : selectedFilter
            );
        } else {
            this.selectedFilters = [...this.selectedFilters, filter];
        }
        this.selectedFilters = this.selectedFilters.filter((f: IFilter) => f.options.length);
    }

    public onSubmit(): void {
        this.checkFiltersSubmitEvent.emit(this.selectedFilters);
        this.hideFilters();
    }

    public resetFilters(): void {
        this.filtersForm.get('search').reset('');
        this.resetCheckboxes();
        this.hideFilters();
        this.filtersResetEvent.emit();
    }

    public hideFilters(emitter: FilterItemComponent = null): void {
        this.childFilterItems.forEach((filterItem: FilterItemComponent) => {
            if (filterItem === emitter) {
                filterItem.showFilter = !filterItem.showFilter;
            } else {
                filterItem.showFilter = false;
            }
        });
    }

    private setupValueChangesSubs(): void {
        this.uns = this.filtersForm
            .get('search')
            .valueChanges.pipe(debounceTime(1000))
            .subscribe((value: string) => {
                this.filterSearchEvent.emit(value);
            });
    }

    private createForm(): void {
        this.filtersForm = this.fb.group({
            search: this.appliedTextFilter,
            filters: this.fb.group({}),
        });
    }

    private setupForm(): void {
        this.filters.forEach((filter: IFilter) => this.addFiltersToFormGroup(filter));
        this.formWasInit = true;
        this.formReadyEvent.emit(this.filtersForm);
    }

    private addFiltersToFormGroup(filter: IFilter): void {
        const values: ICheckboxControlValue[] = filter.options.map((option: IFilterOption) => ({
            value: option.value,
            label: option.label,
            checked: false,
        }));

        this.filtersFG.addControl(filter.label, this.fb.array(values));
    }

    private resetCheckboxes(): void {
        Object.keys(this.filtersFG.controls).forEach((key: string) => {
            const checkboxFA: FormArray = this.filtersFG.get([key]) as FormArray;

            checkboxFA.controls.forEach((control: AbstractControl) =>
                control.patchValue({ ...control.value, checked: false } as ICheckboxControlValue)
            );
        });
    }
}
