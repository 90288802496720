import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IProfile, IProfileDTOIn } from '../interfaces/profile.interface';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(private http: HttpClient) {}

    public getUser(): Observable<IProfile> {
        return this.http.get<IProfileDTOIn>(environment.itqiApi + SubUrlsEnum.USERS).pipe(map((profile: IProfileDTOIn) => profile.user));
    }

    public updateUser(profile: Partial<IProfile>, id: number): Observable<IProfile> {
        return this.http
            .put<IProfileDTOIn>(environment.itqiApi + SubUrlsEnum.USERS + '/' + id, { user: profile })
            .pipe(map((result: IProfileDTOIn) => result.user));
    }

    public uploadLogo(logo: File, id: number): Observable<IProfile> {
        const headers: HttpHeaders = new HttpHeaders({
            ['Content-Type']: 'multipart/form-data',
        });

        const formData: FormData = new FormData();

        formData.append('user[logo]', logo);

        return this.http
            .put<IProfileDTOIn>(environment.itqiApi + SubUrlsEnum.USERS + '/' + id, formData, { headers })
            .pipe(map((result: IProfileDTOIn) => result.user));
    }

    public removeLogo(id: number): Observable<IProfile> {
        return this.http.delete<IProfile>(environment.itqiApi + SubUrlsEnum.DELETE_LOGO.replace('{{id}}', id.toString()));
    }
}
