import { Component, Input } from '@angular/core';

import { TranslationsTabsEnum, WorkspaceTabsEnum } from '../../enums/tabs.enum';
import { ITab } from '../../utils/tabs.utils';
import { ReportManagementTabsEnum } from './../../enums/tabs.enum';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
    @Input() public selectedTab: WorkspaceTabsEnum | TranslationsTabsEnum | ReportManagementTabsEnum;
    @Input() public tabs: ITab[];

    public showMobile = false;

    public toggleTabs(): void {
        this.showMobile = !this.showMobile;
    }
}
