import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFilter } from '../interfaces/filter.interface';

@Pipe({
    name: 'relevantAppliedFilters',
})
export class RelevantAppliedFiltersPipe implements PipeTransform {
    public transform(appliedFilters$: Observable<IFilter[]>, concernedFilter: IFilter): Observable<IFilter> {
        const relevantFilter$: Observable<IFilter> = appliedFilters$.pipe(
            /*
            I check the type with 'includes' because categories have types that are different for type/mothercat/cat
            So I can differentiate them even though we send the same type to the back (product.category).
            This means a concerned Filter might have a type = product.category##MOTHER_CAT (see pretest service mapPretestFilters method),
            but the appliedFilter 'product.category' is still relevant to it
            */
            map((filters: IFilter[]) => filters.find((filter: IFilter) => concernedFilter.type.includes(filter.type)))
        );

        return relevantFilter$;
    }
}
