import { NonStandardIngredientStatusEnum } from '../enums/non-standard-ingredient-status.enum';
import { IGenericListItem } from '../interfaces/generic-list-item.interface';

export type INonStandardIngredientItem = IGenericListItem<NonStandardIngredientStatusEnum>;

export const nonStandardIngredientList: INonStandardIngredientItem[] = Object.keys(NonStandardIngredientStatusEnum).map(
    (key: string, i: number) => ({
        id: i + 1,
        value: NonStandardIngredientStatusEnum[key as keyof typeof NonStandardIngredientStatusEnum],
        label: `NON_STANDARD_INGREDIENT.${key.toUpperCase()}`,
    })
);
