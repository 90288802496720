import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IAdminRole, IRole } from '../../interfaces/user-rigts.interface';
import { UserRightService } from '../../services/user-rights.service';
import { DeleteAdmin, GetAdmins, GetRightsDetails, UpdateAdminRight } from '../actions/user-right.action';
import { CreateAdmin } from './../actions/user-right.action';

export interface UserRightStateModel {
    admins: IAdminRole[];
    roles: IRole[];
}

@State<UserRightStateModel>({
    name: 'userRight',
    defaults: {
        admins: [],
        roles: [],
    },
})
@Injectable()
export class UserRightState {
    @Selector()
    public static admins(state: UserRightStateModel): IAdminRole[] {
        return state.admins;
    }

    @Selector()
    public static roles(state: UserRightStateModel): IRole[] {
        return state.roles;
    }

    constructor(private userRightService: UserRightService) {}

    @Action(GetAdmins)
    public getAllAdmins(ctx: StateContext<UserRightStateModel>, action: GetAdmins): Observable<IAdminRole[]> {
        const state: UserRightStateModel = ctx.getState();
        if (state.admins.length > 0 && !action.flags.bustCache) {
            return null;
        }

        return this.userRightService.getAdminsRights().pipe(
            tap((result: IAdminRole[]) => {
                ctx.patchState({
                    admins: result,
                });
            })
        );
    }

    @Action(CreateAdmin)
    public createAdmin(ctx: StateContext<UserRightStateModel>, action: CreateAdmin): Observable<void> {
        return this.userRightService
            .createAdmin(action.roleName, action.email)
            .pipe(tap(() => ctx.dispatch(new GetAdmins({ bustCache: true }))));
    }

    @Action(UpdateAdminRight)
    public updateAdminRight(ctx: StateContext<UserRightStateModel>, action: UpdateAdminRight): Observable<IAdminRole> {
        return this.userRightService.updateAdminsRights(action.id, action.roleName).pipe(
            tap((result: IAdminRole) => {
                ctx.setState(
                    patch({
                        admins: updateItem<IAdminRole>((admin: IAdminRole) => admin.email === result.email, result),
                    })
                );
            })
        );
    }

    @Action(GetRightsDetails)
    public getRoles(ctx: StateContext<UserRightStateModel>): Observable<IRole[]> {
        return this.userRightService.getRolesAndRights().pipe(
            tap((result: IRole[]) => {
                ctx.patchState({
                    roles: result,
                });
            })
        );
    }

    @Action(DeleteAdmin)
    public deleteAdmin(ctx: StateContext<UserRightStateModel>, action: DeleteAdmin): Observable<void> {
        return this.userRightService.deleteAdmin(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        admins: removeItem<IAdminRole>((admin: IAdminRole) => admin.id === action.id),
                    })
                );
            })
        );
    }
}
