export enum ValidationStatusEnum {
    NEW = 'new',
    BC_VALIDATED = 'bc_validated',
    FOODEX_VALIDATED = 'foodex_validated',
}

export enum JuryQuestionValidationStatusEnum {
    NEW = 'new',
    CLIENT_CONTENT = 'client_content',
    BC_VALIDATED = 'bc_validated',
    FOODEX_VALIDATED = 'foodex_validated',
}
