import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HeaderErrorEnum } from '../enums/header-errors.enum';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { ILogin, ITokens } from '../interfaces/authentication.interface';
import { IProfile } from '../interfaces/profile.interface';
import { IProfileDTOIn } from './../interfaces/profile.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private http: HttpClient) {}

    public getUser(): Observable<IProfile> {
        return this.http.get<IProfile>(environment.itqiApi + SubUrlsEnum.USER);
    }

    // public updateUser(user: IProfile): Observable<IProfile> {
    //     // return this.http.post<IProfile>(environment.itqiApi + SubUrlsEnum.USER, { user });
    //     return of(user);
    // }

    public login(email: string, password: string): Observable<ILogin> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http.post<ILogin>(environment.itqiApi + SubUrlsEnum.LOGIN, { email, password }, { headers });
    }

    public logout(): Observable<void> {
        return this.http.delete<void>(environment.itqiApi + SubUrlsEnum.LOGOUT);
    }

    public refreshToken(token: string): Observable<ITokens> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http.post<ITokens>(environment.itqiApi + SubUrlsEnum.REFRESH, { refresh_token: token }, { headers });
    }

    public getProfile(): Observable<IProfileDTOIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http.get<IProfileDTOIn>(environment.itqiApi + SubUrlsEnum.PROFILE, { headers });
    }
}
