import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    IBuiltReferentials,
    ICountryResource,
    ICriteriaTitlesResource,
    ILanguageResource,
    IPriceTagResource,
    IScoringIndexResource,
    IStarIntervalResource,
    IStaYearResource,
} from '../../interfaces/referentials.interface';
import { ReferentialsService } from './../../services/referentials.service';
import { BootstrapReferentials, GetAllReferentials, GetStaYearList } from './../actions/referentials.actions';

export interface ReferentialsStateModel {
    languages: ILanguageResource[];
    priceTagResources: IPriceTagResource[];
    countryResources: ICountryResource[];
    criteriaTitlesResources: ICriteriaTitlesResource[];
    starIntervalResource: IStarIntervalResource;
    scoringResource: IScoringIndexResource;
    staYearResource: IStaYearResource;
    staYears: number[];
    fetched: boolean;
}

@State<ReferentialsStateModel>({
    name: 'Referentials',
    defaults: {
        languages: [],
        priceTagResources: [],
        countryResources: [],
        criteriaTitlesResources: [],
        starIntervalResource: {
            id: null,
            star1: null,
            star2: null,
            star3: null,
        },
        scoringResource: {
            id: null,
            new: null,
            up: null,
            down: null,
        },
        staYearResource: {
            id: null,
            begin: null,
            end: null,
        },
        staYears: [],
        fetched: false,
    },
})
@Injectable()
export class ReferentialsState {
    @Selector()
    public static languages(state: ReferentialsStateModel): ILanguageResource[] {
        return state.languages;
    }

    @Selector()
    public static countries(state: ReferentialsStateModel): ICountryResource[] {
        return state.countryResources;
    }

    @Selector()
    public static criteriaTitles(state: ReferentialsStateModel): ICriteriaTitlesResource[] {
        return state.criteriaTitlesResources;
    }

    @Selector()
    public static starInterval(state: ReferentialsStateModel): IStarIntervalResource {
        return state.starIntervalResource;
    }

    @Selector()
    public static scoring(state: ReferentialsStateModel): IScoringIndexResource {
        return state.scoringResource;
    }

    @Selector()
    public static staYear(state: ReferentialsStateModel): IStaYearResource {
        return state.staYearResource;
    }

    @Selector()
    public static staYears(state: ReferentialsStateModel): number[] {
        return state.staYears;
    }

    constructor(private referentialsService: ReferentialsService) {}

    @Action(GetAllReferentials)
    public getAllReferentials(ctx: StateContext<ReferentialsStateModel>): Observable<IBuiltReferentials> {
        return this.referentialsService.getAllReferentials().pipe(
            tap((result: IBuiltReferentials) => {
                const {
                    languages,
                    priceTagResources,
                    countryResources,
                    criteriaTitlesResources,
                    starIntervalResource,
                    scoringResource,
                    staYearResource,
                } = result;

                const sortedCountries: ICountryResource[] = this.getSortedCountries(countryResources);

                ctx.patchState({
                    languages,
                    priceTagResources,
                    countryResources: sortedCountries,
                    criteriaTitlesResources,
                    starIntervalResource,
                    scoringResource,
                    staYearResource,
                });
            })
        );
    }

    @Action(BootstrapReferentials, { cancelUncompleted: true })
    public bootstrapReferentials(ctx: StateContext<ReferentialsStateModel>): Observable<void> {
        const state: ReferentialsStateModel = ctx.getState();

        if (state.fetched) {
            return null;
        }

        return ctx.dispatch([new GetAllReferentials(), new GetStaYearList()]).pipe(tap(() => ctx.patchState({ fetched: true })));
    }

    @Action(GetStaYearList)
    public getAllStaYears(ctx: StateContext<ReferentialsStateModel>): Observable<number[]> {
        return this.referentialsService.getAllStaYears().pipe(
            tap((result: number[]) => {
                ctx.patchState({
                    staYears: result.sort((a: number, b: number) => a - b),
                });
            })
        );
    }

    private getSortedCountries(countries: ICountryResource[]): ICountryResource[] {
        return countries.sort((a: ICountryResource, b: ICountryResource) => a.name.localeCompare(b.name));
    }
}
