import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { HeaderErrorEnum } from '../enums/header-errors.enum';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import {
    IPlanningCreationPayload,
    IPlanningDetails,
    IPlanningDetailsDTOIn,
    IPlanningDispatchPayload,
    IPlanningIndex,
    IPlanningIndexDTOIn,
    IPlanningReportPayload,
    IPlanningUpdatePayload,
} from './../interfaces/planning.interface';

@Injectable({ providedIn: 'root' })
export class PlanningService {
    constructor(private http: HttpClient) {}

    public create(planning: IPlanningCreationPayload): Observable<IPlanningDetails> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .post<IPlanningDetailsDTOIn>(environment.itqiApi + SubUrlsEnum.PLANNINGS, { planning }, { headers })
            .pipe(map((result: IPlanningDetailsDTOIn) => result.planning));
    }

    public fetchAll(): Observable<IPlanningIndex[]> {
        return this.http
            .get<IPlanningIndexDTOIn>(environment.itqiApi + SubUrlsEnum.PLANNINGS)
            .pipe(map((result: IPlanningIndexDTOIn) => result.plannings));
    }

    public show(id: number): Observable<IPlanningDetails> {
        return this.http
            .get<IPlanningDetailsDTOIn>(environment.itqiApi + SubUrlsEnum.PLANNINGS + `/${id.toString()}`)
            .pipe(map((result: IPlanningDetailsDTOIn) => result.planning));
    }

    public update(updatePayload: IPlanningUpdatePayload, id: number): Observable<IPlanningDetails> {
        return this.http
            .patch<IPlanningDetailsDTOIn>(environment.itqiApi + SubUrlsEnum.PLANNINGS + `/${id.toString()}`, updatePayload)
            .pipe(map((result: IPlanningDetailsDTOIn) => result.planning));
    }

    public dispatch(dispatchPayload: IPlanningDispatchPayload, id: number): Observable<void> {
        return this.http.put<void>(environment.itqiApi + SubUrlsEnum.DISPATCH_PLANNING.replace('{{id}}', id.toString()), dispatchPayload);
    }

    public delete(id: number): Observable<void> {
        return this.http.delete<void>(environment.itqiApi + SubUrlsEnum.PLANNINGS + `/${id.toString()}`);
    }

    public getReport(payload: IPlanningReportPayload): void {
        let urlEnding: string = `/${payload.planningId}`;
        urlEnding += payload.day ? `?day=${payload.day}` : '';
        urlEnding += payload.group ? `&group=${payload.group}` : '';

        window.open(environment.itqiFiles + SubUrlsEnum.PLANNING_REPORTS + urlEnding);
    }
}
