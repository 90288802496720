export enum ProductClaimsEnum {
    ORGANIC_BIO = 'organic_bio',
    GLUTEN_FREE = 'gluten_free',
    SUGAR_FREE = 'sugar_free',
    LACTOSE_FREE = 'lactose_free',
    LOW_FAT_LIGHT = 'low_fat_light',
    LOW_SUGAR = 'low_sugar',
    VEGETARIAN_VEGAN = 'vegetarian_vegan',
    LOW_SALT = 'low_salt',
}
