export enum InvoiceSortConditionEnum {
    ID = 'invoice.id',
    DOCUMENT_NUMBER = 'invoice.id',
    STA_YEAR = 'invoice.sta_year',
    DATE = 'invoice.created_at',
    DOCUMENT_TYPE = 'invoice.invoice_type',
    USER_ID = 'invoice.user_id',
    COUNTRY = 'invoice.country',
    COMPANY_NAME = 'invoice.company_name',
    TOTAL_AMOUNT = 'invoice.amount',
    DISCOUNT = 'invoice.discount_htva',
    DISCOUNT_ID = 'discount.id',
    DISCOUNT_DESC = 'discount.description',
    PAYMENT_METHOD = 'invoice.payment_method',
}

export enum InvoiceFiltersTypeEnum {
    INVOICE_TYPE = 'invoice.invoice_type',
    STA_YEAR = 'invoice.sta_year',
    DISCOUNTED = 'invoice.discounted',
    DISCOUNT_CODE = 'discount.code',
    CONTACT_OWNER = 'user.contact_owner',
    PAYMENT_METHOD = 'invoice.payment_method',
}
