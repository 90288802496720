import { AwardsEnum } from './../enums/awards.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IAwardItem = IGenericListItem<AwardsEnum>;

export const awardList: IAwardItem[] = [
    {
        id: 1,
        value: AwardsEnum.CRYSTAL,
        label: 'AWARD.CRYSTAL',
    },
    {
        id: 2,
        value: AwardsEnum.DIAMOND,
        label: 'AWARD.DIAMOND',
    },
    {
        id: 3,
        value: AwardsEnum.ABSOLUTE,
        label: 'AWARD.ABSOLUTE',
    },
];
