import { IDiscountDTOOut } from '../../interfaces/discounts.interface';

export class GetAllDiscounts {
    public static readonly type = '[Discount] Get discounts';
}

export class DisableDiscount {
    public static readonly type = '[Discount] Remove discount';
    constructor(public payload: { id: number }) {}
}

export class CreateDiscount {
    public static readonly type = '[Discount] Create discount';
    constructor(public discount: IDiscountDTOOut) {}
}

export class UpdateDiscount {
    public static readonly type = '[Discount] Update discount';
    constructor(public discount: IDiscountDTOOut) {}
}

export class EnableDiscount {
    public static readonly type = '[Discount] Enable discount';
    constructor(public id: number) {}
}

export class SetSortDiscount {
    public static readonly type = '[Discount] Set sort';
    constructor(public sort: string) {}
}
