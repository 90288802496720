import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'customPricing',
})
export class CustomPricingPipe implements PipeTransform {
    public transform(price: number, needCentsFormatting: boolean = false): string {
        if (price === undefined || price === null) {
            return '';
        }

        const isNegative: boolean = price < 0;
        const priceText: string = Math.abs(price).toString();

        let formattedPrice: string = '';

        if (needCentsFormatting) {
            if (priceText.length > 2) {
                const euros: string = priceText.slice(0, priceText.length - 2);
                formattedPrice += euros;
                if (euros.length > 0) {
                    formattedPrice += ',';
                }
            } else {
                formattedPrice += '0,';
            }
            formattedPrice += priceText.padStart(2, '0').slice(-2) + ' €';
        } else {
            formattedPrice += priceText + ' €';
        }

        if (formattedPrice.substring(0, formattedPrice.indexOf(',')).length > 3) {
            formattedPrice = this.addPointSeparator(formattedPrice, formattedPrice.indexOf(',') - 3);
            while (formattedPrice.substring(0, formattedPrice.indexOf('.')).length > 3) {
                formattedPrice = this.addPointSeparator(formattedPrice, formattedPrice.indexOf('.') - 3);
            }
        }

        return (isNegative ? '- ' : '') + formattedPrice;
    }

    private addPointSeparator(str: string, index: number): string {
        return str.substring(0, index) + '.' + str.substring(index, str.length);
    }
}
