import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { IPretestTranslationsDTOIn } from './../interfaces/pretest.interface';
import { mockPretestTranslationsData } from './mock-data';

export const mockPretestTranslationsRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: IPretestTranslationsDTOIn = mockPretestTranslationsData;

    if (req.method === 'GET') {
        const res: any = {
            status: 200,
            body: data,
        };

        return of(new HttpResponse(res));
    }

    if (req.method === 'PUT') {
        const res: any = {
            status: 200,
            body: req.body,
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
