/*
CR1...5 => title depends on food type
FOOD :
    FIRST IMPRESSION
    VISION
    OLFACTION
    TASTE
    TEXTURE
OLIVE OIL:
   FIRST IMPRESSION
   ABSENCE OF FAULTS
   FRUITINESS
   BITTERNESS
   PUNGENCY
DRINK:
    FIRST IMPRESSION
    VISION
    OLFACTION
    TASTE
    FINAL SENSATIONS
*/
export enum ReportDetailsCriteriaEnum {
    GENERAL = 'general',
    CR2 = 'cr2',
    CR3 = 'cr3',
    CR4 = 'cr4',
    CR5 = 'cr5',
}
