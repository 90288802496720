import { Component, Input, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { $ } from 'protractor';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControlComponent } from 'src/shared/abstracts/form-control.abstract';
import { IDateInputConfig } from 'src/shared/interfaces/date-input-config.interface';
import { generateUniqueId } from 'src/shared/utils/utility-functions';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent extends FormControlComponent implements OnInit {
    @Input() public config: IDateInputConfig;

    @Input() public inputId: string;
    public idField: string;
    public labelField: string;
    public placeholder: string;
    private valueChanged = new Subject<any>();

    constructor(@Self() @Optional() public control: NgControl) {
        super();
        this.control.valueAccessor = this;
    }

    ngOnInit(): void {
        this.inputId = this.inputId || generateUniqueId();
        this.idField = this.config.idField || 'id';
        this.labelField = this.config.labelField || 'label';
        this.placeholder = this.config.placeholder || 'Please select an option';

        this.valueChanged.pipe(debounceTime(1000)).subscribe({
            next: (v) => this.onChange(v),
        });
    }

    public onInput(event: Event): void {
        const value: string = (event.target as HTMLInputElement).value;

        this.valueChanged.next(value);
    }

}
