import { PastStarsEnum } from './../enums/past-stars.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IPastStarItem = IGenericListItem<PastStarsEnum>;

export const pastStarsList: IPastStarItem[] = Object.keys(PastStarsEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: PastStarsEnum[key as keyof typeof PastStarsEnum],
    label: `PAST_STARS.${key}`,
}));
