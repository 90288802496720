import { ITableColumn } from '../interfaces/table-column.interface';

export const invoicesColumns: ITableColumn[] = [
    { field: 'created_at', header: 'INVOICES.TABLE.DATE', checked: true, sortable: true },
    { field: 'id', header: 'INVOICES.TABLE.DOCUMENT_ID', checked: true, sortable: true },
    { field: 'invoice_type', header: 'INVOICES.TABLE.DOCUMENT_TYPE', checked: true, sortable: true },
    { field: 'payment_method', header: 'INVOICES.TABLE.PAYMENT_METHOD', checked: true, sortable: true },
    { field: 'user_id', header: 'INVOICES.TABLE.USER_ID', checked: true, sortable: true },
    { field: 'contact_owner', header: 'INVOICES.TABLE.CONTACT_OWNER', checked: true, sortable: true },
    { field: 'company_name', header: 'INVOICES.TABLE.COMPANY_NAME', checked: true, sortable: true },
    { field: 'amount', header: 'INVOICES.TABLE.TOTAL_AMOUNT', checked: true, sortable: false },
    { field: 'htva_amount', header: 'INVOICES.TABLE.AMOUNT', checked: true, sortable: false },
    { field: 'discount_amount', header: 'INVOICES.TABLE.DISCOUNT', checked: true, sortable: false },
    { field: 'discount_description', header: 'INVOICES.TABLE.DISCOUNT_DESC', checked: true, sortable: true },
    { field: 'pdf_path', header: 'INVOICES.TABLE.PDF', checked: true, sortable: false },
];

export const frozenColumns: ITableColumn[] = [{ field: 'sta_year', header: 'INVOICES.TABLE.STA_YEAR', checked: true, sortable: true }];
