import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IExportColumn } from '../interfaces/export-column.interface';
import { IFilter, IFilterFromDto, IFilterOption, IFilterToDto } from '../interfaces/filter.interface';
import { IInvoiceQueryOptionsDTO, IInvoicesDTOIn, IInvoiceTableData } from '../interfaces/invoices.interface';
import { mapFiltersToDTO } from '../utils/utility-functions';
import { InvoiceExportFieldsEnum } from './../enums/invoice-export.enum';
import { InvoiceFiltersTypeEnum } from './../enums/invoice-filters.enum';
import { ExportEnum } from './../enums/invoices.enum';
import { ICreditNoteDTOOut, IInvoiceDTOOut, IInvoiceQueryOptions, IPaymentDTOOut } from './../interfaces/invoices.interface';

@Injectable({
    providedIn: 'root',
})
export class InvoicesService {
    constructor(private http: HttpClient, private translateService: TranslateService) {}

    public getAllInvoices(queryOptions: IInvoiceQueryOptions): Observable<IInvoicesDTOIn> {
        const body: IInvoiceQueryOptionsDTO = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IInvoicesDTOIn>(environment.itqiApi + SubUrlsEnum.INVOICES, body, {
            params: queryOptions.textFilter ? params : null,
        });
    }

    public createInvoice(invoice: IInvoiceDTOOut): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.CREATE_INVOICE, invoice);
    }

    public createCreditNote(creditNote: ICreditNoteDTOOut): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.CREATE_CREDIT_NOTE, creditNote);
    }

    public createPayment(payment: IPaymentDTOOut): Observable<void> {
        return this.http.post<void>(environment.itqiApi + SubUrlsEnum.CREATE_PAYMENT, payment);
    }

    public getFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.INVOICES_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.orderFilters(this.parseFilter(result))));
    }

    public export(queryOptions: IInvoiceQueryOptions, type: ExportEnum): Observable<string> {
        const activeFilters: IFilterToDto[] = mapFiltersToDTO(queryOptions.filters);
        const activeSort: string = queryOptions.sortCondition;
        const textFilter: string = queryOptions.textFilter;
        const formattedColumns: IExportColumn[] = this.getExportColumns(type);

        const subUrl: string = type === ExportEnum.INVOICE ? SubUrlsEnum.EXPORT_INVOICES : SubUrlsEnum.EXPORT_CREDIT_NOTES;

        return this.http.post(
            environment.itqiApi + subUrl,
            {
                sort: activeSort,
                filters: activeFilters,
                q: textFilter,
                columns: formattedColumns,
            },
            { responseType: 'text' }
        );
    }

    public exportClientBalance(): Observable<string> {
        return this.http.get(environment.itqiApi + SubUrlsEnum.EXPORT_BALANCE, { responseType: 'text' });
    }

    public downloadPDF(invoice: IInvoiceTableData): Observable<Blob> {
        return this.http
            .get(environment.itqiFiles + invoice.pdf_path, { responseType: 'blob' })
            .pipe(tap((pdf: Blob) => saveAs(pdf, `${invoice.invoice_type}-${invoice.id}`)));
    }

    private parseFilter(filters: IFilterFromDto): IFilter[] {
        const parsedFilters: IFilter[] = [];
        for (const [key, value] of Object.entries(filters)) {
            const options: IFilterOption[] = [];
            if (key === 'user.contact_owner') {
                value.values.forEach((v: any) => {
                    options.push({
                        label: v.name,
                        value: v.id,
                    });
                });
            } else if (key === 'discount.code') {
                value.values.forEach((v: any) => {
                    options.push({
                        label: v,
                        value: v,
                    });
                });
            } else {
                value.values.forEach((v: any) => {
                    options.push({
                        label:
                            Number(v) && Number(v) > 1 ? v.toString() : 'MY_WORKSPACE.FILTERS.LABELS.INVOICE.' + v.toString().toUpperCase(), // si 0 ou 1 => discount à traduire, si > 1 => sta year
                        value: v,
                    });
                });
            }

            parsedFilters.push({
                type: key,
                label: key.toString().toUpperCase(),
                options,
            });
        }
        return parsedFilters;
    }

    private orderFilters(filters: IFilter[]): IFilter[] {
        if (!filters.length) {
            return filters;
        }
        const orderedFilters: IFilter[] = [];

        orderedFilters.push(filters.find((f: IFilter) => f.type === InvoiceFiltersTypeEnum.STA_YEAR));
        orderedFilters.push(filters.find((f: IFilter) => f.type === InvoiceFiltersTypeEnum.INVOICE_TYPE));
        orderedFilters.push(filters.find((f: IFilter) => f.type === InvoiceFiltersTypeEnum.CONTACT_OWNER));
        orderedFilters.push(filters.find((f: IFilter) => f.type === InvoiceFiltersTypeEnum.DISCOUNTED));
        orderedFilters.push(filters.find((f: IFilter) => f.type === InvoiceFiltersTypeEnum.DISCOUNT_CODE));
        orderedFilters.push(filters.find((f: IFilter) => f.type === InvoiceFiltersTypeEnum.PAYMENT_METHOD));

        return orderedFilters;
    }

    private getExportColumns(type: ExportEnum): IExportColumn[] {
        return [
            {
                field: InvoiceExportFieldsEnum.ID,
                label: this.translateService.instant(
                    type === ExportEnum.CREDIT_NOTE ? 'INVOICES.EXPORT.CREDIT_NOTE_ID' : 'INVOICES.EXPORT.INVOICE_ID'
                ),
            },
            {
                field: InvoiceExportFieldsEnum.CREATED_AT,
                label: this.translateService.instant(
                    type === ExportEnum.CREDIT_NOTE ? 'INVOICES.EXPORT.CREDIT_NOTE_DATE' : 'INVOICES.EXPORT.INVOICE_DATE'
                ),
            },
            {
                field: InvoiceExportFieldsEnum.USER_ID,
                label: this.translateService.instant('INVOICES.EXPORT.CLIENT_ID'),
            },
            {
                field: InvoiceExportFieldsEnum.XTVA_AMOUNT,
                label: this.translateService.instant('INVOICES.EXPORT.AMOUNT_XTVA'),
            },
            {
                field: InvoiceExportFieldsEnum.VAT_AMOUNT,
                label: this.translateService.instant('INVOICES.EXPORT.VAT_AMOUNT'),
            },
            {
                field: InvoiceExportFieldsEnum.VAT_CODE,
                label: this.translateService.instant('INVOICES.EXPORT.VAT_CODE'),
            },
            {
                field: InvoiceExportFieldsEnum.AMOUNT,
                label: this.translateService.instant('INVOICES.EXPORT.AMOUNT_TOTAL'),
            },
            {
                field: InvoiceExportFieldsEnum.VAT_NUMBER,
                label: this.translateService.instant('INVOICES.EXPORT.VAT_NUMBER'),
            },

            {
                field: InvoiceExportFieldsEnum.BILLING_COMPANY_NAME,
                label: this.translateService.instant('INVOICES.EXPORT.BILLING_NAME'),
            },
            {
                field: InvoiceExportFieldsEnum.BILLING_ADDRESS,
                label: this.translateService.instant('INVOICES.EXPORT.BILLING_ADDRESS'),
            },
            {
                field: InvoiceExportFieldsEnum.BILLING_ZIP,
                label: this.translateService.instant('INVOICES.EXPORT.BILLING_ZIP'),
            },
            {
                field: InvoiceExportFieldsEnum.BILLING_CITY,
                label: this.translateService.instant('INVOICES.EXPORT.BILLING_CITY'),
            },
            {
                field: InvoiceExportFieldsEnum.BILLING_COUNTRY,
                label: this.translateService.instant('INVOICES.EXPORT.BILLING_COUNTRY'),
            },
            {
                field: InvoiceExportFieldsEnum.CONTACT_OWNER,
                label: this.translateService.instant('INVOICES.EXPORT.CONTACT_OWNER'),
            },
            {
                field: InvoiceExportFieldsEnum.STA_YEAR,
                label: this.translateService.instant('INVOICES.EXPORT.STA_YEAR'),
            },
            {
                field: InvoiceExportFieldsEnum.DISCOUNT_AMOUNT,
                label: this.translateService.instant('INVOICES.EXPORT.DISCOUNT_AMOUNT'),
            },
            {
                field: InvoiceExportFieldsEnum.DISCOUNT_DESCRIPTION,
                label: this.translateService.instant('INVOICES.EXPORT.DISCOUNT_DESC'),
            },
            {
                field: InvoiceExportFieldsEnum.COMPANY_SIZE,
                label: this.translateService.instant('INVOICES.EXPORT.COMPANY_SIZE'),
            },
        ];
    }
}
