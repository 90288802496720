import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { frozenColumns, scrollableColumns } from '../../data/new-announcements-columns';
import { AlertTypeEnum } from '../../enums/alert.enum';
import { AnnouncementsSortConditionsEnum } from '../../enums/announcements-filters.enum';
import {
    IAnnouncement,
    IAnnouncementQueryOptions,
    IAnnouncementsDTOIn,
    IAnnouncementTableData,
} from '../../interfaces/announcement.interface';
import { IFilter } from '../../interfaces/filter.interface';
import { AnnouncementsService } from '../../services/announcements.service';
import { criteriaTypeList, ICriteriaTypeItem } from '../../utils/criteria-type.utils';
import { rowPerPageOptions } from '../../utils/table.utils';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { NewAnnouncementsTable } from '../actions/new-announcements-table.actions';
import { SharedTablesSelectors } from '../selectors/shared-tables.selectors';
import { AnnouncementsTableStateModel } from './announcemments-table.state';

const queryOptionsDefaults: IAnnouncementQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    new: true,
    sortCondition: '-' + AnnouncementsSortConditionsEnum.ANNOUNCEMENT_ID,
    filters: [],
    textFilter: '',
};

@State<AnnouncementsTableStateModel>({
    name: 'newAnnouncementsTable',
    defaults: {
        announcements: [],
        tableData: [],
        scrollableColumns: [...scrollableColumns],
        frozenColumns,
        selectedRow: null,
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
    },
})
@Injectable()
export class NewAnnouncementsTableState extends SharedTablesSelectors {
    constructor(private announcementsService: AnnouncementsService) {
        super();
    }

    @Action(NewAnnouncementsTable.GetAll)
    public getAnnouncements(
        ctx: StateContext<AnnouncementsTableStateModel>,
        action: NewAnnouncementsTable.GetAll
    ): Observable<IAnnouncementsDTOIn> {
        ctx.setState(
            patch<AnnouncementsTableStateModel>({
                queryOptions: patch<IAnnouncementQueryOptions>({
                    new: action.type.new,
                }),
            })
        );

        const state: AnnouncementsTableStateModel = ctx.getState();

        return this.announcementsService.getAnnouncements(state.queryOptions).pipe(
            tap((result: IAnnouncementsDTOIn) => {
                const tableData: IAnnouncementTableData[] = this.formatTableData(result.announcements);

                ctx.setState(
                    patch<AnnouncementsTableStateModel>({
                        announcements: result.announcements,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    })
                );
            })
        );
    }

    @Action(NewAnnouncementsTable.SetSelectedRow)
    public setSelectedRow(ctx: StateContext<AnnouncementsTableStateModel>, action: NewAnnouncementsTable.SetSelectedRow): void {
        const state: AnnouncementsTableStateModel = ctx.getState();

        const selectedRow: IAnnouncement =
            state.announcements.find((dataItem: IAnnouncement) => dataItem.id === action.announcementId) ?? null;

        ctx.patchState({ selectedRow });
    }

    @Action(NewAnnouncementsTable.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<AnnouncementsTableStateModel>, action: NewAnnouncementsTable.SetQueryOptions): void {
        const state: AnnouncementsTableStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IAnnouncementQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<AnnouncementsTableStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(NewAnnouncementsTable.GetFilters)
    public getAnnouncementsFilters(ctx: StateContext<AnnouncementsTableStateModel>): Observable<IFilter[]> {
        const state: AnnouncementsTableStateModel = ctx.getState();

        if (state.filtersData.length) {
            return null;
        }
        return this.announcementsService.getAnnouncementFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.setState(
                    patch<AnnouncementsTableStateModel>({
                        filtersData: result,
                    })
                );
            })
        );
    }

    @Action(NewAnnouncementsTable.UpdateColumnPreferences)
    public updateColumnPreferences(
        ctx: StateContext<AnnouncementsTableStateModel>,
        action: NewAnnouncementsTable.UpdateColumnPreferences
    ): void {
        ctx.setState(
            patch<AnnouncementsTableStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(NewAnnouncementsTable.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<AnnouncementsTableStateModel>): void {
        ctx.setState(
            patch<AnnouncementsTableStateModel>({
                scrollableColumns,
            })
        );
    }

    @Action(NewAnnouncementsTable.Update)
    public updateAnnouncement(
        ctx: StateContext<AnnouncementsTableStateModel>,
        action: NewAnnouncementsTable.Update
    ): Observable<IAnnouncement> {
        return this.announcementsService.updateAnnouncement(action.announcement, action.id).pipe(
            tap((result: IAnnouncement) => {
                const formattedAnnouncement: IAnnouncementTableData[] = this.formatTableData([result]);

                ctx.setState(
                    patch<AnnouncementsTableStateModel>({
                        announcements: updateItem<IAnnouncement>((ann: IAnnouncement) => ann.id === result.id, result),
                        tableData: updateItem<IAnnouncementTableData>(
                            (data: IAnnouncementTableData) => data.announcementId === formattedAnnouncement[0].announcementId,
                            formattedAnnouncement[0]
                        ),
                    })
                );

                ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.ANNOUNCEMENT_UPDATE', type: AlertTypeEnum.SUCCESS }));
            })
        );
    }

    @Action(NewAnnouncementsTable.Create)
    public createAnnouncement(ctx: StateContext<AnnouncementsTableStateModel>, action: NewAnnouncementsTable.Create): Observable<unknown> {
        return this.announcementsService
            .createAnnouncement(action.announcement)
            .pipe(
                tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.ANNOUNCEMENT_CREATE', type: AlertTypeEnum.SUCCESS })))
            );
    }

    @Action(NewAnnouncementsTable.ToggleFilters)
    public setFiltersDisplay(ctx: StateContext<AnnouncementsTableStateModel>, action: NewAnnouncementsTable.ToggleFilters): void {
        ctx.setState(
            patch<AnnouncementsTableStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    private formatTableData(data: IAnnouncement[]): IAnnouncementTableData[] {
        return data.map((item: IAnnouncement) => {
            const criteriaType: string = criteriaTypeList.find(
                (type: ICriteriaTypeItem) => type.value === item.category.criteria_type
            ).label;

            return {
                announcementId: item.id,
                criteriaType,
                categoryId: item.category.id,
                categoryName: item.category.name,
                description: item.description ?? null,
                definition: item.definition ?? null,
                announcement_creator: item.announcement_creator ?? null,
                isFoodexValidated: item.foodex_ok,
            };
        });
    }
}
