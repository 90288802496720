import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { IProduct } from '../../interfaces/product.interface';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { ProductService } from './../../services/product.service';
import { Product } from './../actions/product.actions';

export interface ProductStateModel {
    products: IProduct[];
    fetched: boolean;
    product: IProduct;
}

@State<ProductStateModel>({
    name: 'product',
    defaults: {
        products: [],
        fetched: false,
        product: null,
    },
})
@Injectable()
export class ProductState {
    @Selector()
    public static products(state: ProductStateModel): IProduct[] {
        return state.products;
    }

    @Selector()
    public static product(state: ProductStateModel): IProduct {
        return state.product;
    }

    constructor(private productService: ProductService) {}

    @Action(Product.GetAll)
    public getAllProducts(ctx: StateContext<ProductStateModel>): Observable<IProduct[]> {
        const state: ProductStateModel = ctx.getState();

        if (state.fetched) {
            return null;
        }

        return this.productService.getAllProducts().pipe(tap((data: IProduct[]) => ctx.patchState({ products: data, fetched: true })));
    }

    @Action(Product.Update)
    public updateProduct(ctx: StateContext<ProductStateModel>, action: Product.Update): Observable<IProduct> {
        return this.productService
            .updateProduct(action.product, action.id)
            .pipe(tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.PRODUCT_UPDATED', type: AlertTypeEnum.SUCCESS }))));
    }

    @Action(Product.Create)
    public createProduct(ctx: StateContext<ProductStateModel>, action: Product.Create): Observable<IProduct> {
        return this.productService.createProduct(action.product).pipe(
            tap((result: IProduct) => {
                ctx.patchState({
                    product: result,
                });
                ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.PRODUCT_CREATED', type: AlertTypeEnum.SUCCESS }));
            })
        );
    }

    @Action(Product.AssignAnnouncement)
    public assignAnnouncement(ctx: StateContext<ProductStateModel>, action: Product.AssignAnnouncement): Observable<unknown> {
        return this.productService
            .assignAnnouncement(action.productIds, action.announcementId)
            .pipe(
                tap(() => ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.ANNOUNCEMENT_ASSIGNED', type: AlertTypeEnum.SUCCESS })))
            );
    }

    @Action(Product.GetById)
    public getById(ctx: StateContext<ProductStateModel>, action: Product.GetById): Observable<IProduct> {
        return this.productService.getById(action.id).pipe(
            tap((res: IProduct) => {
                ctx.patchState({
                    product: res,
                });
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404) {
                    ctx.patchState({
                        product: null,
                    });
                    return throwError('FORMS.ERROR_FEEDBACK.PRODUCT_NOT_FOUND');
                } else {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ERRORS.GENERIC', type: AlertTypeEnum.ERROR }));
                    return of(null);
                }
            })
        );
    }

    @Action(Product.UpdloadIngredientsList)
    public uploadIngredientsList(ctx: StateContext<ProductStateModel>, action: Product.UpdloadIngredientsList): Observable<void> {
        return this.productService.uploadIngredientsList(action.id, action.ingredientsList).pipe(
            tap(() => {
                ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.INGREDIENTS_LIST_UPLOADED', type: AlertTypeEnum.SUCCESS }));
            })
        );
    }
}
