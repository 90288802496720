import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';

import { FormControlComponent } from '../../../abstracts/form-control.abstract';
import { IRadioInputConfig } from '../../../interfaces/radio-input-config.interface';
import { generateUniqueId } from '../../../utils/utility-functions';

@Component({
    selector: 'app-radio-input',
    templateUrl: './radio-input.component.html',
    styleUrls: ['./radio-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RadioInputComponent,
            multi: true,
        },
    ],
})
export class RadioInputComponent extends FormControlComponent implements OnInit {
    @Input() public configs: IRadioInputConfig[];
    @Input() public nameSuffix: string;

    public inputIds: string[];
    public radioControl: FormControl = new FormControl({ value: null, disabled: this.disabled });

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.inputIds = this.configs.map(() => generateUniqueId());

        this.uns = this.radioControl.valueChanges
            .pipe(
                filter(() => !this.disabled),
                tap((value: any) => {
                    this.markAsTouched();
                    this.onChange(value);
                })
            )
            .subscribe();
    }

    public setDisabledState(disabled: boolean): void {
        super.setDisabledState(disabled);

        if (this.disabled) {
            this.radioControl.disable();
        } else {
            this.radioControl.enable();
        }
    }

    public writeValue(obj: any): void {
        this.radioControl.patchValue(obj, { emitEvent: false });
    }
}
