export enum ReferentialTypeEnum {
    LANGUAGE = 'language',
    COUNTRY = 'country',
    SALUTATION = 'salutation',
    TITLE = 'title',
    PRICE_TAG = 'price_tag',
    SHELF_LIFE = 'shelf_life',
    DEFROSTING_TIME = 'defrosting_time',
    STORAGE_CONDITION = 'storage_condition',
    PRODUCT_CLAIM = 'product_claim',
    QA_SYSTEM = 'qa_system',
    AWARD = 'award',
    SIDE_DISH = 'side_dish',
    MAIN_CONTAINER = 'main_container',
    SIDE_CONTAINER = 'side_container',
    COMPANY_SIZE = 'company_size',
    CRITERIA = 'criteria',
    PARAMETER = 'parameter',
}

export enum ParameterTypeEnum {
    STAR_INTERVAL = 'star_interval',
    SCORING_INDEX = 'scoring_index',
    STAYEAR = 'stayear',
}
