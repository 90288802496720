import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CollapsibleService {
    public activeCollapsible$: Observable<string>;

    private activeCollapsibleIds: string[] = [];
    private activeCollapsibleSubject: Subject<string>;

    constructor() {
        this.activeCollapsibleSubject = new Subject<string>();
        this.activeCollapsible$ = this.activeCollapsibleSubject.asObservable();
    }

    public registerCollapsible(id: string): void {
        this.activeCollapsibleIds = [...this.activeCollapsibleIds, id];
    }

    public unregisterCollapsible(id: string): void {
        this.activeCollapsibleIds = this.activeCollapsibleIds.filter((collapsibleId: string) => id !== collapsibleId);
    }

    public notifyExpansion(id: string): void {
        this.activeCollapsibleSubject.next(id);
    }
}
