import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AlertTypeEnum } from '../../enums/alert.enum';
import { IProfile } from '../../interfaces/profile.interface';
import { AuthenticationService } from '../../services/authentication.service';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { RoleEnum } from './../../enums/user-right.enums';
import { ILogin, ITokens } from './../../interfaces/authentication.interface';
import { IProfileDTOIn } from './../../interfaces/profile.interface';
import { GetProfile, Login, Logout, RefreshToken, SetTokens } from './../actions/auth.actions';

export interface AuthStateModel {
    isAuth: boolean;
    accessToken: string;
    refreshToken: string;
    errors: string[];
    user: IProfile;
    userRole: RoleEnum;
    refreshTimeOutId: ReturnType<typeof setTimeout>;
}
@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        isAuth: false,
        user: null,
        accessToken: '',
        refreshToken: '',
        errors: [],
        userRole: null,
        refreshTimeOutId: null,
    },
})
@Injectable()
export class AuthState {
    @Selector()
    public static isAuthenticated(state: AuthStateModel): boolean {
        return state.isAuth;
    }
    @Selector()
    public static accessToken(state: AuthStateModel): string {
        return state.accessToken;
    }
    @Selector()
    public static refreshToken(state: AuthStateModel): string {
        return state.refreshToken;
    }
    @Selector()
    public static user(state: AuthStateModel): IProfile {
        return state.user;
    }
    @Selector()
    public static userRole(state: AuthStateModel): RoleEnum {
        return state.userRole;
    }

    constructor(private authService: AuthenticationService) {}

    @Action(Login)
    public login(ctx: StateContext<AuthStateModel>, action: Login): Observable<ILogin> {
        return this.authService.login(action.login, action.password).pipe(
            tap((result: ILogin) => {
                if (result.role === RoleEnum.USER) {
                    this.redirectToFrontFacingSite(result);
                    return;
                }

                ctx.patchState({
                    isAuth: true,
                    userRole: result.role,
                    user: result.user,
                    accessToken: result.access_token,
                    refreshToken: result.refresh_token,
                });

                // Reset refreshTimeOut
                this.setRefreshTimeOut(ctx);
            }),
            catchError(() => {
                ctx.dispatch(new FeedbackMessages.Set({ text: 'ERRORS.LOGIN', type: AlertTypeEnum.ERROR }));
                return of(null);
            })
        );
    }

    @Action(Logout)
    public logout(ctx: StateContext<AuthStateModel>): void {
        this.authService.logout();
        ctx.patchState({
            isAuth: false,
            user: null,
            userRole: null,
            accessToken: '',
            refreshToken: '',
        });
    }

    @Action(RefreshToken)
    public refreshToken(ctx: StateContext<AuthStateModel>): Observable<ITokens> {
        const state: AuthStateModel = ctx.getState();

        if (!state.refreshToken) {
            ctx.patchState({
                accessToken: null,
                isAuth: false,
            });
            return null;
        }

        return this.authService.refreshToken(state.refreshToken).pipe(
            tap((result: ITokens) => {
                ctx.patchState({
                    refreshToken: result.refresh_token ? result.refresh_token : state.refreshToken, // AWS renvoie pas un new refresh token pour cet appel
                    accessToken: result.access_token,
                    isAuth: true,
                });

                // Reset refreshTimeOut
                this.setRefreshTimeOut(ctx);
            }),
            catchError((err: HttpErrorResponse) => {
                ctx.patchState({
                    refreshToken: null,
                    accessToken: null,
                    isAuth: false,
                });
                return of(null);
            })
        );
    }

    @Action(GetProfile)
    public getProfile(ctx: StateContext<AuthStateModel>): Observable<IProfileDTOIn> {
        return this.authService.getProfile().pipe(
            tap((result: IProfileDTOIn) => {
                ctx.patchState({
                    user: result.user,
                    userRole: result.role,
                });
            }),
            catchError((err: HttpErrorResponse) => {
                ctx.patchState({
                    user: null,
                    userRole: null,
                });
                return of(null);
            })
        );
    }

    @Action(SetTokens)
    public setTokens(ctx: StateContext<AuthStateModel>, action: SetTokens): void {
        ctx.patchState({
            accessToken: action.accessToken,
            refreshToken: action.refreshToken,
            isAuth: true,
        });
    }

    private setRefreshTimeOut(ctx: StateContext<AuthStateModel>): void {
        const state: AuthStateModel = ctx.getState();

        if (state.refreshTimeOutId) {
            clearTimeout(state.refreshTimeOutId);
        }

        const newTimeOutId: ReturnType<typeof setTimeout> = setTimeout(() => {
            ctx.dispatch(new RefreshToken());
        }, 3590000); // A bit before 1 hour

        ctx.patchState({
            refreshTimeOutId: newTimeOutId,
        });
    }

    private redirectToFrontFacingSite(result: ILogin): void {
        const { access_token: accessToken, refresh_token: refreshToken } = result;
        window.location.href = `${environment.frontFacingSiteUrl}/en/access/login?access_token=${accessToken}&refresh_token=${refreshToken}`;
    }
}
