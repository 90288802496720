import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    frozenPretestTranslationColumns,
    scrollablePretestTranslationColumns,
} from '../../data/pretest-translations-columns';
import { AlertTypeEnum } from '../../enums/alert.enum';
import {
    PretestTranslationsFiltersLabelEnum,
    PretestTranslationsSortConditionsEnum,
} from '../../enums/pretest-translations-filters.enum';
import { SpecificQuestionKeyEnum } from '../../enums/specific-question-key.enum';
import { IFilter } from '../../interfaces/filter.interface';
import {
    IPretestQueryOptions,
    IPretestTranslations,
    IPretestTranslationsDTOIn,
    IPretestTranslationsTableData,
} from '../../interfaces/pretest.interface';
import { PretestService } from '../../services/pretest.service';
import { rowPerPageOptions } from '../../utils/table.utils';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { PretestTranslations } from '../actions/pretest-translations.actions';
import { SharedTablesSelectors } from '../selectors/shared-tables.selectors';
import { TranslationLanguagesEnum } from './../../enums/translation-languages.enum';
import { ISpecificQuestion } from './../../interfaces/specific-questions.interface';
import { TranslationsService } from './../../services/translations.service';
import { TableStateModel } from './table.state';

export interface PretestTranslationsStateModel extends TableStateModel<IPretestTranslationsTableData, IPretestQueryOptions> {
    data: IPretestTranslations[];
    selectedRow: IPretestTranslations;
    modalContext: TranslationLanguagesEnum;
}

const queryOptionsDefaults: IPretestQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    sortCondition: '-' + PretestTranslationsSortConditionsEnum.CREATED_AT,
    filters: [
        {
            type: 'validation_all',
            label: 'TRANSLATIONS.' + PretestTranslationsFiltersLabelEnum.VALIDATION_ALL,
            options: [{ value: false, label: 'MY_WORKSPACE.FILTERS.NOT_OK' }],
        },
    ],
    textFilter: '',
};

@State<PretestTranslationsStateModel>({
    name: 'pretestTranslations',
    defaults: {
        data: [],
        tableData: [],
        scrollableColumns: [...scrollablePretestTranslationColumns],
        frozenColumns: [...frozenPretestTranslationColumns],
        selectedRow: null,
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
        modalContext: null,
    },
})
@Injectable()
export class PretestTranslationsState extends SharedTablesSelectors {
    constructor(private pretestService: PretestService, private translationsService: TranslationsService) {
        super();
    }

    @Action(PretestTranslations.GetData)
    public getData(ctx: StateContext<PretestTranslationsStateModel>): Observable<IPretestTranslationsDTOIn> {
        const state: PretestTranslationsStateModel = ctx.getState();

        return this.pretestService.getPrestestTranslations(state.queryOptions).pipe(
            tap((result: IPretestTranslationsDTOIn) => {
                const tableData: IPretestTranslationsTableData[] = this.formatTableData(result.tastings);
                ctx.patchState({
                    data: result.tastings,
                    tableData,
                    totalRecords: result.pagination.total_items,
                });
            })
        );
    }

    @Action(PretestTranslations.SetSelectedRow)
    public setSelectedRow(ctx: StateContext<PretestTranslationsStateModel>, action: PretestTranslations.SetSelectedRow): void {
        const state: PretestTranslationsStateModel = ctx.getState();

        const selectedRow: IPretestTranslations =
            state.data.find((dataItem: IPretestTranslations) => dataItem.id === action.tastingId) ?? null;

        ctx.patchState({ selectedRow });
    }

    @Action(PretestTranslations.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<PretestTranslationsStateModel>, action: PretestTranslations.SetQueryOptions): void {
        const state: PretestTranslationsStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IPretestQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<PretestTranslationsStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(PretestTranslations.GetFilters)
    public getFilters(ctx: StateContext<PretestTranslationsStateModel>): Observable<IFilter[]> {
        return this.pretestService.getPrestestTranslationsFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.setState(
                    patch<PretestTranslationsStateModel>({
                        filtersData: result,
                    })
                );
            })
        );
    }

    @Action(PretestTranslations.UpdateColumnPreferences)
    public updateColumnPreferences(
        ctx: StateContext<PretestTranslationsStateModel>,
        action: PretestTranslations.UpdateColumnPreferences
    ): void {
        ctx.setState(
            patch<PretestTranslationsStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(PretestTranslations.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<PretestTranslationsStateModel>): void {
        ctx.setState(
            patch<PretestTranslationsStateModel>({
                scrollableColumns: scrollablePretestTranslationColumns,
            })
        );
    }

    @Action(PretestTranslations.ToggleFilters)
    public toggleFilters(ctx: StateContext<PretestTranslationsStateModel>, action: PretestTranslations.ToggleFilters): void {
        ctx.setState(
            patch<PretestTranslationsStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    @Action(PretestTranslations.SetModalContext)
    public setModalContext(ctx: StateContext<PretestTranslationsStateModel>, action: PretestTranslations.SetModalContext): void {
        ctx.patchState({
            modalContext: action.context,
        });
    }

    @Action(PretestTranslations.Update)
    public update(ctx: StateContext<PretestTranslationsStateModel>, action: PretestTranslations.Update): Observable<unknown> {
        return this.pretestService
            .updatePrestestTranslations(action.tastingToUpdate, action.id)
            .pipe(
                tap(() =>
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.PRETEST_TRANSLATION_UPDATED', type: AlertTypeEnum.SUCCESS }))
                )
            );
    }

    @Action(PretestTranslations.SetAutomaticTranslation)
    public setAutomaticTranslation(
        ctx: StateContext<PretestTranslationsStateModel>,
        action: PretestTranslations.SetAutomaticTranslation
    ): void {
        const tastingTranslationObj: IPretestTranslations = action.tastingTranslation;

        /* Call dispatch only if there is english content and the context language has no content*/
        if (
            tastingTranslationObj?.client_specific_question?.bc_en &&
            !tastingTranslationObj?.client_specific_question?.[`question_${action.language}` as keyof ISpecificQuestion]
        ) {
            ctx.dispatch(
                new PretestTranslations.CallGoogleTradService(SpecificQuestionKeyEnum.ONE, tastingTranslationObj, action.language)
            );
        }

        if (
            tastingTranslationObj?.client_specific_question_two?.bc_en &&
            !tastingTranslationObj?.client_specific_question_two?.[`question_${action.language}` as keyof ISpecificQuestion]
        ) {
            ctx.dispatch(
                new PretestTranslations.CallGoogleTradService(SpecificQuestionKeyEnum.TWO, tastingTranslationObj, action.language)
            );
        }

        if (
            tastingTranslationObj?.client_specific_question_three?.bc_en &&
            !tastingTranslationObj?.client_specific_question_three?.[`question_${action.language}` as keyof ISpecificQuestion]
        ) {
            ctx.dispatch(
                new PretestTranslations.CallGoogleTradService(SpecificQuestionKeyEnum.THREE, tastingTranslationObj, action.language)
            );
        }
    }

    @Action(PretestTranslations.CallGoogleTradService)
    public callGoogleTradService(
        ctx: StateContext<PretestTranslationsStateModel>,
        action: PretestTranslations.CallGoogleTradService
    ): Observable<string> {
        const { translationObj, languageContext, questionToUpdate } = action;

        return this.translationsService
            .getTranslation({
                source: 'en',
                target: languageContext,
                text: (translationObj[questionToUpdate] as ISpecificQuestion).bc_en,
            })
            .pipe(
                tap((result: string) => {
                    ctx.setState(
                        patch<PretestTranslationsStateModel>({
                            selectedRow: patch<IPretestTranslations>({
                                [questionToUpdate]: patch<ISpecificQuestion>({
                                    [`question_${languageContext}`]: result,
                                }),
                            }),
                        })
                    );
                })
            );
    }

    private formatTableData(data: IPretestTranslations[]): IPretestTranslationsTableData[] {
        const tableData: IPretestTranslationsTableData[] = [];

        data.forEach((item: IPretestTranslations) => {
            const tableDataItem: IPretestTranslationsTableData = {
                tastingId: item.id,
                productId: item.product_id,
                companyName: item.company_name ?? null,
                categoryName: item.category_name ?? null,
                productName: item.product_name ?? null,
                contactOwner: item.contact_owner ?? null,
                frenchTranslationStatus: item.validation_fr,
                italianTranslationStatus: item.validation_it,
                spanishTranslationStatus: item.validation_es,
                globalTranslationStatus: item.validation_fr && item.validation_it && item.validation_es,
            };

            tableData.push(tableDataItem);
        });

        return tableData;
    }
}
