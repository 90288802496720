import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ISpecificPlanningItem = IGenericListItem<number>;

const specificPlanningItemCount: number = 5;

export const specificPlanningList: ISpecificPlanningItem[] = [...new Array(specificPlanningItemCount)]
    .map((item: void, index: number) => ({
        id: index + 1,
        value: index + 1,
        label: (index + 1).toString(),
    }))
    .concat([{ id: specificPlanningItemCount + 1, value: null, label: 'UTIL.NO_VALUE' }]);
