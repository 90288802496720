import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';

import { componentsShared } from './components';
import { sharedContainers } from './containers';
import { directivesComponents } from './directives';
import { sharedPipes } from './pipes';
import { AnnouncementTranslationsState } from './store/state/announcement-translations.state';
import { AnnouncementsState } from './store/state/announcements.state';
import { AnnouncementsTableState } from './store/state/announcemments-table.state';
import { AuthState } from './store/state/auth.state';
import { CategoryState } from './store/state/category.state';
import { ClientsState } from './store/state/clients.state';
import { DiscountsState } from './store/state/discounts.state';
import { FeedbackMessageState } from './store/state/feedback-message.state';
import { HeaderState } from './store/state/header.state';
import { InvoicesState } from './store/state/invoices.state';
import { LoaderState } from './store/state/loader.state';
import { ModalState } from './store/state/modal.state';
import { NewAnnouncementsTableState } from './store/state/new-announcements-table.state';
import { PlanningState } from './store/state/planning.state';
import { PretestTranslationsState } from './store/state/pretest-translations.state';
import { PretestState } from './store/state/pretest.state';
import { ProductState } from './store/state/product.state';
import { ProfileState } from './store/state/profile.state';
import { ReferentialsState } from './store/state/referentials.state';
import { ReportAssignationState } from './store/state/report-assignation.state';
import { ReportManagementState } from './store/state/report-management.state';
import { ReportRedactionState } from './store/state/report-redaction.state';
import { ResultsAndReportsState } from './store/state/results-and-reports.state';
import { ResultValidationsState } from './store/state/results-validation.state';
import { TastingState } from './store/state/tasting.state';
import { UserRightState } from './store/state/user-right.state';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        LocalizeRouterModule,
        NgxsModule.forFeature([
            HeaderState,
            AuthState,
            ProfileState,
            ReferentialsState,
            ProductState,
            CategoryState,
            PretestState,
            ModalState,
            DiscountsState,
            LoaderState,
            UserRightState,
            InvoicesState,
            AnnouncementsState,
            AnnouncementsTableState,
            NewAnnouncementsTableState,
            PretestTranslationsState,
            AnnouncementTranslationsState,
            ClientsState,
            FeedbackMessageState,
            TastingState,
            PlanningState,
            ResultValidationsState,
            ResultsAndReportsState,
            ReportAssignationState,
            ReportRedactionState,
            ReportManagementState,
        ]),
        ReactiveFormsModule,
    ],
    providers: [],
    declarations: [...componentsShared, ...sharedContainers, ...sharedPipes, ...directivesComponents],
    exports: [...componentsShared, ...sharedContainers, ...sharedPipes, ...directivesComponents, TranslateModule, CommonModule],
})
export class SharedModule {}
