import { createSelector } from '@ngxs/store';

import { IFilter } from '../../interfaces/filter.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { TableStateModel } from '../state/table.state';
import { RoleEnum } from './../../enums/user-right.enums';
import { ITableQueryOptions } from './../../interfaces/table-query-options.interface';
import { AuthState } from './../state/auth.state';

export class SharedTablesSelectors {
    public static tableData<T>(): (state: TableStateModel<T, unknown>) => T[] {
        return createSelector([this], (state: TableStateModel<T, unknown>) => state.tableData);
    }

    public static scrollableColumns(): (state: TableStateModel<unknown, unknown>, userRole: RoleEnum) => ITableColumn[] {
        return createSelector([this, AuthState.userRole], (state: TableStateModel<unknown, unknown>, role: RoleEnum) => {
            if (!role) {
                return [];
            }

            /* if there are no permissions on the col, return true, else check authorisation  */
            return state.scrollableColumns.filter((col: ITableColumn) =>
                col?.colPermission ? col.colPermission.authorisation?.includes(role) : true
            );
        });
    }

    public static checkedColumns(): (cols: ITableColumn[]) => ITableColumn[] {
        return createSelector([this.scrollableColumns()], (cols: ITableColumn[]) => cols.filter((col: ITableColumn) => col.checked));
    }

    public static frozenColumns(): (state: TableStateModel<unknown, unknown>) => ITableColumn[] {
        return createSelector([this], (state: TableStateModel<unknown, unknown>) => state.frozenColumns);
    }

    public static totalRecords(): (state: TableStateModel<unknown, unknown>) => number {
        return createSelector([this], (state: TableStateModel<unknown, unknown>) => state.totalRecords);
    }

    public static rowPerPageOptions(): (state: TableStateModel<unknown, unknown>) => number[] {
        return createSelector([this], (state: TableStateModel<unknown, unknown>) => state.rowPerPageOptions);
    }

    public static queryOptions<T extends ITableQueryOptions>(): (state: TableStateModel<unknown, T>) => T {
        return createSelector([this], (state: TableStateModel<unknown, T>) => state.queryOptions);
    }

    public static appliedFilters<T extends ITableQueryOptions>(): (queryOptions: T) => IFilter[] {
        return createSelector([this.queryOptions()], (queryOptions: T) => queryOptions.filters);
    }

    public static appliedTextFilter<T extends ITableQueryOptions>(): (queryOptions: T) => string {
        return createSelector([this.queryOptions()], (queryOptions: T) => queryOptions.textFilter);
    }

    public static hasAppliedFilters<T extends ITableQueryOptions>(): (queryOptions: T) => boolean {
        return createSelector([this.queryOptions()], (queryOptions: T) => queryOptions.filters.length > 0 || !!queryOptions.textFilter);
    }

    public static showFilters(): (state: TableStateModel<unknown, unknown>) => boolean {
        return createSelector([this], (state: TableStateModel<unknown, unknown>) => state.showFilters);
    }

    public static bareFilters(): (state: TableStateModel<unknown, unknown>) => IFilter[] {
        return createSelector([this], (state: TableStateModel<unknown, unknown>) => state.filtersData);
    }
}
