import { ITableColumn } from '../interfaces/table-column.interface';

export const reportRedactionFrozenColumns: ITableColumn[] = [
    { field: 'tasting_id', header: 'REPORT_REDACTION.TABLE.COL_HEADER.TASTING_ID', checked: true, sortable: true },
    { field: 'user_id', header: 'REPORT_REDACTION.TABLE.COL_HEADER.USER_ID', checked: true, sortable: true },
    { field: 'product_id', header: 'REPORT_REDACTION.TABLE.COL_HEADER.PRODUCT_ID', checked: true, sortable: true },
];

export const reportRedactionColumns: ITableColumn[] = [
    { field: 'sta_year', header: 'REPORT_REDACTION.TABLE.COL_HEADER.STA_YEAR', checked: true, sortable: false },
    { field: 'category_type', header: 'REPORT_REDACTION.TABLE.COL_HEADER.FOOD_DRINK', checked: true, sortable: false },
    { field: 'category_name', header: 'REPORT_REDACTION.TABLE.COL_HEADER.CAT_NAME', checked: true, sortable: false },
    {
        field: 'hasCommentsAndSuggestions',
        header: 'REPORT_REDACTION.TABLE.COL_HEADER.COMMENTS_SUGGESTIONS',
        checked: true,
        sortable: true,
    },
    { field: 'hasFoodPairing', header: 'REPORT_REDACTION.TABLE.COL_HEADER.FOOD_PAIRING', checked: true, sortable: true },
    { field: 'hasQuestions', header: 'REPORT_REDACTION.TABLE.COL_HEADER.SPECIFIC_QUESTIONS', checked: true, sortable: true },
    { field: 'feedback_only', header: 'REPORT_REDACTION.TABLE.COL_HEADER.FEEDBACK_ONLY', checked: true, sortable: false },
    { field: 'score', header: 'REPORT_REDACTION.TABLE.COL_HEADER.SCORE', checked: true, sortable: false },
    { field: 'stars', header: 'REPORT_REDACTION.TABLE.COL_HEADER.STARS', checked: true, sortable: true },
    {
        field: 'redaction_status',
        header: 'REPORT_REDACTION.TABLE.COL_HEADER.REPORT_REDACTION_STATUS',
        checked: true,
        sortable: true,
    },
    { field: 'announcement_desc', header: 'REPORT_REDACTION.TABLE.COL_HEADER.ANNOUNCEMENT_DESC', checked: true, sortable: false },
];
