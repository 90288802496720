import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ParameterTypeEnum, ReferentialTypeEnum } from '../enums/referentials.enum';
import {
    IBuiltReferentials,
    ICountryResource,
    ICriteriaTitlesResource,
    ILanguageResource,
    IPriceTagResource,
    IReferential,
    IReferentialData,
    IReferentialProp,
    IScoringIndexResource,
    IStarIntervalResource,
    IStaYearResource,
} from '../interfaces/referentials.interface';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { IStaYearListDTOIn } from './../interfaces/referentials.interface';

@Injectable({
    providedIn: 'root',
})
export class ReferentialsService {
    constructor(private http: HttpClient) {}

    public getAllReferentials(): Observable<IBuiltReferentials> {
        return this.http.get<IReferentialData>(environment.itqiApi + SubUrlsEnum.REFERENTIALS).pipe(
            map((result: IReferentialData) => {
                const builtReferentials: IBuiltReferentials = this.getbuiltResources(result.data);

                return builtReferentials;
            })
        );
    }

    public getAllStaYears(): Observable<number[]> {
        return this.http
            .get<IStaYearListDTOIn>(environment.itqiApi + SubUrlsEnum.STA_YEAR_LIST)
            .pipe(map((result: IStaYearListDTOIn) => result.sta_years));
    }

    private getbuiltResources(data: IReferential[]): IBuiltReferentials {
        const languages: ILanguageResource[] = [];
        const priceTagResources: IPriceTagResource[] = [];
        const countryResources: ICountryResource[] = [];
        const criteriaTitlesResources: ICriteriaTitlesResource[] = [];
        const starIntervalResource: IStarIntervalResource = {
            id: null,
            star1: null,
            star2: null,
            star3: null,
        };
        const scoringResource: IScoringIndexResource = {
            id: null,
            new: null,
            up: null,
            down: null,
        };
        const staYearResource: IStaYearResource = {
            id: null,
            begin: null,
            end: null,
        };

        data.forEach((ref: IReferential) => {
            switch (ref.type) {
                case ReferentialTypeEnum.LANGUAGE:
                    const lang: ILanguageResource = {
                        id: ref.id,
                        name: ref.value,
                    };
                    languages.push(lang);
                    break;

                case ReferentialTypeEnum.COUNTRY:
                    const country: ICountryResource = {
                        id: ref.id,
                        name: ref.value,
                        isInEU: ref.props[0].value === 'YES',
                    };
                    countryResources.push(country);
                    break;

                case ReferentialTypeEnum.CRITERIA:
                    const crit: ICriteriaTitlesResource = {
                        id: ref.id,
                        type: ref.value,
                        cr1: null,
                        cr2: null,
                        cr3: null,
                        cr4: null,
                        cr5: null,
                    };

                    ref.props.forEach((prop: IReferentialProp) => {
                        if (prop.key === 'cr1') crit.cr1 = prop.value;
                        if (prop.key === 'cr2') crit.cr2 = prop.value;
                        if (prop.key === 'cr3') crit.cr3 = prop.value;
                        if (prop.key === 'cr4') crit.cr4 = prop.value;
                        if (prop.key === 'cr5') crit.cr5 = prop.value;
                    });

                    criteriaTitlesResources.push(crit);
                    break;

                case ReferentialTypeEnum.PARAMETER:
                    if (ref.value === ParameterTypeEnum.STAR_INTERVAL) {
                        starIntervalResource.id = ref.id;

                        ref.props.forEach((prop: IReferentialProp) => {
                            if (prop.key === 'star1') starIntervalResource.star1 = parseInt(prop.value, 10);
                            if (prop.key === 'star2') starIntervalResource.star2 = parseInt(prop.value, 10);
                            if (prop.key === 'star3') starIntervalResource.star3 = parseInt(prop.value, 10);
                        });
                    }

                    if (ref.value === ParameterTypeEnum.SCORING_INDEX) {
                        scoringResource.id = ref.id;

                        ref.props.forEach((prop: IReferentialProp) => {
                            if (prop.key === 'down') scoringResource.down = parseInt(prop.value, 10);
                            if (prop.key === 'new') scoringResource.new = parseInt(prop.value, 10);
                            if (prop.key === 'up') scoringResource.up = parseInt(prop.value, 10);
                        });
                    }

                    if (ref.value === ParameterTypeEnum.STAYEAR) {
                        staYearResource.id = ref.id;

                        ref.props.forEach((prop: IReferentialProp) => {
                            if (prop.key === 'begin') staYearResource.begin = parseInt(prop.value, 10);
                            if (prop.key === 'end') staYearResource.end = parseInt(prop.value, 10);
                        });
                    }
                    break;
            }
        });

        return {
            languages,
            priceTagResources,
            countryResources,
            criteriaTitlesResources,
            starIntervalResource,
            scoringResource,
            staYearResource,
        };
    }
}
