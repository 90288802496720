import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { ClientsService } from '../../services/clients.service';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { IClientDtoIn } from './../../interfaces/client.interface';
import { GetClientById, ResetClient } from './../actions/clients.actions';

export interface ClientsStateModel {
    client: IClientDtoIn;
}
@State<ClientsStateModel>({
    name: 'client',
    defaults: {
        client: null,
    },
})
@Injectable()
export class ClientsState {
    @Selector()
    public static client(state: ClientsStateModel): IClientDtoIn {
        return state.client;
    }

    constructor(private clientService: ClientsService) {}

    @Action(GetClientById)
    public getClientById(ctx: StateContext<ClientsStateModel>, action: GetClientById): Observable<IClientDtoIn> {
        return this.clientService.getUserById(action.payload.id).pipe(
            tap((result: IClientDtoIn) =>
                ctx.patchState({
                    client: result,
                })
            ),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404) {
                    return throwError('FORMS.ERROR_FEEDBACK.USER_NOT_FOUND');
                } else {
                    ctx.dispatch(new FeedbackMessages.Set({ text: 'ERRORS.GENERIC', type: AlertTypeEnum.ERROR }));
                    return of(null);
                }
            })
        );
    }

    @Action(ResetClient)
    public resetClient(ctx: StateContext<ClientsStateModel>): void {
        ctx.patchState({
            client: null,
        });
    }
}
