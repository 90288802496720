import { IResultsAndReportsQueryOptions } from './../../interfaces/results-and-reports.interface';
import { ITableColumn } from './../../interfaces/table-column.interface';
import { ITasting } from './../../interfaces/tasting.interface';

export namespace ResultsAndReports {
    export class GetAll {
        public static readonly type = '[Results and Reports] get data';
    }

    export class Show {
        public static readonly type = '[Results and Reports] show';

        constructor(public id: number) {}
    }

    export class GetFilters {
        public static readonly type = '[Results and Reports] Get filters';
    }

    export class SetQueryOptions {
        public static readonly type = '[Results and Reports] Set query options';

        constructor(
            public options: Partial<IResultsAndReportsQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[Results and Reports] set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[Results and Reports] reset column prefs';
    }

    export class ToggleFilters {
        public static readonly type = '[Results and Reports] Set Results and Reports filters display status';

        constructor(public showFilters: boolean) {}
    }

    export class SetSelectedRow {
        public static readonly type = '[Results and Reports] set row as selected';

        constructor(public tastingId: number) {}
    }

    export class SetMultipleSelectedRows {
        public static readonly type = '[Results and Reports] set multiple rows as selected';

        constructor(public tastingIds: number[]) {}
    }

    export class UpdateTasting {
        public static readonly type = '[Results and Reports] update tasting';

        constructor(public tasting: Partial<ITasting>, public tastingId: number) {}
    }

    export class UploadPackshot {
        public static readonly type = '[Results and Reports] upload packshot';

        constructor(public file: File, public tastingId: number) {}
    }

    export class BustShowCache {
        public static readonly type = '[Results and Reports] bust show cache';
    }

    export class GenerateVideo {
        public static readonly type = '[Results and Reports] generate video';

        constructor(public tastingIds: number[]) {}
    }

    export class GetAvailableYearsForVideoGeneration {
        public static readonly type = '[Results and Reports] get available years for video generation';
    }
}
