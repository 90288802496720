import { IProfile } from '../../interfaces/profile.interface';

export class GetUser {
    public static readonly type = '[Profile] Get User Infos';
}

export class UpdateUser {
    public static readonly type = '[Profile] Update User';

    constructor(public profile: Partial<IProfile>, public userId: number) {}
}

export class UploadLogo {
    public static readonly type = '[Profile] Upload Logo';

    constructor(public logo: File, public userId: number) {}
}

export class RemoveLogo {
    public static readonly type = '[Profile] Remove Logo';

    constructor(public userId: number) {}
}
