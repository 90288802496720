export enum ResultValidationToolFiltersTypeEnum {
    STA_YEAR = 'tasting.sta_year',
    CONTACT_OWNER = 'user.contact_owner',
    TASTING_STATUS = 'tasting.tasting_status',
    TEST_DATE = 'score.test_date',
    CATEGORY = 'product.category',
    CANDIDATE_AWARD = 'tasting.candidate_award',
    TEST_STARS = 'tasting.test_stars',
    PAST_STARS = 'tasting.past_stars',
    FINAL_STARS = 'tasting.final_stars',
    STARS_VAR = 'tasting.stars_var',
    MODIFIED = 'tasting.modified',
    PRESTIGE_AWARD = 'tasting.prestige_award',
    COMPANY_SIZE = 'user.company_size',
    COUNTRY = 'user.country',
}

export enum ResultsValidationToolFiltersLabelEnum {
    STA_YEAR = 'RESULTS_VALIDATION.STA_YEAR',
    CONTACT_OWNER = 'RESULTS_VALIDATION.CONTACT_OWNER',
    TASTING_STATUS = 'RESULTS_VALIDATION.TASTING_STATUS',
    TEST_DATE = 'RESULTS_VALIDATION.TEST_DATE',
    FOOD_DRINK = 'RESULTS_VALIDATION.FOOD_DRINK',
    MOTHER_CAT = 'RESULTS_VALIDATION.MOTHER_CAT',
    CAT = 'RESULTS_VALIDATION.CAT',
    CANDIDATE_AWARD = 'RESULTS_VALIDATION.CANDIDATE_AWARD',
    TEST_STARS = 'RESULTS_VALIDATION.TEST_STAR',
    PAST_STARS = 'RESULTS_VALIDATION.PAST_STARS',
    FINAL_STARS = 'RESULTS_VALIDATION.FINAL_STARS',
    STARS_VAR = 'RESULTS_VALIDATION.STAR_VAR',
    MODIFIED = 'RESULTS_VALIDATION.MODIFIED',
    PRESTIGE_AWARD = 'RESULTS_VALIDATION.PRESTIGE_AWARD',
    COUNTRY = 'RESULTS_VALIDATION.COUNTRY',
    COMPANY_SIZE = 'RESULTS_VALIDATION.COMPANY_SIZE',
    TO_PLAN = 'RESULTS_VALIDATION.TO_PLAN',
    PLANNED = 'RESULTS_VALIDATION.PLANNED',
    TASTED = 'RESULTS_VALIDATION.TASTED',
    SCORE_AND_PUBLISHED = 'RESULTS_VALIDATION.SCORE_AND_PUBLISHED',
}

export enum ResultValdiationToolSortConditionEnum {
    CREATED_AT = 'score.created_at',
    SCORE_ID = 'score.id',
    COMPANY_NAME = 'user.company_name',
    TASTING_ID = 'tasting.id',
    PRODUCT_NAME = 'product.name',
    STA_YEAR = 'tasting.sta_year',
    TASTING_STATUS = 'tasting.tasting_status',
    USER_ID = 'user.id',
    BC_COMMENTS = 'tasting.bc_comments',
    CANDIDATE_AWARD = 'tasting.candidate_award',
    TEST_SCORE = 'test_score.total',
    TEST_STARS = 'test_score.stars',
    PAST_STARS = 'tasting.past_stars',
    AUTO_SCORE = 'auto_score.score',
    SCORE_VAR = 'tasting.score_var',
    FINAL_SCORE = 'final_score.score',
    MODIFIED = 'tasting.modified',
    FINAL_STARS = 'final_score.stars',
    PRESTIGE_AWARD = 'tasting.prestige_award',
    TEST_DATE = 'tasting.test_date',
    CRITERIA_TYPE = 'category.criteria_type',
    CATEGORY_ID = 'category.id',
    CATEGORY_NAME = 'category.name',
    CONTACT_OWNER = 'user.contact_owner',
    COUNTRY = 'user.country',
    LASTNAME = 'user.lastname',
    FIRTSNAME = 'user.firstname',
    EMAIL = 'user.email',
    TASTING_CREATED_AT = 'tasting.created_at',
}
