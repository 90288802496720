import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { IProfile } from '../interfaces/profile.interface';
import { mockUser } from './mock-data';
import { IRes } from './mock-response.interface';

export const mockProfileRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: IProfile = mockUser;

    if (req.method === 'GET') {
        const res: IRes = {
            status: 200,
            body: { user: data },
        };

        return of(new HttpResponse(res));
    }

    if (req.method === 'PUT') {
        const res: IRes = {
            status: 200,
            body: { user: req.body.profile },
        };

        return of(new HttpResponse(res));
    }
    return of(new HttpResponse());
};
