export enum DocumentTypeEnum {
    INVOICE = 'invoice',
    PAYMENT = 'payment',
    CREDIT_NOTE = 'credit_note',
}

export enum PaymentMethodEnum {
    CREDIT_CARD = 'credit_card',
    BANK_TRANSFER = 'bank_transfer',
}

export enum ExportEnum {
    INVOICE = 'invoice',
    CREDIT_NOTE = 'credit_note',
    BALANCE = 'balance',
}
