export class Login {
    public static readonly type = '[Auth] Login';
    constructor(public login: string, public password: string) {}
}

export class Logout {
    public static readonly type = '[Auth] Logout';
}

export class RefreshToken {
    public static readonly type = '[Auth] Refresh Token';
}

export class GetProfile {
    public static readonly type = '[Auth] Get profile';
}

export class SetTokens {
    public static readonly type = '[Auth] Set Tokens';
    constructor(public refreshToken: string, public accessToken: string) {}
}
