export enum ValidationErrorNameEnum {
    PRODUCT_ID_NOT_FOUND = 'PRODUCT_ID_NOT_FOUND',
    USER_ID_NOT_FOUND = 'USER_ID_NOT_FOUND',
    ASSIGN_STA_YEAR_ERROR = 'ASSIGN_STA_YEAR_ERROR',
    CODE_ALREADY_EXIST = 'CODE_ALREADY_EXIST',
    TASTING_ID_NOT_FOUND = 'TASTING_ID_NOT_FOUND',
    TO_MUCH_TASTING = 'TO_MUCH_TASTING',
    CALIBRATION_MIN_MAX = 'CALIBRATION_MIN_MAX',
    EXISTING_PRODUCT_TO_REASSIGN_TO = 'EXISTING_PRODUCT_TO_REASSIGN_TO',
}
