import { ReadinessFilterEnum } from '../enums/readiness_filter.enum';
import { IGenericListItem } from '../interfaces/generic-list-item.interface';

export type IReadinessFilterItem = IGenericListItem<ReadinessFilterEnum>;

export const readinessFilterList: IReadinessFilterItem[] = Object.keys(ReadinessFilterEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: ReadinessFilterEnum[key as keyof typeof ReadinessFilterEnum],
    label: `READINESS_FILTER.${key}`,
}));
