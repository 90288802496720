import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PretestFiltersLabelEnum, PretestFiltersTypeEnum } from '../enums/prestest-filters.enum';
import { IExportColumn } from '../interfaces/export-column.interface';
import { INonStandardIngredientItem, nonStandardIngredientList } from '../utils/non-standard-ingredient.utils';
import { specificPlanningList } from '../utils/specific-planning.utils';
import { ITastingOptionsItem, tastingOptionsList } from '../utils/tasting-options.utils';
import { ITransportConditionsItem, transportConditionsList } from '../utils/transport_conditions.utils';
import { mapFiltersToDTO } from '../utils/utility-functions';
import { PrestestExportFieldsEnum } from './../enums/pretest-export.enum';
import { PretestTranslationsFiltersLabelEnum } from './../enums/pretest-translations-filters.enum';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { ICNCode, ICNCodePayload } from './../interfaces/cn-code.interface';
import { IFilter, IFilterFromDto, IFilterToDto } from './../interfaces/filter.interface';
import {
    IPretestDataDTOIn,
    IPretestQueryOptions,
    IPretestQueryOptionsDTO,
    IPretestTranslations,
    IPretestTranslationsDTOIn,
} from './../interfaces/pretest.interface';
import { IAwardItem, awardList } from './../utils/award.utils';
import { IBooleanItem, booleanOptions } from './../utils/boolean-select.utils';
import { ICategoryTypeItem, categoryTypeList } from './../utils/category-type.utils';
import { IClaimsItem, claimsList } from './../utils/claims.utils';
import { IClientDeliveryInstructionsItem, clientDeliveryInstructionsList } from './../utils/client-delivery-instructions.utils';
import { ICompanySizeItem, companySizeList } from './../utils/company-size.utils';
import { IDefrostingTimesItem, defrostingTimesList } from './../utils/defrosting-times.utils';
import { IDeliveryStatusItem, deliveryStatusList } from './../utils/delivery-status.utils';
import { IEmptyFilterItem, emptyFilterList } from './../utils/empty-filter.utils';
import { IItiClassItem, itiClassList } from './../utils/iti-class-utils';
import { IPastStarItem, pastStarsList } from './../utils/past-stars.utils';
import { IPaymentStatusItem, paymentStatusList } from './../utils/payment-status.utils';
import { ISamplesDeliveredItem, samplesDeliveredList } from './../utils/samples-delivered.utils';
import { ISeasonItem, seasonsList } from './../utils/seasons.utils';
import { ISpecStatusItem, specStatusList } from './../utils/spec-status.utils';
import { ISpecificPlanningItem } from './../utils/specific-planning.utils';
import { ISpicinessItem, spicinessList } from './../utils/spiciness.utils';
import { IStorageConditionItem, storageConditionList } from './../utils/storage-conditions.utils';
import { ITastingStatusItem, tastingStatusList } from './../utils/tasting-status.utils';

@Injectable({
    providedIn: 'root',
})
export class PretestService {
    constructor(private http: HttpClient, private translate: TranslateService) {}

    public getPretestData(queryOptions: IPretestQueryOptions): Observable<IPretestDataDTOIn> {
        const body: IPretestQueryOptionsDTO = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IPretestDataDTOIn>(environment.itqiApi + SubUrlsEnum.PRETEST, body, {
            params: queryOptions.textFilter ? params : null,
        });
    }

    public getPretestFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.PRETEST_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.mapPretestFilters(result)));
    }

    /* PRETEST TRANSLATION METHODS  */
    public getPrestestTranslations(queryOptions: IPretestQueryOptions): Observable<IPretestTranslationsDTOIn> {
        const body: IPretestQueryOptionsDTO = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IPretestTranslationsDTOIn>(environment.itqiApi + SubUrlsEnum.PRETEST_TRANSLATIONS, body, { params });
    }

    public getPrestestTranslationsFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.PRETEST_TRANSLATIONS_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.mapPretestTranslationsFilters(result)));
    }

    public updatePrestestTranslations(translationObject: Partial<IPretestTranslations>, id: number): Observable<unknown> {
        return this.http.put<unknown>(environment.itqiApi + SubUrlsEnum.PRETEST_TRANSLATIONS + `/${id}`, {
            tasting: translationObject,
        });
    }

    public exportPretest(queryOptions: IPretestQueryOptions): Observable<string> {
        const activeFilters: IFilterToDto[] = mapFiltersToDTO(queryOptions.filters);
        const activeSort: string = queryOptions.sortCondition;
        const textFilter: string = queryOptions.textFilter;

        const formattedColumns: IExportColumn[] = this.getExportColumns();

        return this.http.post(
            environment.itqiApi + SubUrlsEnum.PRETEST_EXPORT,
            {
                columns: formattedColumns,
                sort: activeSort,
                filters: activeFilters,
                q: textFilter,
            },
            { responseType: 'text' }
        );
    }

    public getCNCodes(): Observable<ICNCode[]> {
        return this.http.get<ICNCode[]>('assets/data/cn-codes.json').pipe(
            map((codes: ICNCode[]) =>
                codes.map((codeItem: ICNCode) => ({
                    ...codeItem,
                    codes: codeItem.codes.map((c: ICNCodePayload) => ({ ...c, hsCode: c.hsCode.toString() })),
                }))
            )
        );
    }

    private mapPretestFilters(filterObj: IFilterFromDto): IFilter[] {
        const filterArray: IFilter[] = [];

        Object.values(PretestFiltersLabelEnum).forEach((label: string) => {
            switch (label) {
                case PretestFiltersLabelEnum.STA_YEAR:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.STA_YEAR,
                        label,
                        options: filterObj[PretestFiltersTypeEnum.STA_YEAR].values.sort().map((value: number) => ({
                            value,
                            label: value.toString(),
                        })),
                    });
                    break;
                case PretestFiltersLabelEnum.TASTING_STATUS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.TASTING_STATUS,
                        label,
                        options: tastingStatusList.map((t: ITastingStatusItem) => ({ value: t.value, label: t.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.SEASON:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.SEASON,
                        label,
                        options: seasonsList.map((s: ISeasonItem) => ({ value: s.value, label: s.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.INGREDIENTS_LIST:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.INGREDIENTS_LIST,
                        label,
                        options: emptyFilterList.map((e: IEmptyFilterItem) => ({ value: e.value, label: e.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.ITI_CLASS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.ITI_CLASS,
                        label,
                        options: itiClassList.map((iClass: IItiClassItem) => ({ value: iClass.value, label: iClass.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.TRANSPORT_CONDITIONS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.TRANSPORT_CONDITIONS,
                        label,
                        options: transportConditionsList.map((transportCondItem: ITransportConditionsItem) => ({
                            value: transportCondItem.value,
                            label: transportCondItem.label,
                        })),
                    });
                    break;
                case PretestFiltersLabelEnum.FOOD_DRINK:
                    /* Need to add a way to differentiate between category types, or filters will overwrite each other */
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CATEGORY + '##' + PretestFiltersLabelEnum.FOOD_DRINK,
                        label,
                        options: categoryTypeList.map((item: ICategoryTypeItem) => ({ value: item.value, label: item.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.MOTHER_CAT:
                    /* Same as for FOOD_DRINK cats */
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CATEGORY + '##' + PretestFiltersLabelEnum.MOTHER_CAT,
                        label,
                        options: null,
                    });
                    break;
                case PretestFiltersLabelEnum.CAT:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CATEGORY,
                        label,
                        options: null,
                    });
                    break;
                case PretestFiltersLabelEnum.OPTIONS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.OPTIONS,
                        label,
                        options: tastingOptionsList.map((o: ITastingOptionsItem) => ({ value: o.value, label: o.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.FEEDBACK_ONLY:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.FEEDBACK_ONLY,
                        label,
                        options: booleanOptions.map((b: IBooleanItem) => ({ value: b.value, label: b.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.NEW_CLIENT:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.NEW_CLIENT,
                        label,
                        options: [
                            { value: true, label: 'MY_WORKSPACE.PRE_TEST.CLIENT.NEW' },
                            { value: false, label: 'MY_WORKSPACE.PRE_TEST.CLIENT.OLD' },
                        ],
                    });
                    break;
                case PretestFiltersLabelEnum.PAST_STARS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.PAST_STARS,
                        label,
                        options: pastStarsList.map((p: IPastStarItem) => ({ value: p.value, label: p.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.CANDIDATE_AWARD:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CANDIDATE_AWARD,
                        label,
                        options: awardList.map((a: IAwardItem) => ({ value: a.value, label: a.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.PAYMENT_STATUS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.PAYMENT_STATUS,
                        label,
                        options: paymentStatusList.map((p: IPaymentStatusItem) => ({ value: p.value, label: p.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.CLIENT_DELIVERY_INSTRUCTIONS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CLIENT_DELIVERY_INSTRUCTIONS,
                        label,
                        options: clientDeliveryInstructionsList.map((c: IClientDeliveryInstructionsItem) => ({
                            value: c.value,
                            label: c.label,
                        })),
                    });
                    break;
                case PretestFiltersLabelEnum.SAMPLES_DELIVERED:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.SAMPLES_DELIVERED,
                        label,
                        options: samplesDeliveredList.map((s: ISamplesDeliveredItem) => ({ value: s.value, label: s.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.CLIENT_SPECS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CLIENT_SPECS,
                        label,
                        options: specStatusList.map((s: ISpecStatusItem) => ({ value: s.value, label: s.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.BC_SPECS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.BC_SPECS,
                        label,
                        options: specStatusList.map((s: ISpecStatusItem) => ({ value: s.value, label: s.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.CLAIMS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CLAIMS,
                        label,
                        options: claimsList.map((c: IClaimsItem) => ({ value: c.value, label: c.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.STORAGE_CONDITIONS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.STORAGE_CONDITIONS,
                        label,
                        options: [
                            {
                                value: null,
                                label: 'STORAGE_CONDITIONS.NO_VALUE',
                            },
                            ...storageConditionList.map((s: IStorageConditionItem) => ({ value: s.value, label: s.label })),
                        ],
                    });
                    break;
                case PretestFiltersLabelEnum.DEFROSTING_TIME:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.DEFROSTING_TIME,
                        label,
                        options: defrostingTimesList.map((d: IDefrostingTimesItem) => ({ value: d.value, label: d.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.SPICINESS_LEVEL:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.SPICINESS_LEVEL,
                        label,
                        options: spicinessList.map((s: ISpicinessItem) => ({ value: s.value, label: s.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.NON_STANDARD_INGREDIENT:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.NON_STANDARD_INGREDIENT,
                        label,
                        options: nonStandardIngredientList.map((item: INonStandardIngredientItem) => ({
                            value: item.value,
                            label: item.label,
                        })),
                    });
                    break;
                case PretestFiltersLabelEnum.CONTACT_OWNER:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.CONTACT_OWNER,
                        label,
                        options: filterObj[PretestFiltersTypeEnum.CONTACT_OWNER].values.map((contact: { id: number; name: string }) => ({
                            value: contact.id,
                            label: contact.name,
                        })),
                    });
                    break;
                case PretestFiltersLabelEnum.COUNTRY:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.COUNTRY,
                        label,
                        options: null,
                    });
                    break;
                case PretestFiltersLabelEnum.COMPANY_SIZE:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.COMPANY_SIZE,
                        label,
                        options: companySizeList.map((item: ICompanySizeItem) => ({ value: item.name, label: item.size })),
                    });
                    break;
                case PretestFiltersLabelEnum.DELIVERY_STATUS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.DELIVERY_STATUS,
                        label,
                        options: deliveryStatusList.map((item: IDeliveryStatusItem) => ({ value: item.value, label: item.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.SPECIFIC_PLANNING:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.SPECIFIC_PLANNING,
                        label,
                        options: specificPlanningList.map((item: ISpecificPlanningItem) => ({ value: item.value, label: item.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.OPTION_COUNT:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.OPTION_COUNT,
                        label,
                        options: [...new Array(4)].map((item: void, index: number) => ({ value: index, label: index.toString() })),
                    });
                    break;
                case PretestFiltersLabelEnum.ANNOUNCEMENT_READY:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.ANNOUNCEMENT_READY,
                        label,
                        options: [
                            { value: 'ok', label: 'BOOLEAN.TRUE' },
                            { value: 'not_ok', label: 'BOOLEAN.FALSE' },
                        ],
                    });
                    break;
                case PretestFiltersLabelEnum.PLANNING_ID:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.PLANNING_ID,
                        label,
                        options: filterObj[PretestFiltersTypeEnum.PLANNING_ID].values.map((value: string) => ({
                            value,
                            label: value,
                        })),
                    });
                    break;
                case PretestFiltersLabelEnum.PLANNING_DETAILS:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.PLANNING_DETAILS,
                        label,
                        options: filterObj[PretestFiltersTypeEnum.PLANNING_DETAILS].values.map((value: string) => ({
                            value,
                            label: `Day ${value}`,
                        })),
                    });
                    break;
                case PretestFiltersLabelEnum.PACKSHOT:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.PACKSHOT,
                        label,
                        options: emptyFilterList.map((e: IEmptyFilterItem) => ({ value: e.value, label: e.label })),
                    });
                    break;
                case PretestFiltersLabelEnum.LOGO:
                    filterArray.push({
                        type: PretestFiltersTypeEnum.LOGO,
                        label,
                        options: emptyFilterList.map((e: IEmptyFilterItem) => ({ value: e.value, label: e.label })),
                    });
                    break;
            }
        });

        return filterArray;
    }

    private mapPretestTranslationsFilters(filterObj: IFilterFromDto): IFilter[] {
        const filterArray: IFilter[] = [];

        Object.keys(filterObj).forEach((key: string) => {
            switch (key) {
                case PretestTranslationsFiltersLabelEnum.CRITERIA_TYPE.toLowerCase():
                    filterArray.push({
                        type: key,
                        label: 'TRANSLATIONS.' + key.toUpperCase(),
                        options: categoryTypeList.map((item: ICategoryTypeItem) => ({
                            value: item.value.toLowerCase(),
                            label: item.label,
                        })),
                    });
                    break;
                default:
                    filterArray.push({
                        type: key,
                        label: 'TRANSLATIONS.' + key.toUpperCase(),
                        options: filterObj[key].values.map((value: any) => ({
                            value,
                            label: value ? 'MY_WORKSPACE.FILTERS.OK' : 'MY_WORKSPACE.FILTERS.NOT_OK',
                        })),
                    });
            }
        });

        return filterArray;
    }

    private getExportColumns(): IExportColumn[] {
        return [
            {
                field: PrestestExportFieldsEnum.TASTING_ID,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.TASTING_ID'),
            },
            {
                field: PrestestExportFieldsEnum.USER_ID,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.USER_ID'),
            },
            {
                field: PrestestExportFieldsEnum.PRODUCT_ID,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PROD_ID'),
            },
            {
                field: PrestestExportFieldsEnum.COMPANY_NAME,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.COMPANY_NAME'),
            },
            {
                field: PrestestExportFieldsEnum.PRODUCT_NAME,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PROD_NAME'),
            },
            {
                field: PrestestExportFieldsEnum.STA_YEAR,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.STA_YEAR'),
            },
            {
                field: PrestestExportFieldsEnum.CONTACT_OWNER,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CONTACT_OWNER'),
            },
            {
                field: PrestestExportFieldsEnum.STATUS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.TASTING_STATUS'),
            },
            {
                field: PrestestExportFieldsEnum.ORDERDATE,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ORDER_DATE'),
            },
            {
                field: PrestestExportFieldsEnum.SEASON,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SEASON'),
            },
            {
                field: PrestestExportFieldsEnum.INGREDIENTS_LIST,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.INGREDIENTS_LIST'),
            },
            {
                field: PrestestExportFieldsEnum.BC_COMMENTS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.COMMENTS_FIELD'),
            },
            {
                field: PrestestExportFieldsEnum.FOOD_DRINK,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.EXPORT.FOOD_DRINK'),
            },
            { field: PrestestExportFieldsEnum.CATEGORY_ID, label: this.translate.instant('MY_WORKSPACE.PRE_TEST.EXPORT.CATEGORY_ID') },
            {
                field: PrestestExportFieldsEnum.CATEGORY_NAME,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CAT_NAME'),
            },
            {
                field: PrestestExportFieldsEnum.COMMENTS_SUGGESTIONS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.COMMENTS_SUGGESTIONS'),
            },
            {
                field: PrestestExportFieldsEnum.FOOD_PAIRING,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.FOOD_PAIRING'),
            },
            {
                field: PrestestExportFieldsEnum.JURY_QUESTION,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SPECIFIC_QUESTIONS'),
            },
            {
                field: PrestestExportFieldsEnum.FEEDBACK_ONLY,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.FEEDBACK_ONLY'),
            },
            {
                field: PrestestExportFieldsEnum.OPTION_COUNT,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.OPTION_COUNT'),
            },
            {
                field: PrestestExportFieldsEnum.NEW_CLIENT,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.NEW_CLIENT'),
            },
            {
                field: PrestestExportFieldsEnum.PAST_STARS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PAST_STARS'),
            },
            {
                field: PrestestExportFieldsEnum.CANDIDATE_AWARD,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CANDIDATE_AWARD'),
            },
            {
                field: PrestestExportFieldsEnum.PAYMENT_STATUS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PAYMENT_STATUS'),
            },
            {
                field: PrestestExportFieldsEnum.CLIENT_DELIVERY_INSTRUCTIONS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLIENT_DELIVERY_INSTRUCTIONS'),
            },
            {
                field: PrestestExportFieldsEnum.SAMPLE_DELIVERY_INFO,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SAMPLES_DELIVERY_STATUS'),
            },
            {
                field: PrestestExportFieldsEnum.COMMENT_ABOUT_DELIVERY,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DELIVERY_COMMENTS'),
            },
            {
                field: PrestestExportFieldsEnum.DELIVERY_DATE,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DELIVERY_DATE'),
            },
            {
                field: PrestestExportFieldsEnum.DELIVERY_STATUS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DELIVERY_STATUS'),
            },
            {
                field: PrestestExportFieldsEnum.SPECIFIC_PLANNING,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SPECIFIC_PLANNING'),
            },
            {
                field: PrestestExportFieldsEnum.PLANNING_ID,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.EXPORT.PLANNING_ID'),
            },
            {
                field: PrestestExportFieldsEnum.PLANNING_DETAILS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.EXPORT.PLANNING_DETAILS'),
            },
            { field: PrestestExportFieldsEnum.TEST_DATE, label: this.translate.instant('MY_WORKSPACE.PRE_TEST.EXPORT.TEST_DATE') },
            {
                field: PrestestExportFieldsEnum.CLIENT_SPECS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLIENT_SPECS'),
            },
            { field: PrestestExportFieldsEnum.BC_SPECS, label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.BC_SPECS') },
            {
                field: PrestestExportFieldsEnum.ANNOUNCEMENT_ID,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ANNOUNCEMENT_ID'),
            },
            {
                field: PrestestExportFieldsEnum.ANNOUNCEMENT_DESCRIPTION,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ANNOUNCEMENT_DESC'),
            },
            {
                field: PrestestExportFieldsEnum.CLIENT_INSTRUCTION,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLIENT_INSTRUCTIONS'),
            },
            {
                field: PrestestExportFieldsEnum.BC_INSTRUCTION,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.BC_INSTRUCTIONS'),
            },
            {
                field: PrestestExportFieldsEnum.NON_STANDARD_INGREDIENT,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.NON_STANDARD_INGREDIENT'),
            },
            { field: PrestestExportFieldsEnum.CLAIMS, label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLAIMS') },
            {
                field: PrestestExportFieldsEnum.STORAGE_CONDITIONS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.STORAGE_CONDITIONS'),
            },
            {
                field: PrestestExportFieldsEnum.DEFROSTING_TIME,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DEFROSTING_TIME'),
            },
            {
                field: PrestestExportFieldsEnum.ALCOHOL_LEVEL,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ALCOHOL_LEVEL'),
            },
            {
                field: PrestestExportFieldsEnum.SPICINESS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SPICINESS_LEVEL'),
            },
            { field: PrestestExportFieldsEnum.COUNTRY, label: this.translate.instant('MY_WORKSPACE.PRE_TEST.EXPORT.COUNTRY') },
            { field: PrestestExportFieldsEnum.COMPANY_SIZE, label: this.translate.instant('MY_WORKSPACE.PRE_TEST.EXPORT.COMPANY_SIZE') },
            {
                field: PrestestExportFieldsEnum.ITI_CLASS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ITI_CLASS'),
            },
            {
                field: PrestestExportFieldsEnum.SHELF_LIFE,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SHELF_LIFE'),
            },
            {
                field: PrestestExportFieldsEnum.TRANSPORT_CONDITIONS,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.TRANSPORT_CONDITIONS'),
            },
            {
                field: PrestestExportFieldsEnum.CN_CODE,
                label: this.translate.instant('MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CN_CODE'),
            },
        ];
    }
}
