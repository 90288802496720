import { IReportRedactionQueryOptions } from '../../interfaces/report-redaction.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';

export namespace ReportRedaction {
    export class GetFilters {
        public static readonly type = '[Report Redaction] Get filters';
    }

    export class GetAll {
        public static readonly type = '[Report Redaction] Get reports';
    }

    export class UpdateColumnPreferences {
        public static readonly type = '[Report Redaction] Set column prefs';

        constructor(public columns: ITableColumn[]) {}
    }

    export class ResetColumnPreferences {
        public static readonly type = '[Report Redaction] reset column prefs';
    }

    export class SetQueryOptions {
        public static readonly type = '[Report Redaction] Set query options';

        constructor(
            public options: Partial<IReportRedactionQueryOptions>,
            public flags: Record<'resetUnchangedFields', boolean> = { resetUnchangedFields: false }
        ) {}
    }

    export class ToggleFilters {
        public static readonly type = '[Report Redaction] Set filters display status';
        constructor(public showFilters: boolean) {}
    }
}
