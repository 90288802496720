import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-infobox',
    templateUrl: './infobox.component.html',
    styleUrls: ['./infobox.component.scss'],
})
export class InfoboxComponent implements OnInit {
    @Input() public message: string;
    @Input() public infoType: InfoTypesEnum;
    @Input() public containsTradKeys: boolean;

    public infoTypes = InfoTypesEnum;
    public tradKeys: string[] = [];

    ngOnInit(): void {
        if (this.containsTradKeys) {
            this.message.split(' ').forEach((key) => this.tradKeys.push(key));
        }
    }
}

export enum InfoTypesEnum {
    SUCCESS,
    WARNING,
    ERROR,
    INFO,
}
