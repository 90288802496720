import { TransportConditionsEnum } from '../enums/transport_conditions.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type ITransportConditionsItem = IGenericListItem<TransportConditionsEnum>;

export const transportConditionsList: ITransportConditionsItem[] = Object.keys(TransportConditionsEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: key === TransportConditionsEnum.NO_VALUE ? null : TransportConditionsEnum[key as keyof typeof TransportConditionsEnum],
    label: `TRANSPORT_CONDITIONS.${key.toUpperCase()}`,
}));
