export enum DefrostingTimesEnum {
    FEW_MINUTES = 'few_minutes',
    HALF_AN_HOUR = '1_2h',
    ONE_HOUR = '1h',
    TWO_HOURS = '2h',
    FOUR_HOURS = '4h',
    EIGHT_HOURS = '8h',
    HALF_A_DAY = '12h',
    A_DAY = '24h',
    TWO_DAYS = '48h',
}
