import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { PlanningStatusEnum } from '../../enums/planning-status.enum';
import { IPlanningIndex } from '../../interfaces/planning.interface';
import { PlanningService } from '../../services/planning.service';
import { FeedbackMessages } from '../actions/feedback-messages.actions';
import { Planning } from '../actions/planning.actions';
import { IPlanningDetails } from './../../interfaces/planning.interface';

export interface PlanningStateModel {
    plannings: IPlanningIndex[];
    allFetched: boolean;
    currentPlanning: IPlanningDetails;
}

@State<PlanningStateModel>({
    name: 'planning',
    defaults: {
        plannings: [],
        allFetched: false,
        currentPlanning: null,
    },
})
@Injectable()
export class PlanningState {
    @Selector()
    public static plannings(state: PlanningStateModel): IPlanningIndex[] {
        return state.plannings;
    }

    @Selector()
    public static currentPlanning(state: PlanningStateModel): IPlanningDetails {
        return state.currentPlanning;
    }

    @Selector()
    public static undispatchedPlannings(state: PlanningStateModel): IPlanningIndex[] {
        return state.plannings.filter((planning: IPlanningIndex) => planning.status === PlanningStatusEnum.NOT_DISPATCHED);
    }

    constructor(private planningService: PlanningService) {}

    @Action(Planning.Create)
    public create(ctx: StateContext<PlanningStateModel>, action: Planning.Create): Observable<IPlanningDetails> {
        return this.planningService.create(action.creationPayload).pipe(
            tap((result: IPlanningDetails) => {
                const relatedPlanningIndexItem: IPlanningIndex = {
                    id: result.id,
                    name: result.name,
                    number_of_tastings: result.number_of_tastings,
                    status: result.status,
                };

                ctx.setState(
                    patch<PlanningStateModel>({
                        plannings: append<IPlanningIndex>([relatedPlanningIndexItem]),
                    })
                );
            })
        );
    }

    @Action(Planning.FetchAll)
    public fetchAll(ctx: StateContext<PlanningStateModel>): Observable<IPlanningIndex[]> {
        const state: PlanningStateModel = ctx.getState();

        if (state.allFetched) {
            return of(state.plannings);
        }

        return this.planningService.fetchAll().pipe(
            tap((result: IPlanningIndex[]) => {
                ctx.patchState({ plannings: result, allFetched: true });
            })
        );
    }

    @Action(Planning.Show)
    public show(ctx: StateContext<PlanningStateModel>, action: Planning.Show): Observable<IPlanningDetails> {
        return this.planningService.show(action.id).pipe(tap((result: IPlanningDetails) => ctx.patchState({ currentPlanning: result })));
    }

    @Action(Planning.Update)
    public update(ctx: StateContext<PlanningStateModel>, action: Planning.Update): Observable<IPlanningDetails> {
        return this.planningService.update(action.updatePayload, action.id).pipe(
            tap((result: IPlanningDetails) => {
                ctx.setState(
                    patch<PlanningStateModel>({
                        plannings: updateItem<IPlanningIndex>(
                            (planning: IPlanningIndex) => planning.id === action.id,
                            patch<IPlanningIndex>({ number_of_tastings: result.number_of_tastings })
                        ),
                        currentPlanning: result,
                    })
                );

                ctx.dispatch(new FeedbackMessages.Set({ text: 'ALERT.MSG.PLANNING_UPDATED', type: AlertTypeEnum.SUCCESS }));
            })
        );
    }

    @Action(Planning.Dispatch)
    public dispatch(ctx: StateContext<PlanningStateModel>, action: Planning.Dispatch): Observable<void> {
        return this.planningService.dispatch(action.dispatchPayload, action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch<PlanningStateModel>({
                        plannings: updateItem<IPlanningIndex>(
                            (planning: IPlanningIndex) => planning.id === action.id,
                            patch<IPlanningIndex>({ status: PlanningStatusEnum.DISPATCHED })
                        ),
                    })
                );
            })
        );
    }

    @Action(Planning.Delete)
    public delete(ctx: StateContext<PlanningStateModel>, action: Planning.Delete): Observable<void> {
        return this.planningService.delete(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch<PlanningStateModel>({
                        plannings: removeItem<IPlanningIndex>((planning: IPlanningIndex) => planning.id === action.id),
                    })
                );
            })
        );
    }
}
