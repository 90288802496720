import { AlertComponent } from './alert/alert.component';
import { CollapsibleComponent } from './collapsible/collapsible.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FilterItemComponent } from './filter-item/filter-item.component';
import { BasicInputComponent } from './form-controls/basic-input/basic-input.component';
import { CheckboxInputComponent } from './form-controls/checkbox-input/checkbox-input.component';
import { DateInputComponent } from './form-controls/date-input/date-input.component';
import { FileInputComponent } from './form-controls/file-input/file-input.component';
import { GroupSelectInputComponent } from './form-controls/group-select-input/group-select-input.component';
import { RadioInputComponent } from './form-controls/radio-input/radio-input.component';
import { SelectInputComponent } from './form-controls/select-input/select-input.component';
import { TextAreaComponent } from './form-controls/text-area/text-area.component';
import { ValidationFeedbackComponent } from './form-controls/validation-feedback/validation-feedback.component';
import { InfoboxComponent } from './infobox/infobox.component';
import { ModalComponent } from './modal/modal.component';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { TabsComponent } from './tabs/tabs.component';

export const componentsShared: any[] = [
    SelectInputComponent,
    BasicInputComponent,
    TextAreaComponent,
    FileInputComponent,
    RadioInputComponent,
    CheckboxInputComponent,
    InfoboxComponent,
    ModalComponent,
    FilterItemComponent,
    TableActionsComponent,
    TabsComponent,
    ValidationFeedbackComponent,
    DropdownComponent,
    AlertComponent,
    CollapsibleComponent,
    GroupSelectInputComponent,
    DateInputComponent
];

export * from './form-controls/select-input/select-input.component';
export * from './form-controls/basic-input/basic-input.component';
export * from './form-controls/text-area/text-area.component';
export * from './form-controls/file-input/file-input.component';
export * from './form-controls/date-input/date-input.component';
export * from './form-controls/radio-input/radio-input.component';
export * from './form-controls/checkbox-input/checkbox-input.component';
export * from './form-controls/validation-feedback/validation-feedback.component';
export * from './infobox/infobox.component';
export * from './modal/modal.component';
export * from './filter-item/filter-item.component';
export * from './table-actions/table-actions.component';
export * from './tabs/tabs.component';
export * from './alert/alert.component';
export * from './dropdown/dropdown.component';
export * from './collapsible/collapsible.component';
export * from './form-controls/group-select-input/group-select-input.component';
