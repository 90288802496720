import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../shared/abstracts/base.abstract';
import { UtilitiesService } from '../shared/services/internal/utilities.service';
import { SetIsMobile } from '../shared/store/actions/header.actions';
import { BootstrapReferentials } from '../shared/store/actions/referentials.actions';
import { LoaderState } from '../shared/store/state/loader.state';
import { ModalState } from '../shared/store/state/modal.state';
import { AuthState } from './../shared/store/state/auth.state';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
    @Select(ModalState.isModalOpened) public isModalOpen$: Observable<boolean>;
    @Select(LoaderState.isLoading) public isLoading$: Observable<boolean>;
    @Select(AuthState.isAuthenticated) public isAuth$: Observable<boolean>;

    @HostListener('window:resize')
    public resized(): void {
        this.store.dispatch(new SetIsMobile());
    }

    /* Global listener that registers which element was clicked. See dropdown component for use */
    @HostListener('document:click', ['$event'])
    public documentClick(event: PointerEvent): void {
        this.utilitiesService.setTarget(event.target);
    }

    constructor(
        private translate: TranslateService,
        private store: Store,
        private renderer: Renderer2,
        private utilitiesService: UtilitiesService
    ) {
        super();
    }

    ngOnInit(): void {
        this.updateBodyScrollBehaviour();
        this.translate.setDefaultLang('en');

        this.uns = this.isAuth$.subscribe((isAuth: boolean) => {
            if (isAuth) {
                this.store.dispatch(new BootstrapReferentials());
            }
        });
    }

    private updateBodyScrollBehaviour(): void {
        this.uns = combineLatest([this.isModalOpen$, this.isLoading$])
            .pipe(map((values: boolean[]) => values.reduce((acc: boolean, curr: boolean) => acc || curr)))
            .subscribe((hasToPreventScroll: boolean) => {
                if (hasToPreventScroll) {
                    this.renderer.addClass(document.body, 'overflow-hidden');
                } else {
                    this.renderer.removeClass(document.body, 'overflow-hidden');
                }
            });
    }
}
