import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CacheMechanism, LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { TranslateService } from '@ngx-translate/core';

import { featuresWithPermissions } from '../shared/data/permissions';
import { AuthGuard } from '../shared/guards/auth.guard';
import { IFeatureList } from './../shared/interfaces/feature-permissions.interface';

const permissions: IFeatureList = featuresWithPermissions;

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', loadChildren: () => import('../views/auth/auth.module').then((m: any) => m.AuthModule) },
    {
        path: 'confirm-password',
        loadChildren: () => import('../views/confirm-password/confirm-password.module').then((m: any) => m.ConfirmPasswordModule),
    },
    {
        path: 'users-rights',
        loadChildren: () => import('../views/users-rights/users-rights.module').then((m: any) => m.UsersRightsModule),
        canActivate: [AuthGuard],
        data: { feature: permissions.user_right_management_access },
    },
    {
        path: 'profile',
        loadChildren: () => import('../views/my-profile/myprofile.module').then((m: any) => m.MyProfileModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'my-workspace',
        loadChildren: () => import('../views/my-workspace/my-workspace.module').then((m: any) => m.MyWorkspaceModule),
        canActivate: [AuthGuard],
        data: { feature: permissions.pretest_access },
    },
    {
        path: 'discounts',
        loadChildren: () => import('../views/discounts/discounts.module').then((m: any) => m.DiscountModule),
        canActivate: [AuthGuard],
        data: { feature: permissions.discount_access },
    },
    {
        path: 'invoices',
        loadChildren: () => import('../views/invoice-db/invoicedb.module').then((m: any) => m.InvoiceDBModule),
        canActivate: [AuthGuard],
        data: { feature: permissions.invoice_access },
    },
    {
        path: 'translations',
        loadChildren: () => import('../views/translations/translations.module').then((m: any) => m.TranslationsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'planning-overview',
        loadChildren: () => import('../views/planning-overview/planning-overview.module').then((m: any) => m.PlanningOverviewModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'result-validation-tool',
        loadChildren: () =>
            import('../views/result-validation-tool/result-validation.module').then((m: any) => m.ResultValidationToolModule),
        canActivate: [AuthGuard],
        data: { feature: permissions.result_validation_tool },
    },
    {
        path: 'report-management-tool',
        loadChildren: () =>
            import('../views/report-management-tool/report-management-tool.module').then((m: any) => m.ReportManagementToolModule),
        canActivate: [AuthGuard],
    },
    { path: '**', redirectTo: '/login' },
];

export const createLocalizeRouterHttpLoader = (
    translate: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings,
    http: HttpClient
): LocalizeRouterHttpLoader => new LocalizeRouterHttpLoader(translate, location, settings, http);

export const defaultLanguage = (languages: string[], cachedLang?: string, browserLang?: string): string => {
    let result: string = 'en';
    if (cachedLang && languages.indexOf(cachedLang) > -1) {
        result = cachedLang;
    } else if (browserLang && languages.indexOf(browserLang) > -1) {
        result = browserLang;
    }
    return result;
};

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
        LocalizeRouterModule.forRoot(routes, {
            cacheMechanism: CacheMechanism.Cookie,
            cacheName: 'defaultLangITQI',
            alwaysSetPrefix: true,
            defaultLangFunction: defaultLanguage,
            parser: {
                provide: LocalizeParser,
                useFactory: createLocalizeRouterHttpLoader,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
            },
        }),
    ],
    exports: [RouterModule, LocalizeRouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule {}
