export enum RoleEnum {
    NONE = 'none',
    USER = 'user',
    FREELANCE_BASIC = 'freelance_basic',
    BC_BASIC = 'bc_basic',
    FOODEX_PLANNING_REPORTS = 'foodex_planning_reports',
    ADMIN_EXPORTS = 'admin_exports' /* ADMIN EXPORTS = BC IT ADMIN */,
    ACCOUNTING = 'accounting',
    SALES_MANAGEMENT = 'sales_management',
    BC_SUPER_MANAGEMENT = 'bc_super_management',
    EXTERNAL_CP = 'external_cp' /* NEW ROLE */,
}

export enum RightTypeEnum {
    USER_RIGHTS_MANAGEMENT = 'user_right_management',
    PRETEST = 'pretest',
    ANNOUNCEMENTS = 'announcments',
    NEW_ANNOUNCEMENTS = 'new_announcments',
    RESULTS = 'results',
    INVOICE_DB = 'invoice_db',
    DISCOUNTS = 'discounts',
    PLANNING = 'planning',
    RESULT_VALIDATION_TOOL = 'result_validation_tool',
    REPORT_ASSIGNATION_TOOL = 'report_assignation_tool',
    REPORT_REDACTION_TOOL = 'report_redaction_tool',
}

export enum AccessTypeEnum {
    ACCESS = 'access',
    CONSULT = 'consult',
    FRITES = 'frites',
    FOODEX = 'foodex',
    PLAN = 'plan',
    U_P_S = 'u_p_s',
    EXPORT = 'export',
    I_CN_P = 'i_cn_p',
    STA = 'sta',
    EDIT = 'edit',
    TRANSLATIONS = 'translations',
}
