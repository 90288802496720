import { IItiClassEnum } from '../enums/iti-class.enum';
import { IGenericListItem } from './../interfaces/generic-list-item.interface';

export type IItiClassItem = IGenericListItem<IItiClassEnum>;

const itiClassItemCount: number = Object.keys(IItiClassEnum).length;

export const itiClassList: IItiClassItem[] = Object.keys(IItiClassEnum)
    .map((key: string, i: number) => ({
        id: i + 1,
        value: IItiClassEnum[key as keyof typeof IItiClassEnum],
        label: `ITI_CLASS.${key}`,
    }))
    .concat([{ id: itiClassItemCount + 1, value: null, label: 'UTIL.NO_VALUE' }]);
