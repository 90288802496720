import { ITableColumn } from '../interfaces/table-column.interface';

export const scrollableColumns: ITableColumn[] = [
    {
        field: 'public_id',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.TASTING_PUBLIC_ID',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'userId',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.USER_ID',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'productId',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PROD_ID',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'sta_year',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.STA_YEAR',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'contactOwner',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.CONTACT_OWNER',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'companyNameOnCertificate',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.NAME_ON_CERTIF',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'testDate',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.TEST_DATE',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'categoryName',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.CAT_NAME',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'bcComments',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.COMMENT_FIELD',
        sortable: true,
        editable: true,
        checked: true,
    },
    { field: 'stars', header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.STARS', sortable: true, editable: false, checked: true },
    { field: 'scores', header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.SCORE', sortable: true, editable: false, checked: true },
    {
        field: 'prestigeAward',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PRESTIGE_AWARD',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'payment_status',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PAYMENT_STATUS',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'packshot',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PACKSHOT',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'logo',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.LOGO',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'sensoryResultsPDF',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.SENSORY_RESULTS',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'certificate',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.CERTIFICATE',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'resultsPublicToclient',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.RESULTS_PUBLIC',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'videoPublicToClient',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.VIDEO_PUBLIC',
        sortable: false,
        editable: true,
        checked: true,
    },
    {
        field: 'publishedOnTheWeb',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PUBLISHED',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'evaluationReport',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.EVALUATION_REPORT',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'reportPublicToClient',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.REPORT_PUBLIC',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'licenceAgreementValidated',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.LICENCE',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'hasCommentsAndSuggestions',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.COMMENTS_SUGGESTIONS',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'hasFoodPairing',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.FOOD_PAIRING',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'hasQuestions',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.SPECIFIC_QUESTIONS',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'feedback_only',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.FEEDBACK_ONLY',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'image_square',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.IMAGE_SQUARE',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'image_wide',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.IMAGE_WIDE',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'generated_video',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.GENERATED_VIDEO',
        sortable: false,
        editable: false,
        checked: true,
    },
];

export const frozenColumns: ITableColumn[] = [
    { field: 'checkbox', header: '', sortable: false, editable: false, checked: true },
    {
        field: 'tastingId',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.TASTING_ID',
        sortable: true,
        editable: true,
        checked: true,
    },
    { field: 'companyName', header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.COMPANY_NAME', editable: true, checked: true },
    {
        field: 'productName',
        header: 'MY_WORKSPACE.RESULTS_AND_REPORTS.TABLE.COL_HEADER.PROD_NAME',
        sortable: false,
        editable: true,
        checked: true,
    },
];
