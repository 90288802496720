import { IPlanningCreationPayload } from '../../interfaces/planning.interface';
import { IPlanningDispatchPayload, IPlanningUpdatePayload } from './../../interfaces/planning.interface';

export namespace Planning {
    export class Create {
        public static readonly type = '[Planning] Create';

        constructor(public creationPayload: IPlanningCreationPayload) {}
    }

    export class FetchAll {
        public static readonly type = '[Planning] Fetch all';
    }

    export class Show {
        public static readonly type = '[Planning] Show';

        constructor(public id: number) {}
    }

    export class Update {
        public static readonly type = '[Planning] Update';

        constructor(public updatePayload: IPlanningUpdatePayload, public id: number) {}
    }

    export class Dispatch {
        public static readonly type = '[Planning] Dispatch';

        constructor(public dispatchPayload: IPlanningDispatchPayload, public id: number) {}
    }

    export class Delete {
        public static readonly type = '[Planning] Delete';

        constructor(public id: number) {}
    }
}
