import { IGenericListItem } from '../interfaces/generic-list-item.interface';
import { EmptyFilterEnum } from './../enums/empty_filter.enum';

export type IEmptyFilterItem = IGenericListItem<EmptyFilterEnum>;

export const emptyFilterList: IEmptyFilterItem[] = Object.keys(EmptyFilterEnum).map((key: string, i: number) => ({
    id: i + 1,
    value: EmptyFilterEnum[key as keyof typeof EmptyFilterEnum],
    label: `EMPTY_FILTER.${key}`,
}));
