import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ReportRedactionFiltersLabelEnum, ReportRedactionFiltersTypeEnum } from '../enums/report-redaction-filters.enum';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IFilter, IFilterFromDto } from '../interfaces/filter.interface';
import {
    IReportRedactionDTOIn,
    IReportRedactionOptionsDTO,
    IReportRedactionQueryOptions,
} from '../interfaces/report-redaction.interface';
import { categoryTypeList, ICategoryTypeItem } from '../utils/category-type.utils';
import { mapFiltersToDTO } from '../utils/utility-functions';

@Injectable({
    providedIn: 'root',
})
export class ReportRedactionToolService {
    constructor(private http: HttpClient) {}

    public getAll(queryOptions: IReportRedactionQueryOptions): Observable<IReportRedactionDTOIn> {
        const body: IReportRedactionOptionsDTO = {
            page: queryOptions.pageNumber,
            per: queryOptions.countToFetch,
            sort: queryOptions.sortCondition,
            filters: mapFiltersToDTO(queryOptions.filters),
        };

        let params: HttpParams = new HttpParams();

        if (queryOptions.textFilter) {
            params = params.set('q', queryOptions.textFilter);
        }

        return this.http.post<IReportRedactionDTOIn>(environment.itqiApi + SubUrlsEnum.REPORT_REDACTION, body, {
            params: queryOptions.textFilter ? params : null,
        });
    }

    public getFilters(): Observable<IFilter[]> {
        return this.http
            .get<IFilterFromDto>(environment.itqiApi + SubUrlsEnum.REPORT_REDACTION_FILTERS)
            .pipe(map((result: IFilterFromDto) => this.parseFilter(result)));
    }

    private parseFilter(filters: IFilterFromDto): IFilter[] {
        const tradPath: string = 'MY_WORKSPACE.FILTERS.LABELS.REPORT_MANAGEMENT.';

        const parsedFilters: IFilter[] = [];

        for (const [key, value] of Object.entries(filters)) {
            switch (key) {
                case ReportRedactionFiltersTypeEnum.STA_YEAR:
                    parsedFilters.push({
                        type: key,
                        label: ReportRedactionFiltersLabelEnum.STA_YEAR,
                        options: value.values.sort().map((year: number) => ({ value: year, label: year.toString() })),
                    });
                    break;
                case ReportRedactionFiltersTypeEnum.FEEDBACK_ONLY:
                    parsedFilters.push({
                        type: key,
                        label: ReportRedactionFiltersLabelEnum.FEEDBACK_ONLY,
                        options: [
                            { label: tradPath + 'FEEDBACK_ONLY_TRUE', value: true },
                            { label: tradPath + 'FEEDBACK_ONLY_FALSE', value: false },
                        ],
                    });
                    break;
                case ReportRedactionFiltersTypeEnum.STARS:
                    parsedFilters.push({
                        type: key,
                        label: ReportRedactionFiltersLabelEnum.STARS,
                        options: [...new Array(4)].map((item: void, index: number) => ({ value: index, label: index.toString() })),
                    });
                    break;
                case ReportRedactionFiltersTypeEnum.OPTION_COUNT:
                    parsedFilters.push({
                        type: key,
                        label: ReportRedactionFiltersLabelEnum.OPTION_COUNT,
                        options: [...new Array(4)].map((item: void, index: number) => ({ value: index, label: index.toString() })),
                    });
                    break;
                case ReportRedactionFiltersTypeEnum.REDACTION_STATUS:
                    parsedFilters.push({
                        type: key,
                        label: ReportRedactionFiltersLabelEnum.REDACTION_STATUS,
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v ? tradPath + v.toString().toUpperCase() : '',
                        })),
                    });
                    break;
                case ReportRedactionFiltersTypeEnum.OPTIONS:
                    parsedFilters.push({
                        type: key,
                        label: ReportRedactionFiltersLabelEnum.OPTIONS,
                        options: value.values.map((v: any) => ({
                            value: v,
                            label: v ? tradPath + v.toString().toUpperCase() : '',
                        })),
                    });
                    break;
                case ReportRedactionFiltersTypeEnum.CATEGORY:
                    parsedFilters.push({
                        type: key,
                        label: ReportRedactionFiltersLabelEnum.CAT,
                        options: null,
                    });
                    break;
            }
        }

        /*
        ADD Category special filters
        Need to add a way to differentiate between category types, or filters will overwrite each other.
        REMINDER: FOOD DRINK, MOTHER CATS and CAT filters are all of type ReportRedactionFiltersTypeEnum.CATEGORY
        */
        parsedFilters.push(
            {
                type: ReportRedactionFiltersTypeEnum.CATEGORY + '##' + ReportRedactionFiltersLabelEnum.FOOD_DRINK,
                label: ReportRedactionFiltersLabelEnum.FOOD_DRINK,
                options: categoryTypeList.map((item: ICategoryTypeItem) => ({ value: item.value, label: item.label })),
            },
            {
                type: ReportRedactionFiltersTypeEnum.CATEGORY + '##' + ReportRedactionFiltersLabelEnum.MOTHER_CAT,
                label: ReportRedactionFiltersLabelEnum.MOTHER_CAT,
                options: null,
            }
        );

        /* Make sure filters are in the right order, dictated by the ReportRedactionFiltersLabelEnum order*/
        const filterOrder: string[] = Object.values(ReportRedactionFiltersLabelEnum);

        const sortedFilters: IFilter[] = parsedFilters.sort(
            (a: IFilter, b: IFilter) => filterOrder.indexOf(a.label) - filterOrder.indexOf(b.label)
        );

        return sortedFilters;
    }
}
